import React from 'react';
import { Notification } from 'react-admin';

const WattsNotification = (props) => {

    return (
        <Notification anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} />
    );
};

export default WattsNotification;