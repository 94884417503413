import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TelemetryCardHelpModalButton from '../Components/Devices/TelemetryCardHelpModalButton';
import TelemetryCardEditModalButton from '../Components/Devices/TelemetryCardEditModalButton';

//Styles
const Parent = {
    "width":"280px",
    "height":"48px",
    "display": "flex",
    "alignItems": "center",
    "color": "#757575"
}

const Message = {
    "width": "188px",
    "height": "20px",
    "fontSize": "16px",
    "fontWeight": 900,
    "fontStretch": "normal",
    "fontStyle": "normal",
    "lineHeight": "1.25",
    "letterSpacing": "-0.16px",
  }

const Icon = {
    "width": "25px",
    "height": "20px",
    "fontSize": "20px",
    "fontWeight": "normal",
    "fontStretch": "normal",
    "fontStyle": "normal",
    "lineHeight": "1",
    "letterSpacing": "normal",
    "textAlign": "center",
    "color": "rgba(0, 0, 0, 0.54)",
    "margin": "0px 15px 0px 26px",
  }

const ContextMenuOption = (props) => {

    const TheIcon = props.icon;
    const optionText = props.optionText;
    const initialSetting = props.settingsValue;
    const thisDevice = props.thisDevice;
    const isMenu = props.isMenu;
    const optionType = props.optionType;

    // Help Modal
    if(optionType === `HelpModal`) {
        return(
            <TelemetryCardHelpModalButton 
                superProps={props}
            />
        );
    }
    if(optionType === `EditModal`) {
        return(
            <TelemetryCardEditModalButton 
                superProps={props}
            />
        );
    }
    else {
        return(
            <div style={Parent}>
                <FontAwesomeIcon icon={TheIcon} style={Icon} key={`${props.uniqueKey}-menu`}/><span style={Message}>{optionText}</span>
            </div>
        );
    }
}

export default ContextMenuOption;