import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useAuthState } from "react-admin";
import { Redirect } from "react-router";

import WattsTheme from '../Colors/WattsTheme';
import { getHelperStyles } from '../Helpers';
import { downSm, downXs, upSm } from './styles.valiable'
import { authProvider } from '../AuthProvider';
import { routes } from './Dashboard/routes'
import WattsLoading from "./WattsLoading";

const loginStyles = makeStyles({
    'container': {
        'background': '#00254A url(/img/launch.jpg)',
        'backgroundSize': 'cover',
        'backgroundPosition': '100%',
        'height': '100vh'
    },
    'rightContent': {
        'fontSize': '14px',
        'lineHeight': '1.14',
        'letterSpacing': '-0.14px',
        [upSm]: {
            position: 'absolute',
            left: 'calc(50% - 63px)',
            top: '40%',
            textAlign: 'center',
            marginTop: 120
        },
        [downSm]: {
            position: 'absolute',
            bottom: 54,
            height: 36,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            '& > a': {
                margin: 0,
                height: 36,
                width: '80%'
            }
        },
        [downXs]: {
            'fontSize': '18px'
        },
    },
    'rightContentItem': {
        'color': '#FFF',
        'marginRight': '20px',
        'textDecoration': 'none',
        'font-family': 'Helvetica Neue !important'
    },
    'title': {
        'position': 'absolute',
        'left': '50%',
        'top': '40%',
        'textAlign': 'center',
        'transform': 'translate(-50%, -50%);',
        'fontSize': '32px',
        [downSm]: {
            'fontSize': '24px'
        },
        [downXs]: {
            'fontSize': '18px'
        },
        'color': '#FFF'
    },
    'appLogo': {
        'width': '100%',
        'maxWidth': '786px',
        'minWidth': '400px',
        [downSm]: {
            'minWidth': 300
        },
        [downXs]: {
            'minWidth': 'unset'
        }
    },
    'heading4': {
        'font-family': 'Helvetica Neue !important',
        [downSm]: {
            'fontSize': '24px'
        },
        [downXs]: {
            'fontSize': '18px'
        },
    },
    'banner': {
        'backgroundColor': '#ED6C02',
        'width': 'calc(100% - 16px)',
        'fontSize': '16px',
        'fontWeight': '700',
        'color': 'black',
        'position': 'fixed',
        'top': '0',
        'left': '0',
        'display': 'flex',
        'justifyContent': 'center',
        'padding': '8px',
        [downSm]: {
            'fontSize': '14px'
        },
    }
});


const AccountControls = () => {
    const useStyles = loginStyles();
    const classTemplate = getHelperStyles()();
    const buttonStyle = useStyles.rightContentItem + ' ' + classTemplate.subtitle2;

    return (
        <div className={useStyles.rightContent}>
            <Button className={buttonStyle} color="primary" variant="contained" onClick={() => { authProvider.login() }}>
                Get Started
            </Button>
        </div>
    );
};

const WattsLoginPage = (params) => {

    const useStyles = loginStyles();
    const classTemplate = getHelperStyles()();
    const headingStyle = useStyles.heading4 + ' ' + classTemplate.heading4;
    const { loading, authenticated } = useAuthState();

    const currentDate = new Date();
    const bannerDueDate = new Date('2024-02-01');
    const showBanner = currentDate < bannerDueDate;

    if (loading) {
        return <WattsLoading />;
    }

    if (authenticated) {
        return <Redirect to={routes.dashboard}></Redirect>;
    }

    return (
        <div className={useStyles.container}>
            {showBanner && < div className={useStyles.banner}>If you are having problems logging in, hit Ctrl/Cmd + Shift + R to refresh your browser.</div>}
            <MuiThemeProvider theme={WattsTheme}>
                <div className={useStyles.title}>
                    <p className={headingStyle}>Welcome to</p>
                    <img src="/img/app-logo.svg" alt="Watts OnSite" className={useStyles.appLogo} />
                </div>
                <AccountControls />
            </MuiThemeProvider>
        </div>
    );
};

export default WattsLoginPage;
