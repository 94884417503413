export const primary = '#005db9'
const width = {
    xxs: '0',
    xs: '320px',
    sm: '480px',
    md: '640px',
    lg: '960px',
    xl: '1350px',
    xxl: '1920px',
}
const height = {
    xl: '600px'
}

export const downXl = `@media (max-height: ${height.xl}), (max-width: ${width.xl})`
export const downLg = `@media (max-width: ${width.lg})`
export const downMd = `@media (max-width: ${width.md})`
export const downSm = `@media (max-width: ${width.sm})`
export const downXs = `@media (max-width: ${width.xs})`
export const upSm = `@media (min-width: ${width.sm})`
