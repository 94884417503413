import React from 'react';
import { Loading } from '../Utils/ra';

import { makeStyles } from '@material-ui/core/styles';

const loadingStyles = makeStyles({
    'loading': {
        'margin': '50px'
    }
});

const WattsLoading = () => {

    const useStyles = loadingStyles();

    return (
        <Loading className={useStyles.loading} loadingPrimary="elogic.loading" loadingSecondary="" />
    );
};

export default WattsLoading;
