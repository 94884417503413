import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { showNotification } from 'react-admin';
import { hideNotification } from 'ra-core';

import { connect } from 'react-redux';

import '../css/HelpModal.css';
import '../css/DeviceInfoModal.css';

import statuses, { SANITIZATION_STATUS } from './DeviceStatusLookup';
import DeviceErrorHelpDialog from './DeviceErrorHelpDialog';

class StatusNotifWithLinkToHelp extends React.Component {

    constructor(props) {
        super(props);

        this.toggleDialog = this.toggleDialog.bind(this);

        this.state = {
            open: false
        };
    }

    toggleDialog() {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (
            <div onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <FontAwesomeIcon icon={this.props.icon}
                    style={
                        {
                            'marginRight': '12px', 'fontSize': '20px'
                        }} />
                {this.props.alert}
                <span style={
                    {
                        'color': '#5daef1',
                        'cursor': 'pointer',
                        'fontSize': '14px',
                        'fontWeight': 'bold',
                        'marginLeft': '20px'
                    }}
                    onClick={this.toggleDialog}>See Event Details</span>

                <FontAwesomeIcon icon={faTimes} onClick={this.props.close} style={{ 'cursor': 'pointer', 'marginLeft': '20px', 'fontSize': '20px', }} />
                <DeviceErrorHelpDialog
                    status={this.props.status}
                    open={this.state.open}
                    onClose={this.toggleDialog}
                />
            </div>
        );
    }
}

const StatusNotification = (props) => {

    return (
        <div>
            <FontAwesomeIcon icon={props.icon}
                style={
                    {
                        'marginRight': '12px', 'fontSize': '20px'
                    }} />
            {props.alert}
        </div>
    );
}

class DeviceStatusNotification extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            errorName: "",
            errorDetailIcon: null,
            errorHelpTopics: [],
            colorClass: "",
        };
    }
    // for help modal
    openHelp = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    // for Snackbar
    closeNotification = () => {
        this.props.hideNotification();
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        // show notification
        if (this.props.device.status) {

            var status = this.props.device.status;
            var errorDetailIcon = statuses[status.id].icon;

            // For sanitization status we do not show the snackbar notification. Instead, we have a banner at the
            // top of the page that handles informing the user the device is in sanitization mode.
            if (status.id !== SANITIZATION_STATUS) {
                // show error notif with help link
                if (statuses[status.id].color === "red" || statuses[status.id].color === "orange"
                    && status.id != 6) { // Offline status is the only exception, it does not have definition for the error code details.

                    this.props.showNotification(
                        <StatusNotifWithLinkToHelp
                            alert={status.name}
                            alertId={status.id}
                            icon={errorDetailIcon}
                            openHelp={this.openHelp.bind(this)}
                            close={this.closeNotification.bind(this)}
                            status={status}
                        />,  // message 
                        'info',  // type
                        { autoHideDuration: 30000 }
                    );
                }
                else if (status.priority > 0) {
                    this.props.showNotification(
                        <StatusNotification
                            alert={status.name}
                            alertId={status.id}
                            icon={errorDetailIcon}
                            close={this.closeNotification.bind(this)}
                        />,  // message 
                        'info',  // type
                        { autoHideDuration: 5000 }
                    );
                }
            }
        }

    }

    render() {
        return (
            <>   
            </>
        );
    }
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    showNotification,
    hideNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceStatusNotification);