import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { TextInput, SelectInput, required } from 'react-admin';
import { useForm } from 'react-final-form';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faCamera } from '@fortawesome/pro-solid-svg-icons';

import FormHeader from '../FormHeader';
import RequiredFieldNote from '../RequiredFieldNote'
import { NONE } from '../../Helpers';
import { DatePickerInput } from '../DatePickerInput';
import { WattsImageField } from '../WattsImageField';
import { WattsImageInput } from '../WattsImageInput';


// CSS
import '../css/LandingPage.css';

const deviceFormStyles = {
    pageContentContainer: {
        backgroundColor: '#FFF',
        boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.12)',
        overflow: 'auto',
    },
    imageFormContainer: {
        margin: '0 auto',
        paddingTop: '25px',

        maxWidth: '813px',
        border: 'none',
        boxShadow: 'none'
    },
    deviceTextContainer: {
        padding: '20px 0 40px 15px',
        boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.12)',
        marginBottom: '44px'
    },
    overlineTop: {
        fontSize: '10px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '.6',
        letterSpacing: '-0.1px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    overlineBottom: {
        fontSize: '10px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.6',
        letterSpacing: '-0.1px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    headline6: {
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '.2',
        letterSpacing: '-0.2px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'bold'
    },
    formContainer: {
        float: 'right',
        width: '467px',
        marginBottom: '55px',
        marginLeft: '40px',

    },
    installInfoContainer: {
        width: '256px',
        float: 'left',
        position: 'relative',
        top: '35px',
    },
    buildIcon: {
        color: '#005DB9',
        fontSize: '32px'
    },
    infoText: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.25',
        letterSpacing: '-0.16px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'bold'
    },
    infoDesc: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: '-0.14px',
        color: '#666'
    },
    addPhotoButton: {
        width: '225px',
        height: '36px',
        borderRadius: '4px',
        backgroundColor: '#00254A',
        padding: '0',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2.5',
        letterSpacing: '-0.14px',
        marginBottom: '20px',
        color: '#FFF',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: '#005DB9',
            cursor: 'pointer'
        }
    },
    photoIcon: {
        fontSize: '20px',
        color: '#FFF',
        position: 'relative',
        top: '2px',
        marginRight: '12px',
    },
    datePicker: {
        width: '100%'
    },
    preview: {
        display: 'inline-block',
        '& img': {
            margin: '0'
        }
    },
    removeButton: {
        display: 'inline-block',
        position: 'relative',
        float: 'left',
        '& button': {
            position: 'absolute',
        }
    },
    codeInput: {
        display: 'none'
    },
    installPhoto: {
        maxWidth: '400px',
        padding: '15px'
    },
    imageFieldContainer: {
        'display': 'inline-block'

    },
    deviceImage: {
        width: '50%',
        margin: '0 auto',
        display: 'block'
    }
};

const InstallImage = (props) => {
    const form = useForm();
    const [installationImageUrl, setInstallationImageUrl] = useState(props.record["installationImageUrl"]);

    if (!props.record.id || !installationImageUrl) {
        return (<WattsImageInput
            source="pictures"
            label=""
            accept="image/*"
            classes={{
                dropZone: props.classes.addPhotoButton,
                preview: props.classes.preview,
                removeButton: props.classes.removeButton
            }}
            placeholder={<span><FontAwesomeIcon icon={faCamera} className={props.classes.photoIcon} />
                Add an Installation Photo</span>}>
            <WattsImageField source="installationImageUrl" title="title" />
        </WattsImageInput>);
    }
    else {
        return (
            <WattsImageField
                source="installationImageUrl"
                title="title"
                record={props.record}
                className={props.classes.imageFieldContainer}
                removeCallback={(evt) => {
                    form.change("installationImageUrl", "");
                    setInstallationImageUrl("");
                }} />
        );
    }
};

const LocationControls = (props) => {

    const [state, setState] = useState({});
    const [defaulted, setDefaulted] = useState(false);
    const [defaultedLocation, setDefaultedLocation] = useState(false);
    const [defaultedSublocation, setDefaultedSublocation] = useState(false);
    const form = useForm();

    useEffect(() => {

        if (props.locationId && !state.locations) {
            // Fetch the sublocations.
            const token = localStorage.getItem('authorizationToken');
            axios.get(`/api/Locations/${props.locationId}`, {
                headers: {
                    "Cache-control": "no-cache, no-store",
                    "Pragma": "no-cache",
                    "Expires": 0,
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (results) {

                    // Need to map the location results in a way that the react-admin SelectInput control
                    // will support.
                    var rawSubLocations = results.data.sublocations.map(function (location) {

                        // Map to an object with an id and name.
                        return {
                            id: location.locationId,
                            name: location.name
                        };
                    });

                    // Add an option to the top of the list for "None" which clears out
                    // the sublocation value.
                    rawSubLocations.unshift({
                        id: NONE,
                        name: '-- None --'
                    });

                    // Populate the state so the UI can be reflected with the correct data.

                    // You can't change the location once you register the device, so the only option in the
                    // locations drop down is the one it's currently associated with.
                    setState({
                        locations: [
                            {
                                id: results.data.locationId,
                                name: results.data.name
                            }
                        ],
                        subLocations: rawSubLocations
                    });
                });
        }
    }, [defaulted]); // Only run when the location id changes.

    debugger;

    if (props.locationId && !defaulted) {
        setDefaulted(true);
    }

    // Once locations have been determined, set the default values now that there are values in the selects.
    const locationFormObj = form.getFieldState("locationId");
    if (state.locations) {
        if (props.locationId && !locationFormObj.value) {
            form.change("locationId", props.locationId);
            setDefaultedLocation(true);
        }
    }

    // Once sublocations have been determined, set the default values now that there are values in the selects.
    const sublocationFormObj = form.getFieldState("sublocationId");
    if (state.subLocations) {
        if (props.sublocationId && !sublocationFormObj.value) {
            form.change("sublocationId", props.sublocationId);
            setDefaultedSublocation(true);
        }
    }

    const locationSearch = window.location.hash.split('?');

    // Handle pre-populating the "code" field from the URL.
    if (locationSearch.length > 1) {
        const code = new URLSearchParams(locationSearch[1]).get('code');
        form.change("code", code);
    }

    return (
        <>
            <SelectInput
                source="locationId"
                label="Location"
                fullWidth
                disabled
                choices={state.locations}
                validate={[required()]}
            />
            <SelectInput source="sublocationId"
                label="Sublocation"
                choices={state.subLocations}
                fullWidth
            />
            <TextInput
                label="Association Code"
                fullWidth
                id="assnCode"
                source="code"
                disabled
                className={props.classes.codeInput}
            />
        </>
    );
};


class DeviceFormLayout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            isImageCleared: this.props.record["installationImageUrl"] === null || this.props.record["installationImageUrl"] === ""
        };
    }

    componentDidMount() {
        this.populateLocationList(this);
    }

    populateLocationList(compThis) {

        // Can't use location.search to get the query string params because react-admin uses /#/ for the
        // start of their routes. So we need to use location.hash and find the query string params ourselves.
        const locationSearch = window.location.hash.split('?');
        var locationId = null;
        var sublocationId = NONE;

        // Handle locationId
        if (compThis.props.record && compThis.props.record.location && compThis.props.record.location.parentId) {
            locationId = compThis.props.record.location.parentId;
        }
        else if (compThis.props.record && compThis.props.record.location) {
            locationId = compThis.props.record.location.locationId;
        }
        else if (locationSearch.length > 0) {
            locationId = new URLSearchParams(locationSearch[1]).get('locationId');
        }

        // Handle sublocationId
        if (compThis.props.record && compThis.props.record.location && compThis.props.record.location.parentId) {
            sublocationId = compThis.props.record.location.locationId;
        }

        this.setState({
            locationId: locationId,
            sublocationId: sublocationId
        });
    }

    render() {

        return (
            <div>
                <FormHeader title={this.props.title} />
                <Grid container alignContent="center" justify="center" className="formGrid">
                    <Grid item md={2}>
                    </Grid>
                    <Grid item md={7}>
                        <Grid container>
                            <Grid item xs={12}>
                                <img src="/img/device_placeholder.jpg" alt="IntelliStation Jr." className={this.props.classes.deviceImage} />
                                <div className={this.props.classes.deviceTextContainer}>
                                    <p className={this.props.classes.overlineTop}>Powers</p>
                                    <h2 className={this.props.classes.headline6}>IntelliStation Jr.</h2>
                                    <p className={this.props.classes.overlineBottom}>Digital Mixing Valve</p>
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <div className={this.props.classes.installInfoContainer}>
                                    <FontAwesomeIcon icon={faWrench} className={this.props.classes.buildIcon} />
                                    <p className={this.props.classes.infoText}>Installation Info</p>
                                    <p className={this.props.classes.infoDesc}>Give your device a custom name and update installation details.</p>
                                </div>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <RequiredFieldNote />

                                <TextInput source="name"
                                    label="Device Name"
                                    fullWidth
                                    validate={[required()]}
                                    inputProps={{
                                        maxLength: 40,
                                    }} />
                                <LocationControls
                                    locationId={this.state.locationId}
                                    sublocationId={this.state.sublocationId}
                                    {...this.props} />
                                <TextInput
                                    source="locationDescription"
                                    label="Location Description"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 4000,
                                    }} />
                                <DatePickerInput
                                    source="installationDate"
                                    label="Installation Date"
                                    fullWidth
                                    defaultValue={this.state.date}
                                    className={this.props.classes.datePicker}
                                />
                                <DatePickerInput
                                    source="commissionDate"
                                    label="Commission Date"
                                    fullWidth
                                    defaultValue={this.state.date}
                                    className={this.props.classes.datePicker} />
                                <TextInput source="additionalNotes"
                                    label="Additional Notes"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 4000,
                                    }}
                                />
                                <InstallImage {...this.props} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

export default withStyles(deviceFormStyles)(DeviceFormLayout);