import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import { DatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// This is based on:
// https://github.com/vascofg/react-admin-date-inputs
// But modified to fix issues with the latest version, specifically this bug report:
// https://github.com/vascofg/react-admin-date-inputs/issues/37
// The GitHub project above might not be compatible with react-admin 3.0.0 and / or the latest version of Material UI?
const makePicker = (PickerComponent) => {
    class _makePicker extends Component {
        onChange(date) {
            this.props.input.onChange(date.toISOString());
            this.props.input.onBlur();
        }

        render() {
            const {
                input,
                options,
                label,
                source,
                resource,
                isRequired,
                className,
                meta,
                providerOptions,
            } = this.props;

            const { touched, error } = meta;

            return (
                <div className="picker">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            {...options}
                            label={<FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                            />}
                            format="M/dd/yyyy"
                            margin="dense"
                            error={!!(touched && error)}
                            helperText={touched && error}
                            className={className}
                            value={input.value ? input.value : null}
                            onChange={date => this.onChange(date)}
                            inputVariant="filled"
                            allowKeyboardControl={false}
                            inputProps={{
                                disabled: "disabled"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            );
        }
    }

    _makePicker.propTypes = {
        input: PropTypes.object,
        isRequired: PropTypes.bool,
        label: PropTypes.string,
        meta: PropTypes.object,
        options: PropTypes.object,
        resource: PropTypes.string,
        source: PropTypes.string,
        labelTime: PropTypes.string,
        className: PropTypes.string,
        providerOptions: PropTypes.shape({
            utils: PropTypes.func,
            locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
    };

    _makePicker.defaultProps = {
        input: {},
        isRequired: 'false',
        meta: { touched: false, error: false },
        options: {},
        resource: '',
        source: '',
        labelTime: '',
        className: '',
        providerOptions: {
            utils: DateFnsUtils,
            locale: undefined,
        },
    };
    return _makePicker;
};

export const DatePickerInput = addField(makePicker(DatePicker));