import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';

import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching,

} from 'react-admin';

import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import dataProvider from '../../dataProvider';

import '../css/LocationModal.css';

class LeaveLocationDialog extends React.Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleRemoveClick = () => {

        const token = localStorage.getItem('authorizationToken');
        axios.delete(`/api/locations/${this.props.thisRecord.locationId}/share/remove`, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
})
            .then(({ data }) => {
                this.props.showNotification(`elogic.toastNotifications.leaveMessage`);
                const url = '/#/locations'
                window.location.replace(window.location.origin + url)
            })
            .catch(error => {
                this.props.showNotification(`elogic.toastNotifications.leaveMessageError`, 'error');
                const url = '/#/locations'
                window.location.replace(window.location.origin + url)
            });
    };

    render() {
        const { showDialog } = this.state;

        return (
            <Fragment>
                <FontAwesomeIcon icon={faSignOut} onClick={this.handleClick} label="elogic.leave" />

                <Dialog className="Modal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Remove Location"
                >
                    <DialogTitle className="ModalTitle">Are you sure?</DialogTitle>
                    <span className="ModalText">Leaving this location will cause you to lose access to all of its devices.  You may only regain access by being invited back by an owner or admin.</span>

                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleCloseClick}>Cancel</Button>
                        <Button className="ModalButton" label="elogic.leave" onClick={this.handleRemoveClick}>Leave</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    LeaveLocationDialog
);