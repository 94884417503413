import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';

import UserCardMenu from './UserCardMenu'

const styles = {
    card: {
        border: '1px solid #E0E0E0',
        boxShadow: 'none',
        display: 'inline-block',
        margin: '12px',
        verticalAlign: 'top',
        width: 328
    },

    content: {
        paddingTop: '8px',
        position: 'relative'
    },

    userType: {
        display: 'inline-block',
        fontSize: '10px',
        fontWeight: 'bold',
        height: '24px',
        clipPath: 'polygon(85% 0, 100% 100%, 100% 100%, 0 100%, 0 0)',
        position: 'relative',
        left: '-16px',
        width: '72px'
    },

    locationOwner: {
        backgroundColor: '#00254A',
        color: 'white',
    },

    locationAdmin: {
        backgroundColor: '#F2F2F2',
        color: '#606060',
    },

    typeText: {
        margin: 0,
        marginLeft: '16px',
        lineHeight: '24px',
        verticalAlign: 'middle'
    },

    userName: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '24px',
        marginBottom: '7px'
    },

    optionButton: {
        cursor: 'pointer',
        position: 'absolute',
        right: '8px',
        top: '8px',
        height: '24px',
        width: '24px',
        color: '#005DB9'
    }
};

const UserTypeRibbon = withStyles(styles)(({ classes, record = {} }) => {

    var userTypeText = "";
    var colorStyle = "";

    if (record.permissions.length) {
        switch (record.permissions[0].roles[0]) {
            case "Owner":
                userTypeText = "Owner";
                colorStyle = classes.locationOwner;
                break;
            case "Admin":
                userTypeText = "Admin";
                colorStyle = classes.locationAdmin;
                break;
            default:
                return <div style={{ height: `24px` }} />;
        }
    }
    else {
        return <div style={{ height: `24px` }} />;
    }

    return (
        <div className={`${classes.userType} ${colorStyle}`}>
            <p className={classes.typeText}>{userTypeText}</p>
        </div>
    );
});

export const UserCard = withStyles(styles)(({ classes, dataRecord = {}, callbacks = {}, parentRecordId = "", ...props }) => {
    return (
        <Card key={dataRecord.userId} className={classes.card}>
            <CardContent className={classes.content}>
                <UserCardMenu recordId={dataRecord.userId} callbacks={callbacks} parentRecord={props.parentRecord} parentRecordId={parentRecordId} isOwner={dataRecord.permissions.length && dataRecord.permissions[0].roles[0] == `Owner`}/>
                <UserTypeRibbon record={dataRecord} />
                <div>
                    <h2 className={classes.userName}>{dataRecord.firstName} {dataRecord.lastName}</h2>
                </div>
            </CardContent>
        </Card>
    )
});