import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import './WattsAppBar.css';
import SideMenuDrawer from '../SideMenuDrawer';

const styles = {
    root: {
        'box-shadow': 'none',
        'flex-direction': 'row',
        'justify-content': 'space-between',
        'height': 64,
        'padding': '20px 0'
    },
    flexContent: {
        'display': 'flex'
    },
    notificationIcon: {
        color: 'white',
        fontSize: '20px',
        position: 'relative',
        right: '55px',
        cursor: 'pointer'
    }
};

const WattsAppBar = (props) => {

    if (props.transparent) {
        styles.root.backgroundColor = 'transparent';
    }
    else {
        delete styles.root.backgroundColor;
    }

    const appBarStyles = makeStyles(styles);

    const useStyles = appBarStyles();

    return (
        <div>
            <AppBar position="static" color="secondary" className={useStyles.root}>
                <div className={useStyles.flexContent}>
                    {GetNavBar()}
                    <a href="/#/locations"><img src="/img/logo-white.svg" alt="Watts" className="logo" /></a>
                </div>
                <div className={useStyles.flexContent}>
                    {props.rightcontent}
                </div>
            </AppBar>
        </div>
    );
};

function GetNavBar() {
    var userId = localStorage.getItem('userId');

    if (userId) {
        return <SideMenuDrawer />;
    }
    else {

        // When the user is not signed in, replace the hamburger icon (and the entire side navigation) with a 20px
        // wide spacer. This is because the user will be redirected to the login page and the login page does not
        // have a navigation menu.
        const iconPlaceholderStyles = makeStyles({
            'iconPlaceholder': {
                width: '20px'
            }
        });

        const useStyles = iconPlaceholderStyles();

        return <span className={useStyles.iconPlaceholder} />;
    }
}

export default WattsAppBar;