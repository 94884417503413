import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import { downSm, downXs } from './Components/styles.valiable'

export const SBS_URL = process.env.REACT_APP_REDIRECT_SBS;
export const SHOW_BOX_INSTALLISTATION = process.env.REACT_APP_SHOW_INSTALLISTATION;

export function getPermissionList(permissions) {
  var ret = {
    Alerts: {},
    Devices: {},
    Locations: {},
    Users: {},
  };

  if (permissions == null || permissions == undefined) {
    return ret;
  }

  permissions.forEach(function (permission) {
    if (
      permission.group === 'intellistationjr' ||
      permission.group === 'default'
    ) {
      permission.resources.forEach(function (resource) {
        resource.actions.forEach(function (action) {
          ret[resource.name][action] = true;
        });
      });
    }
  });
  return ret;
}

export function getDeviceImageOrDefault(dataRecord) {
  // Default installation image for Intellistation Jr.
  // TODO: If we end up adding more devices we need to add logic to pick the correct
  // default image.
  return '/img/device_placeholder.jpg';
}

export function getUserField(fieldName) {
  const userStr = localStorage.getItem('user');

  if (userStr) {
    var user = JSON.parse(userStr);

    if (user && user.hasOwnProperty(fieldName)) {
      return user[fieldName];
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getUserTempScale(device) {
  var tempScale = null;
  if (device && device.data && device.data.TempUnits) {
    tempScale = device.data.TempUnits.Val;
  }

  if (tempScale == null) {
    tempScale = getUserSettingsTempScale();
  }

  return tempScale;
}

export const getUserSettingsTempScale = () => {
  var tempScale = DEFAULT_TEMP_VALUE;

  var userString = localStorage.getItem('user');
  if (userString != null) {
    tempScale = JSON.parse(userString).temperatureScale;
  }

  return tempScale;
};

export function getUTCDate(date, device) {
  var utcOffsetValue = getUTCOffset(device);

  var result = moment(date); // Capture the date value in UTC.

  if (utcOffsetValue) {
    result.add(utcOffsetValue, 'm'); // Then apply the UTC offset.
  }

  return result.toDate(); // Convert to a Date object.
}

export function formatToUTCDate(date, device) {
  return getUTCDate(date, device).toLocaleString(); // Then format as in the user's locale format.
}

export function getDurationString(rawDuration) {
  var duration = moment.duration(rawDuration);

  var durationString = [];

  durationString.push(duration.hours().toString().padStart(2, '0'));
  durationString.push(duration.minutes().toString().padStart(2, '0'));
  durationString.push(duration.seconds().toString().padStart(2, '0'));

  return durationString.join(':');
}

export function getUTCOffset(device) {
  var utcOffsetValue = 0;

  if (device.data && device.data.ZoneOffset) {
    var zoneOff = device.data.ZoneOffset;
    utcOffsetValue = zoneOff.Hours * 60 + zoneOff.Minutes;
  }

  return utcOffsetValue;
}

export function getRecordOutletTemp(record, defaultValue, outlet) {
  const styles = makeStyles({
    headerTempUnit: {
      fontSize: '32px',
      verticalAlign: 'super',
      letterSpacing: '0',
    },
  });

  const classes = styles();

  var displayValue = '';
  if (outlet) {
    displayValue = (
      <span className={classes.headerTempUnit}>
        °{getUserTempScale(record)}
      </span>
    );
  }

  var outletValue = getMixedOutletTemp(record, defaultValue);

  return (
    <>
      {outletValue}
      {displayValue}
    </>
  );
}

export function getHelperStyles() {
  const styles = makeStyles({
    heading2: {
      'font-family': 'Roboto',
      'font-size': '64px',
      'font-weight': '300',
      'font-stretch': 'normal',
      'font-style': 'normal',
      'line-height': '1.13',
      'letter-spacing': '-0.64px',
    },
    heading3: {
      'font-family': 'Roboto',
      'font-size': '48px',
      'font-weight': '900',
      'font-stretch': 'normal',
      'font-style': 'normal',
      'line-height': '1.17',
      'letter-spacing': '-0.48px',
    },
    heading4: {
      'font-family': 'Roboto',
      'font-size': '32px',
      [downSm]: {
        'fontSize': '24px'
      },
      [downXs]: {
        'fontSize': '18px'
      },
      'font-weight': '300',
      'font-stretch': 'normal',
      'font-style': 'normal',
      'line-height': '1.13',
      'letter-spacing': '-0.32px',
    },
    heading6: {
      'font-family': 'Roboto',
      'font-size': '20px',
      'font-weight': '900',
      'font-stretch': 'normal',
      'font-style': 'normal',
      'line-height': '1.2',
      'letter-spacing': '-0.2px',
    },
    subtitle1: {},
    subtitle2: {
      'font-family': 'Roboto',
      'font-size': '14px',
      'font-weight': '900',
      'font-stretch': 'normal',
      'font-style': 'normal',
      'line-height': '1.14',
      'letter-spacing': '-0.14px',
    },
  });

  return styles;
}

export const ADD_BUTTON_STYLES = {
  root: {
    background: '#005DB9',
    'border-radius': '100%',
    'box-shadow':
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0,0,0,.12)',
    color: '#FFF',
    height: '56px',
    left: '100%',
    transform: 'translateY(-50%)',
    minHeight: '56px',
    minWidth: '56px',
    position: 'absolute',
    marginLeft: '-14.2%',
    width: '56px',
    'z-index': '100',
    'font-size': '20px',
    'font-weight': '300',

    '&:hover, &:active, &:focus': {
      background: '#005DB9',
    },
  },
};

export const SANITIZATION_STATUS = {
  id: 7,
  name: 'Sanitizing',
  condition: 'Sanitization Alert',
  cause: 'Sanitization mode has been activated at the device',
  howToClear:
    'Sanitizaton period must elapse. At the device, sanitization mode must be terminated',
  controlBehavior:
    'Operates at elevated setpoint temperature for period of time',
  priority: 7,
};

export const prioritySort = (first, second) => {
  return first.status.priority - second.status.priority;
};

export const getMixedOutletTemp = (record, defaultValue) => {
  var tempText = defaultValue;

  // Check that the object we're getting the Mixed Outlet value from exists to prevent errors.
  if (record && record.status && record.status.id !== 6) {
    // Sensor error.
    if (record.status.id === 2) {
      tempText = '0';
    } else if (
      record.data &&
      record.data.Sensors &&
      record.data.Sensors.MixOutlet &&
      record.data.Sensors.MixOutlet.Val
    ) {
      tempText = renderTemperature(record.data.Sensors.MixOutlet.Val);
    }
  }

  return tempText;
};

export const DEFAULT_TEMP_VALUE = '- - -';

export const isDeviceOffline = (device) => {
  return device && device.status && device.status && device.status.id === 6;
};

export const renderTemperature = (value) => {
  var result = value;

  var parsedValue = parseFloat(value);

  if (!isNaN(parsedValue) && getUserTempScale() == 'C') {
    result = `${parsedValue.toFixed(1)}`;
  }

  return result;
};

export const NONE = 'none';

export const getLocationAddress = (address) => {
  const addressBuilder = [];

  if (address) {
    addressBuilder.push(address.address);

    if (address.address2) {
      addressBuilder.push(address.address2);
    }

    addressBuilder.push(address.city);
    addressBuilder.push(address.state_province);
    addressBuilder.push(address.zipcode);
  }

  return addressBuilder.join(', ');
};
