import React from 'react';

import Grid from '@material-ui/core/Grid';

import axios from 'axios';

import { showNotification } from 'react-admin';
import { connect } from 'react-redux';

import TelemetryCard from './TelemetryCard';
import TelemetryCardMenu from './TelemetryCardMenu';
import ContextMenuOption from '../ContextMenuOption';
import DeviceHistoryChart from './DeviceHistoryChart';

import DeviceLastUpdated from './DeviceLastUpdated';

import { faQuestionCircle, faPencil } from '@fortawesome/pro-solid-svg-icons';

import '../css/DeviceDashboardTab.css';

import { getUserTempScale, getPermissionList, isDeviceOffline } from "../../Helpers.js";

const SettingsCard = (props) => {
    var permissions = null;

    if (props.thisDevice) {
        permissions = getPermissionList(props.thisDevice.location.permissions);
    }

    var menuOptions = [];

    if (permissions && permissions.Devices && permissions.Devices.Update && !props.sanitization) {
        menuOptions.push(

            // Edit settings option.
            <ContextMenuOption
                icon={faPencil}
                optionText={`Edit Setting`}
                optionType={`EditModal`}
                initialSetting={props.settingsValue}
                thisDevice={props.thisDevice}
                addTodoItem={props.SetValueFunction}
                uniqueKey={props.uniqueKey}
                isMenu={true}
            />
        );
    }

    let className = props.className;
    if (props.sanitization) {
        className += " disabledCard";
    }
    else {

        // Help option.
        menuOptions.push(
            <ContextMenuOption
                icon={faQuestionCircle}
                optionText={`Help`}
                optionType={`HelpModal`}
                thisDevice={props.thisDevice}
                uniqueKey={props.uniqueKey}
                isMenu={true}
                helpKey={props.helpKey}
                helpObject={props.helpObject}
                helpError={props.helpError}
            />
        );
    }

    return (
        <TelemetryCard
            message={props.message}
            value={props.settingsValue}
            date={null}
            className={className}
            iconType={!isDeviceOffline(props.thisDevice) && !props.sanitization &&
                <TelemetryCardMenu
                    uniqueKey={props.parentKey}
                    menuOptions={menuOptions}
                />
            }
            key={`${props.parentKey}-${0}`}
        />
    );
};

class DeviceDashboardTab extends React.Component {
    constructor(props) {
        super(props);
        this.SetMixedOutletSetpointValue = this.SetMixedOutletSetpointValue.bind(this);
        this.SetHighTempDiffValue = this.SetHighTempDiffValue.bind(this);
        this.SetLowTempDiffValue = this.SetLowTempDiffValue.bind(this);
        this.state = {
            MixedOutletSetpointValue: null,
            HighTempDiffValue: null,
            LowTempDiffValue: null,
        };
    }

    SetMixedOutletSetpointValue(value, tempErrorCallback) {
        var comp = this;
        var remoteMax = comp.props.record.data.RemoteMaxSetpoint.Val;
        var tempUnit = comp.props.record.data.TempUnits.Val;
        if (value > remoteMax) {
            var errorText = "This device does not allow remote temperature settings to exceed " + remoteMax + "°" + tempUnit + ". To adjust the Mixed Outlet Setpoint above this threshold, you must change it manually on the device or by contacting the device owner.";
            tempErrorCallback(comp.state.MixedOutletSetpointValue, errorText);
            return;
        }

        // axios update device value - direct method
        const token = localStorage.getItem('authorizationToken');
        axios({
            method: 'patch',
            url: `/api/devices/${comp.props.record.id}/UpdateSetpoint`,
            data: {
                MixOutletSetpoint: value
            },
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }).then(function (response) {
            // handle success
            comp.setState({ MixedOutletSetpointValue: value });
            comp.props.record.data["MixedSetpoint"].Val = value;

            comp.props.showNotification("elogic.toastNotifications.setMixedOutletSetpointValue");
        }).catch(function (error) {
            // handle error
            tempErrorCallback(comp.state.MixedOutletSetpointValue);
            comp.props.showNotification("elogic.toastNotifications.setMixedOutletSetpointValueError", "error");
            console.log(error);
        });
    }

    SetHighTempDiffValue(value, tempErrorCallback) {
        var comp = this;
        // axios update device value - direct method
        const token = localStorage.getItem('authorizationToken');
        axios({
            method: 'patch',
            url: `/api/devices/${comp.props.record.id}/UpdateHighTempDiff`,
            data: {
                HighAlertDiff: value
            },
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }).then(function (response) {
            // handle success
            comp.setState({ HighTempDiffValue: value });
            comp.props.record.data["HighAlertDiff"].Val = value;

            comp.props.showNotification("elogic.toastNotifications.setHighTempDiffValue");
        }).catch(function (error) {
            // handle error
            tempErrorCallback(comp.state.HighTempDiffValue);
            comp.props.showNotification("elogic.toastNotifications.setHighTempDiffValueError", "error");
            console.log(error);
        });
    }

    SetLowTempDiffValue(value, tempErrorCallback) {
        var comp = this;
        // axios update device value - direct method
        const token = localStorage.getItem('authorizationToken');
        axios({
            method: 'patch',
            url: `/api/devices/${comp.props.record.id}/UpdateLowTempDiff`,
            data: {
                LowAlertDiff: value
            },
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }).then(function (response) {
            // handle success
            comp.setState({ LowTempDiffValue: value });
            comp.props.record.data["LowAlertDiff"].Val = value;

            comp.props.showNotification("elogic.toastNotifications.setLowTempDiffValue");
        }).catch(function (error) {
            // handle error
            tempErrorCallback(comp.state.LowTempDiffValue);
            comp.props.showNotification("elogic.toastNotifications.setLowTempDiffValueError", "error");
            console.log(error);
        });
    }

    componentDidMount() {
        this.getTempValuesFromRecord();
    }

    getTempValuesFromRecord() {
        // These values come from device record data block (see mock data)
        if (this.props.record.data) {

            var mixedOutlet = this.getTelemetryDataValue('MixedSetpoint');
            var highAlertDiff = this.getTelemetryDataValue('HighAlertDiff');
            var lowAlertDiff = this.getTelemetryDataValue('LowAlertDiff');

            if (mixedOutlet != this.state.MixedOutletSetpointValue ||
                highAlertDiff != this.state.HighTempDiffValue ||
                lowAlertDiff != this.state.LowTempDiffValue) {

                this.setState(
                    {
                        MixedOutletSetpointValue: mixedOutlet,
                        HighTempDiffValue: highAlertDiff,
                        LowTempDiffValue: lowAlertDiff,
                        TempUnits: getUserTempScale(this.props.record)
                    });
            }
        }
    }

    getTelemetryDataValue(fieldName) {

        if (this.props.record.data[fieldName]) {
            return this.props.record.data[fieldName].Val;
        }

        return null;
    }


    render() {
        const displayValue = getUserTempScale(this.props.record);

        if (displayValue != this.state.TempUnits) {

            // If the temp scale changed since the state was rendered, we need to reset the state
            // from the record.
            this.getTempValuesFromRecord();
        }

        return (
            <>
                <Grid container alignContent="center" justify="center">
                    <Grid item md={12} xs={12}>
                        <div style={{maxWidth:'1028px', margin:'0 auto'}}>
                            <DeviceLastUpdated />
                        </div>
                    </Grid>
                    <Grid className="chartContainer" item md={12} xs={12}>
                        <DeviceHistoryChart thisDevice={this.props.record} />
                    </Grid>

                    <Grid className="cardContainer" item md={12} xs={12}>
                        <Grid container alignContent="center" justify="center">

                            <SettingsCard
                                message={`Mixed Outlet Setpoint (°${displayValue})`}
                                settingsValue={this.state.MixedOutletSetpointValue}
                                parentKey={`DashBoardTabCard-${0}`}
                                uniqueKey={`MixedSetpoint`}
                                thisDevice={this.props.record}
                                SetValueFunction={this.SetMixedOutletSetpointValue}
                                className="monitorCardNoBorder"
                                helpKey="HELP_MixedOutletSetpoint"
                                helpObject={this.props.helpObject}
                                helpError={this.props.helpError}
                                sanitization={this.props.record.sanitizeEndTime}
                            />
                            <SettingsCard
                                message={`High Temperature Alert Differential (°${displayValue})`}
                                settingsValue={this.state.HighTempDiffValue}
                                parentKey={`DashBoardTabCard-${1}`}
                                uniqueKey={`HighAlertDiff`}
                                thisDevice={this.props.record}
                                SetValueFunction={this.SetHighTempDiffValue}
                                className="monitorCardBorder"
                                helpKey="HELP_HighTempAlertDiff"
                                helpObject={this.props.helpObject}
                                helpError={this.props.helpError}
                            />
                            <SettingsCard
                                message={`Low Temperature Alert Differential (°${displayValue})`}
                                settingsValue={this.state.LowTempDiffValue}
                                parentKey={`DashBoardTabCard-${2}`}
                                uniqueKey={`LowAlertDiff`}
                                thisDevice={this.props.record}
                                SetValueFunction={this.SetLowTempDiffValue}
                                className="monitorCardNoBorder"
                                helpKey="HELP_LowTempAlertDiff"
                                helpObject={this.props.helpObject}
                                helpError={this.props.helpError}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default connect(null, { showNotification })(DeviceDashboardTab);