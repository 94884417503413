import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CancelButton from './CancelButton';

// Custom toolbar for the SimpleForms used by Watts.
const WattsToolbar = ({ classes, basePath, ...props }) => {

    const saveIconStyles = {
        'root': {
            'margin': 0
        }
    };

    // This is sort of a no-op icon. It's blank. The design (UI / UX) didn't specify one. 
    const SaveIcon = withStyles(saveIconStyles)(({ classes, ...props }) => {
        return (
            <span className={classes.root} />
        );
    });

    return (
        <Grid container alignContent="center" className="toolbarGrid" justify="center">
            <Grid item md={2}>
            </Grid>
            <Grid item md={3} xs={12}>
            </Grid>
            <Grid item md={4} xs={12}>
                <Toolbar {...props} className="toolbarContainer">
                    <SaveButton
                        label={props.savebuttontext}
                        redirect="show"
                        submitOnEnter={true}
                        icon={<SaveIcon />}
                        className="formSubmitButton" />
                    <CancelButton text="Cancel" />
                </Toolbar>
            </Grid>
            <Grid item md={2}>
            </Grid>
        </Grid>
    );
};

export default WattsToolbar;