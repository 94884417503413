import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { faSyncAlt} from '@fortawesome/pro-regular-svg-icons';

import DeviceInfoBanner from '../Devices/DeviceInfoBanner';

const LocationBanner = (props) => {
    const [banners, setBanners] = useState([]);
    const [checkHash, setCheckHash] = useState("");
    const [refreshTimer, setRefreshTimer] = useState();

    useEffect(() => {
        return () => {
            if (refreshTimer) {
                clearInterval(refreshTimer);
            }
        };
    }, [refreshTimer]);

    const checkForUpdatedData = function (refreshCallback, refreshTimer) {

        var recordId = 'CheckHash';

        if (props.record.id) {
            recordId = props.record.id;
        }

        const token = localStorage.getItem('authorizationToken');
        axios.get(`/api/Locations/${recordId}`, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
            .then(function (results) {

                if (props.record.CheckHash) {
                    if (results.data.CheckHash != props.record.CheckHash) {
                        clearInterval(refreshTimer);

                        setBanners([
                            <DeviceInfoBanner
                                color={"#ff8613"}
                                icon={faSyncAlt}
                                userMessage={"New data is available. Reload the page to see the latest data."}
                                actionText={"Update Location Data"}
                                callback={refreshCallback}
                            />
                        ]);
                    }
                }
                else if (props.setData) {
                    props.setData({
                        CheckHash: results.data.CheckHash
                    });
                }
            });
    }

    if (props.record.CheckHash && checkHash != props.record.CheckHash) {
        setCheckHash(props.record.CheckHash);

        var refreshPage = () => { window.location.reload(); };

        clearInterval(refreshTimer);
        var timer = window.setInterval(() => {
            checkForUpdatedData(refreshPage, timer);
        }, 30000);

        setRefreshTimer(timer);
    }
    else if (!props.record.CheckHash && props.setData && !props.record.pending) {
        props.setData({
            pending: true
        });
        checkForUpdatedData();
    }

    return (
        <>
            {banners}
        </>
    );
}

export default LocationBanner;