import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching,
    SimpleForm,
    TextInput,
    SelectInput
} from 'react-admin';
import axios from 'axios';

import Button from '@material-ui/core/Button';

import { faUsers } from '@fortawesome/pro-solid-svg-icons';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import ContextMenuOption from '../ContextMenuOption';

import '../css/DeviceModal.css';

class CreateDeviceModal extends Component {
    _isMounted = false;
    state = {
        error: false,
        showDialog: false,
        roleOptions: [],
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        var postObj = {
            emailAddressToShareWith: document.getElementsByName("inviteUserEmail")[0].value,
            groupRole: document.getElementsByName("inviteuserRole")[0].value
        }

        var comp = this;

        const token = localStorage.getItem('authorizationToken');
        axios.post(`/api/Locations/${this.props.locationid}/share`, postObj, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
            .then(function (response) {
                comp.props.showNotification("elogic.toastNotifications.inviteUser");
                comp.handleCloseClick();
            })
            .catch(function (error) {

                if (error && error.response && error.response.data && error.response.data.errorMessage) {
                    comp.props.showNotification(error.response.data.errorMessage);
                }
                else {
                    comp.props.showNotification("elogic.toastNotifications.inviteUserError", "error");
                }
            });
    };

    componentDidMount = () => {
        this._isMounted = true;
        const token = localStorage.getItem('authorizationToken');
        var jsonHeaders = {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        };

        var comp = this;

        axios.get(`/api/AppSettings/grantableroles/${this.props.locationid}`, jsonHeaders)
            .then(function (response) {
                var userRoles = [];
                
                response.data.groupRoles.forEach(function (group) {
                    if (group.groupName == "intellistationjr") {
                        group.roles.forEach(function (role) {
                            userRoles.push({
                                id: role.groupRoleId,
                                name: role.role
                            })
                        });
                    }
                });

                comp.setState({ roleOptions: userRoles });
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { showDialog } = this.state;
        return (
            <Fragment>
                <div onClick={this.handleClick}>
                    <ContextMenuOption
                        icon={faUsers}
                        optionText="Invite a User"
                    />
                </div>

                <Dialog className="Modal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Create post"
                >
                    <DialogTitle className="ModalTitle">Invite a User</DialogTitle>
                    <DialogContent>
                        <SimpleForm toolbar={null} submitOnEnter={false}>
                            <TextInput type="email" label="Email" source="inviteUserEmail" />
                            <SelectInput label="Role" source="inviteuserRole" choices={this.state.roleOptions} />
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleCloseClick}>Cancel</Button>
                        <Button className="ModalButton" label="ra.action.next" onClick={this.handleSaveClick}>Send Invite</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    CreateDeviceModal
);