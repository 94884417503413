import React from 'react';
import { Layout, Menu } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import WattsAppBar from './WattsAppBar/WattsAppBar';
import WattsSidebar from './WattsSidebar';
import WattsNotification from './WattsNotification'
import NotificationTray from './NotificationTray';

const WattsLayout = props => {
    return (<Layout
        appBar={() => <WattsAppBar rightcontent={<NotificationTray />}/>}
        menu={Menu}
        notification={WattsNotification}
        sidebar={WattsSidebar}
        classes={props.classes.content}
        {...props} />
    );
};

const styles = {
    root: {
        'background-color': '#F5F5F5',
        'marginTop': '0',
        'z-index': 'auto'
    },
    content: {
        'padding': '0'
    }
};

export default withStyles(styles)(WattsLayout);