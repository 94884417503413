import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles({
    list: {
        display: 'flex',
        listStyleType: 'none',
    },
    image: {
        maxHeight: '300px'
    },
    tagLine: {
        background: 'rgba(0, 0, 0, 0.38)',
        color: '#FFF',
        marginTop: '-4px',
        padding: '8px',
        transform: 'translateY(-100%)'
    },
    removeButton: {
        color: '#A2A3A5',
        cursor: 'pointer',
        float: 'right'
    }
});

const sanitizeRestProps =
    ({
        addLabel, allowEmpty, basePath, cellClassName, className, formClassName, headerClassName, label, linkType, link,
        locale, record, resource, sortable, sortBy, source, textAlign, translateChoice, ...props
    }) => props;

export const WattsImageField = ({
    className,
    classes: classesOverride,
    record,
    source,
    src,
    title,
    removeCallback,
    ...rest
}) => {
    const sourceValue = record[source];
    const classes = useStyles({ classes: classesOverride });
    if (!sourceValue) {
        return <div className={className} />;
    }

    const titleValue = record[title];
    return (
        <div className={className} {...sanitizeRestProps(rest)}>
            <img
                title={titleValue}
                alt={titleValue}
                src={sourceValue}
                className={classes.image}
            />
            <div className={classes.tagLine}>Installation Image

                <FontAwesomeIcon className={classes.removeButton}
                    onClick={removeCallback}
                    title="Delete"
                    icon={faTrash} />
            </div>
        </div>
    );
};

export default WattsImageField;