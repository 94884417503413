import { NavigationClient, PublicClientApplication } from "@azure/msal-browser";
import { environment } from './environment';
import  axios  from 'axios';

export const msalConfig = {
    auth: {
        instance: environment.authorization.issuer,
        clientId: environment.authorization.clientId,
        redirectUrl: environment.authorization.redirectUri,
        logoutUri: environment.authorization.logoutUri,
        scopes: environment.authorization.scopes,
    },
};

const navigationClientService = new NavigationClient();

const authorization = {
    auth: {
        clientId: msalConfig.auth.clientId,
        authority: `${msalConfig.auth.instance}/B2C_1A_UnifiedSignUpOrSignIn/`,
        knownAuthorities: [msalConfig.auth.instance],

        redirectUri: msalConfig.auth.redirectUrl,
        postLogoutRedirectUri: msalConfig.auth.logoutUri,

        logoutUri: msalConfig.auth.logoutUri,
        scopes: msalConfig.auth.scopes,
        setupAutomaticSilentRefresh: true,

        baseUrl: '/',
        editProfileUrl: `${msalConfig.auth.instance}/B2C_1A_UnifiedProfileEdit/`,
    },
    system: {
        navigationClient: navigationClientService,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};

export const msalInstance = new PublicClientApplication(authorization);

class AuthProvider {
    constructor() {
        let account = this.getUserAccount();

        if (account) {
            msalInstance.acquireTokenSilent({ account, scopes: authorization.auth.scopes }).then((res) => {
                localStorage.setItem("authorizationToken", res.accessToken);
                if (res && res.accessToken) {
                    this.setUser(res.accessToken);
                }
                if (!sessionStorage.getItem('userAccount') && res && res.account && res.account.idTokenClaims) {
                    this.setUserAccount(res.account.idTokenClaims);
                }
            })
        }

        msalInstance
            .handleRedirectPromise()
            .then((res) => {
                if (res) {
                    localStorage.setItem("authorizationToken", res.accessToken);
                    const userId = res.idTokenClaims.oid ? res.idTokenClaims.oid : res.idTokenClaims.sub;
                    localStorage.setItem('userId', userId);
                    this.setUser(res.accessToken);
                    this.setUserAccount(res.idTokenClaims);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    login = () => {
        localStorage.clear();
        sessionStorage.clear();
        return msalInstance
            .loginRedirect({
                scopes: msalConfig.auth.scopes,
            })
            .catch((err) => {
                console.error(err);
                this.logout();
            });
    };
    logout = () => {
        return msalInstance
            .logoutRedirect()
            .then(() => {
                localStorage.removeItem("authorizationToken");
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject();
            });
    };
    editProfile = () => {
        return msalInstance
            .loginRedirect({
                scopes: authorization.auth.scopes,
                authority: authorization.auth.editProfileUrl,
            })
            .catch((err) => {
                console.error(err);
            });
    };
    checkAuth = async () => {
        if (window.location.href.includes('/dashboard')) {
            await new Promise(resolve => setTimeout(resolve, 2000));
        }
        let account = this.getUserAccount();

        if (account && account.localAccountId) {
            localStorage.setItem('userId', account.localAccountId);
        }

        if (account) {
            await msalInstance.acquireTokenSilent({ account, scopes: authorization.auth.scopes }).then((res) => {
                localStorage.setItem("authorizationToken", res.accessToken);
                if (res && res.accessToken) {
                    this.setUser(res.accessToken);
                }
                if (!sessionStorage.getItem('userAccount') && res && res.account && res.account.idTokenClaims) {
                    this.setUserAccount(res.account.idTokenClaims);
                }
            })
            return Promise.resolve();
        }

        return Promise.reject();
    };
    checkError = () => Promise.resolve();
    getPermissions = () => Promise.resolve();
    setUser = (idToken) => {
        axios.get("/api/User", {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${idToken}`
            }
        }).then((response) => {
            if (response && response.data) {
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                if (response.data.userId) {
                    localStorage.setItem('userId', response.data.userId);
                }
                localStorage.setItem('pendingLocationTransfers', JSON.stringify(response.data.pendingLocationTransfers));
            }
            axios.get("/manifest.json").then((response) => {
                localStorage.setItem("version", response.data.version);
            });

        })
            .catch(() => {
                localStorage.clear();
                return Promise.reject();
            });

    };
    setUserAccount = (claims) => {
        const user = {};

        user.aad_givenname = claims ? claims.given_name : "";
        user.aad_surname = claims ? claims.family_name : "";
        user.country = claims ? claims.country : "";
        user.emailAddress = claims ? claims.email : "";
        sessionStorage.setItem('userAccount', JSON.stringify(user));
    };
    getUserAccount = () => {
        let account = msalInstance.getActiveAccount();

        if (!account) {
            const accounts = msalInstance.getAllAccounts();
            account = accounts[0];
        }

        return account;
    }
}

const authProvider = new AuthProvider();

export { authProvider };
