import axios from 'axios';

import { authProvider } from './AuthProvider';
import { NONE } from './Helpers';

const API_URL = '/api';
const HTTP_403 = 403;

const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('authorizationToken');
    options.url = url;
    options.headers = { authorization: `Bearer ${token}` };

    return axios(options);
};

// Since react-admin requires a unique property specifically named 'id', we need
// to make sure that we can easily map between the various resource ids and the
// required id property.
const resourceIdMap = {
    locations: 'locationId',
    devices: 'deviceId',
    user: 'userId'
}

const errorHandler = (error, id) => {
    if (error && error.response && error.response.status == HTTP_403) {
        authProvider.logout().then(function () {
            window.location.href = '/#/login?authentication';
        });
    }
    else if (error && error.response && error.response.data && error.response.data.errorCode === 1 && error.response.data.errorNumber == 403) {

        error.response.data.id = id;

        return {
            data: error.response.data
        };
    }
}

export default {
    // GET_LIST
    getList: (resource, params) => {
        const url = `${API_URL}/${resource}`;

        return httpClient(url).then(result => {
            for (var i = 0; i < result.data.length; i++) {
                result.data[i].id = result.data[i][resourceIdMap[resource]];
            }
            return ({
                data: result.data,
                total: result.data.length,
            });
        }).catch(errorHandler);
    },

    // GET_ONE
    getOne: (resource, params) => {
        var url = `${API_URL}/${resource}/${params.id}`;

        if (resource === 'user' || resource === 'account') {

            if (resource === 'account') {
                resource = 'user';
            }

            // For user settings we don't have an id.
            url = `${API_URL}/${resource}`;
        }

        return httpClient(url).then(response => {

            response.data.id = response.data[resourceIdMap[resource]];

            return ({
                data: response.data,
            });
        }).catch((error) => errorHandler(error, params.id));
    },

    // UPDATE
    update: (resource, params) => {

        var url = `${API_URL}/${resource}/${params.id}`;

        if (resource === 'user') {

            localStorage.setItem('user', JSON.stringify(params.data));

            // For user settings we don't have an id.
            url = `${API_URL}/${resource}`;
        }

        const updateCallback = response => {

            response.data.id = response.data[resourceIdMap[resource]];

            // Put the device data back into the response we get.
            response.data.data = params.data.data;
            response.data.location = params.data.location

            return ({ data: response.data });
        };

        var deviceParams = handleDeviceParams(resource, params);

        if (shouldHandlePictures(resource, deviceParams.data)) {
            return handlePictures(resource, deviceParams.data, updateCallback, 'PATCH');
        }
        else {
            return httpClient(url, {
                method: 'PATCH',
                data: deviceParams.data,
            })
                .then(updateCallback)
                .catch(errorHandler);
        }

    },

    // CREATE
    create: (resource, params) => {
        const createCallback = response => {
            response.data.id = response.data[resourceIdMap[resource]];

            return ({
                data: response.data
            });
        };

        var deviceParams = handleDeviceParams(resource, params);

        if (shouldHandlePictures(resource, deviceParams.data)) {
            return handlePictures(resource, deviceParams.data, createCallback, 'POST');
        }
        else {
            return httpClient(`${API_URL}/${resource}`, {
                method: 'POST',
                data: deviceParams.data,
            })
                .then(createCallback)
                .catch(errorHandler);
        }
    },

    // DELETE
    delete: (resource, params) => {
        return httpClient(`${API_URL}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(response => {
            return ({ data: response.data });
        }).catch(errorHandler);
    }
};

function handleDeviceParams(resource, params) {

    // This is necessary because for devices we only send a subset of the fields to the API.
    if (resource === 'devices') {
        var results = {
            data: {}
        };

        if (params.data['sublocationId'] && params.data['sublocationId'] !== NONE) {
            results.data['locationId'] = params.data['sublocationId'];
        }
        else {
            results.data['locationId'] = params.data['locationId'];
        }

        if (!params.data.deviceId) {
            results.data.installationDate = params.data['installationDate'];
        }

        results.data.name = params.data['name'];
        results.data.additionalNotes = params.data['additionalNotes'];
        results.data.locationDescription = params.data['locationDescription'];
        results.data.commissionDate = params.data['commissionDate'];
        results.data.code = params.data['code'];
        results.data.installationDate = params.data['installationDate'];

        if (params.data['pictures']) {
            results.data.pictures = params.data['pictures'];
        }
        else if (params.data['installationImageUrl'] === "") {
            // If installationImageUrl is blank, clear it in the API.
            results.data.installationImageUrl = params.data['installationImageUrl'];
        }

        results.data.deviceId = params.data['deviceId'];

        return results;
    }
    else if (resource === 'locations') {
        params.data['buildingTypeId'] = params.data['buildingType'].buildingTypeId;
    }

    return params;
}

function shouldHandlePictures(resource, data) {
    return resource == 'devices' && data && data.pictures;
}

function handlePictures(resource, data, createCallback, requestMethod) {

    return new Promise((resolve, reject) => {
        convertFileToBase64(data.pictures).then((installationImage) => {
            data['installationImageUrl'] = installationImage.replace(/^data:image\/[a-z]+;base64,/, "");

            var deviceId = "";
            if (requestMethod === 'PATCH') {
                deviceId = `/${data.deviceId}`;
            }

            resolve(
                httpClient(`${API_URL}/${resource}${deviceId}`, {
                    method: requestMethod,
                    data: data
                })
                    .then(createCallback)
                    .catch(errorHandler)
            )
        })
    });
}


function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file.rawFile);

        fileReader.onload = function () { resolve(fileReader.result) };
        fileReader.onerror = reject;

    });
};