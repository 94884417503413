import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faUser, faCog, faQuestionCircle, faShieldCheck, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import { authProvider } from '../AuthProvider';

const SideMenuDrawer = (props) => {

    // Stores the state for whether or not the open (visible) or not.
    const [state, setState] = React.useState({
        isOpen: false
    });

    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        // Handles the state of whether or not the drawer should be rendered.
        setState({ ...state, isOpen: open });
    };

    const userId = localStorage.getItem('userId');

    // Data representation of the menu structure.
    const navItems = {
        'manage': [
            {
                'label': 'Locations',
                'icon': faMapMarkerAlt,
                'route': '/locations'
            },
            {
                'label': 'Account',
                'icon': faUser,
                'route': '/account/' + userId
            },
            {
                'label': 'Settings',
                'icon': faCog,
                'route': '/user/' + userId
            }
        ],
        'info': [
            {
                'label': 'Help',
                'icon': faQuestionCircle,
                'link': 'https://watts.com/Support/WattsOnSite'
            },
            {
                'label': 'Privacy',
                'icon': faShieldCheck,
                'link': 'https://www.watts.com/OnSitePrivacy'
            }
        ],
        'access': [
            {
                'label': 'Sign Out',
                'icon': faSignOut,
                'onClick': function () {
                    localStorage.clear();
                    localStorage.clear();
                    authProvider.logout();
                }
            }
        ]
    };

    const ListGrouping = (listProps) => {

        const groupItems = listProps.navItemGroup;
        const listClass = props.classes.menuText + ' ' + props.classes.listItem;

        // Returns the state of the entire navigation grouping.
        return (
            <div>
                <Divider className={props.classes.menuDivider} />
                <div>
                    {groupItems.map((navItem) => {

                        if (navItem.route) {
                            return (
                                <Link to={navItem.route} key={navItem.label} className={props.classes.link}>
                                    {getLinkItem(props.classes, navItem, listClass)}
                                </Link>
                            );
                        }
                        else {
                            return (
                                <a href={navItem.link} key={navItem.label} className={props.classes.link} onClick={navItem.onClick} target="_blank">
                                    {getLinkItem(props.classes, navItem, listClass)}
                                </a>
                            );
                        }
                    })}
                </div>
            </div>
        );
    };

    const getLinkItem = (classes, navItem, listClass) => {
        return (
            <ListItem button key={navItem.label} className={listClass}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={navItem.icon} className={classes.menuIcon} />
                </ListItemIcon>
                <ListItemText primary={navItem.label} className={classes.listItemText} disableTypography />
            </ListItem>
        );
    }

    const VersionDisplay = () => {

        const styles = makeStyles({
            versionDisplay: {
                'color': '#666',
                'font-family': 'Roboto',
                'font-size': '12px',
                'font-weight': 'normal',
                'font-stretch': 'normal',
                'font-style': 'normal',
                'line-height': '1.33',
                'letter-spacing': '-0.12px',
                'margin-top': '-20px',
                'margin-left': '12px',
                'top': '100%',
                'position': 'absolute',
                'transform': 'translateY(-100%)'
            }
        });

        const useStyles = styles();

        return (
            <div className={useStyles.versionDisplay} > Version: {localStorage.getItem("version")}</div>
        );
    }

    const SideMenuContent = () => {
        // Renders the actual content that appears when the navigation drawer appears.
        return (<div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            className={props.classes.drawer}
        >
            <List className={props.classes.list}>
                <ListGrouping navItemGroup={navItems.manage} />
                <ListGrouping navItemGroup={navItems.info} />
                <ListGrouping navItemGroup={navItems.access} />
            </List>
            <VersionDisplay />
        </div>
        );
    };

    return (
        <span>
            <Button onClick={toggleDrawer(true)} className={props.classes.button}>
                <FontAwesomeIcon icon={faBars} />
            </Button>
            <Drawer open={state.isOpen} onClose={toggleDrawer(false)}>
                <div className={props.classes.menuLogo}>
                    <img src='/img/logo-black.svg' alt='Watts' className='logo' />
                </div>
                {SideMenuContent()}
            </Drawer>
        </span>
    );
};

const styles = {
    link: {
        'textDecoration': 'none',
        'color': 'inherit'
    },
    button: {
        'color': '#FFF',
        'fontSize': '20px',
        'min-height': '26px',
        'padding': '0 24px'
    },
    drawer: {
        'padding': '0 8px',
        'width': '256px'
    },
    menuLogo: {
        'padding': '20px 16px'
    },
    menuDivider: {
        'margin': '8px 0'
    },
    menuIcon: {
        'font-size': '20px',
        'width': '45px !important'
    },
    list: {
        'padding': '0'
    },
    listItem: {
        'border-radius': '4px',
        'font-weight': '900',
        'height': '44px',
        'padding': '2px 0',
        'color': '#757575',
        '&:hover': {
            'background': 'rgba(3, 110, 203, 0.12)',
            'color': '#005DB9'
        }
    },
    listItemText: {
        'padding-left': '15px'
    },
    menuText: {
        '&:hover div, &:hover svg, &:focus div, &:focus svg, &:active div, &:active svg':
        {
            'color': '#005DB9'
        }
    }
};

export default withStyles(styles)(SideMenuDrawer);