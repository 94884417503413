import React from 'react';
import { Edit, SimpleForm, useAuthState } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeviceFormLayout from './DeviceFormLayout';
import WattsToolbar from '../WattsToolbar';
import WattsLoading from '../WattsLoading';

const createStyles = makeStyles({
    'main': {
        'margin': '0'
    }
});

export const CreateDevice = (props) => {

    const createUseStyles = createStyles();

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return (
        <div>
            <Edit successMessage="elogic.toastNotifications.deviceUpdated" classes={{ main: createUseStyles.main }} actions={null} undoable={false} {...props}>
                <SimpleForm toolbar={<WattsToolbar savebuttontext="Save" />}>
                    <DeviceFormLayout title="Edit Device" />
                </SimpleForm>
            </Edit>
        </div>


    );
};

export default (CreateDevice);