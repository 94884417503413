import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

import '../css/TelemetryCard.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function TelemetryCardMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = props.menuOptions;
  const uniqueKey = props.uniqueKey;

  return (
    <div className={classes.root}>
        <FontAwesomeIcon icon={faEllipsisV} onClick={handleClickListItem} label="ra.action.menu" className="monitorCardIcon" />
        <Menu
            id="Button-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="telemetryCardMenu"
            >
            {options.map((option, index) => (
            <MenuItem
                key={`${uniqueKey}-${index}`}
                onClick={event => handleMenuItemClick(event, index)}
                >
                {option}
            </MenuItem>
            ))}
        </Menu>
    </div>
  );
}