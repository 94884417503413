import { createMuiTheme } from '@material-ui/core/styles';
import WattsBluePrimary from './WattsBluePrimary';
import WattsBlueSecondary from './WattsBlueSecondary';

const WattsTheme = createMuiTheme({
    palette: {
        primary: WattsBluePrimary,
        secondary: WattsBlueSecondary
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1348,
            xl: 1920
        }
    },
    overrides: {
        'MuiToolbar': {
            'root': {
                'backgroundColor': '#FFF'
            }
        },
        'MuiFormControl': {
            'marginDense': {
                'marginBottom': '27px'
            }
        },
        'MuiPaper': {
            'elevation1': {
                'boxShadow': 'none'
            }
        },
        'MuiButton': {
            'root': {
                'textTransform': 'none'
            }
        }
    }
});

export default WattsTheme;