const WattsBlueSecondary = {
    50: '#E5E9EF',
    100: '#BCC8D8',
    200: '#91A4BD',
    300: '#6781A2',
    400: '#456791',
    500: '#1C4F81',
    600: '#144879',
    700: '#083F6E',
    800: '#013562',
    900: '#00254A',
    main: '#00254A'
}

export default WattsBlueSecondary;