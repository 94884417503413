import React, { useState, useEffect } from "react";
import { useAuthState } from "react-admin";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import WattsLoading from "../WattsLoading";
import { ApplicationTitle } from "./ApplicationTitle";
import {
  SBS_URL,
} from "../../Helpers";
import { useStyles } from "./style";
import { BoxIntelliStation } from './IntellistationJuniorTile'
import { backgroundPlaceholder, backgroundFullImage, logoWatts, tekmarImageLogo, tekmarDevice } from './constants'
import { routes } from './routes'

export const Dashboard = () => {
  const [backgroundImage, setBackgroundImage] = useState(backgroundPlaceholder);
  const classes = useStyles()
  const { loading, authenticated } = useAuthState();

  useEffect(() => {
    const img = new Image()
    img.src = backgroundFullImage;
    img.onload = () => setBackgroundImage(backgroundFullImage);
  }, []);

  if (loading) {
    return <WattsLoading />;
  }

  if (!authenticated) {
    return <Redirect to={routes.login}></Redirect>;
  }

  return (
    <div className={classes.body} style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src={logoWatts} className={classes.logo} />
        </div>
        <div>
          <p className={classes.title}>Please select your product</p>
        </div>
        <div className={classes.tiles}>
          <div className={classes.tile}>
            <BoxIntelliStation classImage={classes.image} />
          </div>
          <div className={classes.tile}>
            <Link
              onClick={(event) => {
                event.preventDefault();
                window.location.replace(SBS_URL);
              }}
              to="/"
            >
              <ApplicationTitle
                logo={tekmarImageLogo}
                applicationImage={tekmarDevice}
                devices={["Smart Boiler Control 294", "Smart Steam Control 289"]}
                classImage={classes.image}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>)
};

