import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, SimpleForm, useAuthState } from 'react-admin';
import ScrollToTopOnMount from '../ScrollToTop'
import WattsToolbar from '../WattsToolbar';
import AccountFormLayout from './AccountFormLayout';
import WattsLoading from '../WattsLoading';

// CSS
import '../css/LandingPage.css';

const editStyles = makeStyles({
    'main': {
        'margin': '0'
    }
});

const EditAccount = ({ hasCreate, hasEdit, hasList, hasShow, ...props }) => {

    const editUseStyles = editStyles();

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return (
        <div>
            <ScrollToTopOnMount />
            <Edit classes={{ main: editUseStyles.main }} actions={null} undoable={false} {...props}>
                <SimpleForm toolbar={null}>
                    <AccountFormLayout title="Account" />
                </SimpleForm>
            </Edit>
        </div>
    );
};

export default EditAccount;