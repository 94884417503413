import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../css/HelpModal.css';
import '../css/DeviceInfoModal.css';

import statuses from './DeviceStatusLookup';
import { getHelperStyles, SANITIZATION_STATUS } from '../../Helpers.js';

const ErrorDetailsRow = (props) => {
    const classTemplate = getHelperStyles()();
    const nameClass = 'errorDetials-topicTitle ' + classTemplate.heading6;

    return (
        <div key={`help-row-${props.name}`} className={`deviceInfoModal-content-row errorDetials-topicRow ${props.gray}`}>
            <div className={"deviceInfoModal-content-row-text"}>
                <p className={nameClass}>{props.name}</p>
                <ul className={"errorDetials-topicItem"}>
                    <li className={"errorDetials-topicItem"} key={`help-li-${props.body}`}>{props.body}</li>
                </ul>
            </div>
        </div>
    );
};

const DeviceErrorHelpDialog = (props) => {

    var status = props.status;

    // Override the cooldown status with the sanitization status for issue 12902.
    if (status.id === 8) {
        status = SANITIZATION_STATUS;
    }

    var colorClass = `errorDetails-color-${statuses[status.id].color}`;
    var errorDetailIcon = statuses[status.id].icon;

    var errorHelpTopics = [];

    // Cause
    errorHelpTopics.push(
        <ErrorDetailsRow name="Cause" body={status.cause} gray="errorDetials-gray" key={`${status.id}-cause`} />
    );

    // How To Clear
    errorHelpTopics.push(
        <ErrorDetailsRow name="How To Clear" body={status.howToClear} key={`${status.id}-clear`} />
    );


    // Control Behavior
    errorHelpTopics.push(
        <ErrorDetailsRow name="Control Behavior During Error Condition" body={status.controlBehavior} gray="errorDetials-gray" key={`${status.id}-behavior`} />
    );

    return (
        <Dialog className="helpModal deviceInfoModal"
            fullWidth
            open={props.open}
            onClose={props.onClose}
            aria-label="Help"
        >
            <DialogTitle className="helpModalTitle">Event Details</DialogTitle>

            <DialogContent>
                <div className="deviceInfoModal-content">
                    <div className={"deviceInfoModal-header"}>
                        <FontAwesomeIcon className={`errorDetails-icon ${colorClass}`} icon={errorDetailIcon} />
                        <p className={"errorDetials-errorTitle"}>{status.name}</p>
                    </div>
                    {errorHelpTopics}
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="ModalButton" label="ra.action.cancel" onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeviceErrorHelpDialog;