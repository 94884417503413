import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { TextInput, SelectInput, ArrayInput, required, choices } from 'react-admin';
import FormHeader from '../FormHeader';
import Grid from '@material-ui/core/Grid';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faBuilding } from '@fortawesome/pro-solid-svg-icons';

import WattsFormIterator from '../WattsFormIterator';
import RequiredFieldNote from '../RequiredFieldNote';

const isStateValid = (stateMap) => (value, allValues) => {
    var country = allValues.address.country;
    if(!country || !stateMap || !stateMap[country] || !Array.isArray(stateMap[country])) {
        return undefined;
    }
    var isStateValid = stateMap[country].some(state => state.id === value);
    return isStateValid ? undefined : {message: "ra.validation.required"}
}


class LocationFormLayout extends React.Component {

    constructor(props) {
        super(props);

        // Initial state, we don't have any building types at the start.
        this.state = {
            buildingTypes: [],
            validBuildingTypeIds: [],
            buildingTypeValue: null,
            countryStateMap: {},
            countries: [],
            states: [],
            stateValue: null
        };
    }

    componentDidMount() {

        // Need to capture a reference to the component's 'this' object so
        // we can update the state in the callback from the API.
        var compThis = this;

        this.handleBuildingType(compThis);
        this.handleCountriesAndStates(compThis);
    }

    handleBuildingType(compThis) {
        const token = localStorage.getItem('authorizationToken');
        axios.get("/api/Locations/BuildingTypes", {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
        .then(function (results) {
            // Sort the results and map them to the object schema the react-admin
            // select control expects.
            var buildingTypes = results.data.sort(function (first, second) {

                // Sort ascending based on sequence number.
                return first.sequence - second.sequence;
            }).map(function (buildingType) {

                // Map to an object with an id and name.
                return {
                    id: buildingType.buildingTypeId,
                    name: buildingType.name
                }
            });

            // Used for validation of the input on submit of the form.
            var validBuildingTypeIds = buildingTypes.map((buildingType) => {
                return buildingType.id;
            });

            // Now that we're populating the list of location types, we also need to populate the
            // value of it as well.
            var buildingTypeId = null;
            if (compThis.props.record && compThis.props.record.buildingType && compThis.props.record.buildingType.buildingTypeId) {
                buildingTypeId = compThis.props.record.buildingType.buildingTypeId;
            }

            // Update the state; need to call setState for the UI to re-render.
            compThis.setState({
                buildingTypes: buildingTypes,
                validBuildingTypeIds: validBuildingTypeIds,
                buildingTypeId: buildingTypeId
            });
        });
    }

    handleCountriesAndStates(compThis) {
        const token = localStorage.getItem('authorizationToken');
        axios.get("/api/Locations/CountriesStates", {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
        .then(function (results) {
            if (results && results.data) {

                var countryStateMap = {};

                // Determine the list of countries from the data provided by the endpoint.
                var countries = results.data.map(function (value) {
                    return {
                        id: value.code,
                        name: value.name
                    };
                });

                // Determine a mapping of country code value to the list of states for that country.
                results.data.forEach(function (value) {

                    var states = value.states.map(function (value) {
                        return {
                            id: value.code,
                            name: value.name
                        };
                    });

                    countryStateMap[value.code] = states;
                });

                // Set the state to update the UI.
                compThis.setState({
                    'countryStateMap': countryStateMap,
                    'countries': countries
                });

                if (compThis.props.record && compThis.props.record.address && compThis.props.record.address.country) {
                    compThis.populateStateDropDown(compThis.props.record.address.country);
                }
            }
        });
    }

    onChangeCountry(compThis, context) {

        if (context && context.target) {
            // What value the country field is set to.
            var country = context.target.value;

            compThis.populateStateDropDown(country);
        }
    }

    populateStateDropDown(country) {

        var stateValue = null;

        if (this.props.record && this.props.record.address && this.props.record.address.state_province) {
            stateValue = this.props.record.address.state_province;
        }

        // If it's a valid country...
        if (country && this.state.countryStateMap.hasOwnProperty(country)) {

            // Set the state drop-down's options based on the country selected.
            this.setState({
                'states': this.state.countryStateMap[country],
                'stateValue': stateValue
            });
        }
    }

    render() {
        const locationFormStyles = {
            icon: {
                'color': '#005DB9',
                'font-size': '32px'
            }
        };

        const StyledLocationIcon = (withStyles(locationFormStyles)(({ classes }) => { return (<FontAwesomeIcon icon={faMapMarkerAlt} className={classes.icon} />) }));
        const StyledSubLocationIcon = (withStyles(locationFormStyles)(({ classes }) => { return (<FontAwesomeIcon icon={faBuilding} className={classes.icon} />) }));

        return (
            <div>
                <FormHeader title={this.props.title} />
                <Grid container alignContent="center" justify="center" className="formGrid">
                    <Grid item md={2}>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container>
                            <Grid item md={5} xs={12}>
                                <StyledLocationIcon />
                                <h2>Location Info</h2>
                                <p className="subText">Enter the details for your new location.</p>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <RequiredFieldNote />

                                <SelectInput
                                    source="buildingType.buildingTypeId"
                                    label="Type of Location"
                                    fullWidth
                                    choices={this.state.buildingTypes}
                                    validate={[required()]} />
                                <TextInput source="name"
                                    label="Name"
                                    fullWidth
                                    validate={[required()]}
                                    inputProps={{
                                        maxLength: 200,
                                    }}/>
                                <SelectInput
                                    source="address.country"
                                    label="Country"
                                    fullWidth
                                    choices={this.state.countries}
                                    validate={[required()]}
                                    onChange={(value) => this.onChangeCountry(this, value)} />
                                <TextInput
                                    source="address.address"
                                    label="Address 1"
                                    fullWidth
                                    validate={[required()]}
                                    inputProps={{
                                        maxLength: 200,
                                    }}/>
                                <TextInput
                                    source="address.address2"
                                    label="Address 2"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 200,
                                    }}/>
                                <TextInput
                                    source="address.city"
                                    label="City"
                                    fullWidth
                                    validate={[required()]}
                                    inputProps={{
                                        maxLength: 100,
                                    }}/>
                                <SelectInput
                                    source="address.state_province"
                                    label="State"
                                    fullWidth
                                    choices={this.state.states}
                                    validate={[required(), isStateValid(this.state.countryStateMap)]}
                                    defaultValue={this.state.stateValue} />
                                <TextInput
                                    source="address.zipcode"
                                    label="Postal Code"
                                    fullWidth
                                    validate={[required()]}
                                    inputProps={{
                                        maxLength: 15,
                                    }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="formGridSeparated"></div>
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <StyledSubLocationIcon />
                                <h2>Sublocations</h2>
                                <p className="subText">Organize your devices in as many sublocations as you need.</p>
                            </Grid>
                            <Grid item md={7} xs={12} className="formGridItemSeparated">
                                <ArrayInput label="" source="sublocations" className="arrayInput">
                                    <WattsFormIterator addButtonText="Add a Sublocation" margin={0}>
                                        <TextInput source="name"
                                            label="Sublocation Name"
                                            fullWidth
                                            validate={[required()]}
                                            inputProps={{
                                                maxLength: 200,
                                            }}/>
                                    </WattsFormIterator>
                                </ArrayInput>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

export default LocationFormLayout;
