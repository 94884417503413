import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching,
} from 'react-admin';

import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import ContextMenuOption from '../ContextMenuOption';

import '../css/HelpModal.css';
import '../css/TelemetryCard.css';


class TelemetryCardHelpModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            showDialog: false,
            helpData: {
                title: "",
                icon: "",
                topics: []
            }
        };
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    render() {
        const { showDialog } = this.state;
        const superProps = this.props.superProps;

        // Check if this is a menu item button 
        // If NOT, then use a regular card icon button
        var ThisButton = () => {
            if (superProps.isMenu) {
                return (<ContextMenuOption icon={faQuestionCircle} optionText="Help" />);
            }
            else {
                var ThisIcon = superProps.icon;
                return (<FontAwesomeIcon icon={ThisIcon} className="monitorCardIcon" />);

            }
        };

        var helpContent = null;
        if (this.props.superProps.helpObject) {

            const helpObject = this.props.superProps.helpObject[this.props.superProps.helpKey];

            helpContent = (
                <>
                    <DialogTitle className="ModalTitle">{helpObject.title}</DialogTitle>
                    <span className="ModalText">{helpObject.body}</span>
                </>
            );
        }
        else {
            helpContent = (
                <>
                    <DialogTitle className="ModalTitle">Unable to retrieve help information.</DialogTitle>
                    <span className="ModalText">{this.props.superProps.helpError}</span>
                </>
            );
        }

        return (
            <Fragment>
                <div onClick={this.handleClick}>
                    <ThisButton />
                </div>

                <Dialog className="helpModal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Help"
                >
                    {helpContent}
                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleCloseClick}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    TelemetryCardHelpModal
);