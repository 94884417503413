import React, { } from 'react';

import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import { showNotification } from 'react-admin';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRedo } from '@fortawesome/pro-regular-svg-icons';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import ContextMenuOption from '../ContextMenuOption';
import TelemetryCard from './TelemetryCard';
import statuses from './DeviceStatusLookup';

import DeviceLastUpdated from './DeviceLastUpdated';

import '../css/DeviceMonitorTab.css';
import '../css/TelemetryCard.css';

import { getPermissionList, getUserTempScale, formatToUTCDate, isDeviceOffline } from '../../Helpers.js';

const ErrorLogCard = (props) => {

    // Set Icon
    var thisIcon = <FontAwesomeIcon icon={statuses[props.errId].icon} className="errorLogCardIcon" />;

    // color odd errors
    var ClassColor = "";
    if (props.myIndex % 2 == 0) {
        ClassColor = "oddCard"
    }

    var dateString = '- - -';
    if (props.dateCreated) {
        dateString = formatToUTCDate(props.dateCreated, props.record);
    }

    return (
        <div className={`errorLogCard ${ClassColor}`}>
            {thisIcon}
            <div className="errorLogCardDetails">
                <span className="errorLogCardName">{props.errMsg}</span><br />
                <span className="errorLogCardDate">{dateString}</span>
            </div>
        </div>
    );
};

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class HighLowTempValueCard extends React.Component {
    constructor(props) {

        super(props);

        this.clearDate = this.clearDate.bind(this);

        var highLowMixTemp = getTelemetryDataFromRecord(this.props.record, this.props.fieldName);

        this.state = {
            currentTemp: highLowMixTemp.value,
            lastCleared: highLowMixTemp.date,
            showConfirmation: false
        };
    }

    clearDate(clearType) {
        var comp = this;
        // axios update device value - direct method
        const token = localStorage.getItem('authorizationToken');
        axios({
            method: 'patch',
            url: `/api/devices/${comp.props.record.id}/${clearType}`,
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }).then(function (response) {

            var clearData = getTelemetryDataFromRecord(response.data, comp.props.fieldName);

            comp.setState({
                currentTemp: clearData.value,
                lastCleared: clearData.date
            });

            comp.props.record.data[comp.props.fieldName].Val = clearData.value;
            comp.props.record.data[comp.props.fieldName].DateCleared = clearData.date;

            comp.props.showNotification(`elogic.toastNotifications.set${clearType}`);

            comp.showConfirmation(false);

        }).catch(function (error) {
            // handle error
            comp.props.showNotification(`elogic.toastNotifications.set${clearType}Error`, "error");
            comp.showConfirmation(false);
        });
    }

    showConfirmation(show) {
        this.setState({
            showConfirmation: show
        });
    }

    render() {

        var offsetDateTime = formatToUTCDate(this.state.lastCleared, this.props.record);

        var icon = this.props.permissions.Devices.Update ? (<FontAwesomeIcon icon={faRedo} className="monitorCardIcon" onClick={() => {this.showConfirmation(true); }} />) : null;
        return (
            <>
                <TelemetryCard
                    message={this.props.message}
                    value={this.state.currentTemp}
                    date={offsetDateTime}
                    iconType={!isDeviceOffline(this.props.record) && icon}
                    key={`HighLowTempValueCard-${this.props.index}`}
                />
                <Dialog className="Modal"
                    fullWidth
                    open={this.state.showConfirmation}
                    aria-label="Temperature Confirmation"
                >
                    <DialogTitle className="ModalTitle">Are you sure?</DialogTitle>
                    <span className="ModalText">{this.props.helpObject[this.props.helpKey].body}</span>

                    <DialogActions>
                        <Button className="ModalButton" onClick={() => this.showConfirmation(false)}>Cancel</Button>
                        <Button className="ModalButton" onClick={() => this.clearDate(this.props.clearType) }>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}


// Tab Content
class DeviceMonitorTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorLogCardList: [],
            isLoading: true
        };
    }

    getActuatorV(record) {
        var actuatorV = "- - -";
        if (record.data && record.data.Outputs && record.data.Outputs.ActuatorV) {
            actuatorV = record.data.Outputs.ActuatorV.toString();
        }

        return actuatorV;
    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        var comp = this;

        // Lists to hold returned compenents
        var logCards = [];

        const token = localStorage.getItem('authorizationToken');
        var jsonHeaders = {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        };

        // Get alerts for device
        axios.get(`/api/alerts/${this.props.record.deviceId}/30/2`, jsonHeaders)
            .then(function (response) {

                for (var i = 0; i < response.data.length; i++) {
                    logCards.push(<ErrorLogCard errMsg={response.data[i].errMsg} dateCreated={response.data[i].dateCreated} errId={response.data[i].deviceStatus} myIndex={i} key={i} record={comp.props.record} />);
                }
                if (comp._isMounted) {
                    comp.setState({ errorLogCardList: logCards, isLoading: false });
                }

            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        var actuatorPositionValue = this.getActuatorV(this.props.record);
        var permissions = getPermissionList(this.props.record.location.permissions);

        return (
            <div>
                <Grid container alignContent="center" justify="flex-start">
                    <Grid item xs={12} md={9} lg={11}>
                        <DeviceLastUpdated />
                    </Grid>
                    <Grid className="errorLogContainer" item md={3}>
                        <div className="errorLogHeader">
                            <p className="errorLogHeaderTitle"><b>Event Log</b> (30 days)</p>
                        </div>
                        <div className="errorLogCardContainer">
                            {this.state.errorLogCardList}
                        </div>
                    </Grid>
                    <Grid item md={6} lg={8}>
                        <HighLowTempValueCard
                            message={`Highest Mixed Outlet Temperature (°${getUserTempScale(this.props.record)})`}
                            index={0}
                            permissions={permissions}
                            clearType="ClearMixedOutletHigh"
                            record={this.props.record}
                            showNotification={this.props.showNotification}
                            fieldName="MonitorMixOutletHigh"
                            helpObject={this.props.helpObject}
                            helpError={this.props.helpError}
                            helpKey="HELP_MixedOutletHigh"
                        />

                        <TelemetryCard
                            message={`Actuator Position (Vdc)`}
                            uniqueKey={`ActuatorPosition`}
                            value={actuatorPositionValue}
                            date={null}
                            iconType={
                                <ContextMenuOption
                                    icon={faInfoCircle}
                                    optionType={`HelpModal`}
                                    initialSetting={this.state.ActuatorPositionValue}
                                    thisDevice={this.props.record}
                                    uniqueKey={`ActuatorPositionMenu`}
                                    isMenu={false}
                                    helpObject={this.props.helpObject}
                                    helpError={this.props.helpError}
                                    helpKey="HELP_ActPosition"
                                />
                            }
                            key={`DeviceDashboardCard-${2}`}
                        />

                        <HighLowTempValueCard
                            message={`Lowest Mixed Outlet Temperature (°${getUserTempScale(this.props.record)})`}
                            index={1}
                            permissions={permissions}
                            clearType="ClearMixedOutletLow"
                            record={this.props.record}
                            showNotification={this.props.showNotification}
                            fieldName="MonitorMixOutletLow"
                            helpObject={this.props.helpObject}
                            helpError={this.props.helpError}
                            helpKey="HELP_MixedOutletLow"
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function getTelemetryDataFromRecord(record, fieldName) {
    var result = {
        value: '- - -',
        date: '- - -'
    };

    if (record.data && record.data[fieldName]) {
        var field = record.data[fieldName];

        if (field.Val) {
            result.value = field.Val;
        }

        if (field.DateCleared) {
            var dateCleared = field.DateCleared.substring(0, field.DateCleared.length - 3)
            result.date = new Date(dateCleared).toLocaleString();
        }
    }

    return result;
}

export default connect(null, { showNotification })(DeviceMonitorTab);