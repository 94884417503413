import React from 'react';
import { Create, SimpleForm, useAuthState } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeviceFormLayout from './DeviceFormLayout';
import WattsToolbar from '../WattsToolbar';
import WattsLoading from '../WattsLoading';

const createStyles = makeStyles({
    'main': {
        'margin': '0'
    }
});

export const CreateDevice = (props) => {
    const createUseStyles = createStyles();

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return (
        <div>
            <Create successMessage="elogic.toastNotifications.deviceCreated" classes={{ main: createUseStyles.main }} actions={null} {...props}>
                <SimpleForm toolbar={<WattsToolbar savebuttontext="Add Device" />}>
                    <DeviceFormLayout title="Add a Device" />
                </SimpleForm>
            </Create>
        </div>


    );
};

export default (CreateDevice);