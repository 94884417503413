import React, { Fragment } from 'react';
import axios from 'axios';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class OwnershipTransferModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pendingTransfers: []
        };
    }

    componentDidMount() {
        const transfers = JSON.parse(localStorage.getItem("pendingLocationTransfers"));
        if (transfers && transfers.length) {
            this.setState({
                pendingTransfers: transfers
            });
        }
        else {

            const token = localStorage.getItem('authorizationToken');
            var compThis = this;
            axios.get("/api/User", {
                    headers: {
                        "Cache-control": "no-cache, no-store",
                        "Pragma": "no-cache",
                        "Expires": 0,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    if (response && response.data) {

                        var pendingTransfers = JSON.stringify(response.data.pendingLocationTransfers);

                        localStorage.setItem('pendingLocationTransfers', pendingTransfers);

                        compThis.setState({
                            pendingTransfers: response.data.pendingLocationTransfers
                        });
                    }
                }
            );
        }
    }

    handleClose() {
        this.setState({ transferWindowOpen: false });
    }

    handleOpen() {
        this.setState({ transferWindowOpen: true });
    }

    handleTransferSelection(accepted = false) {
        var comp = this;
        var urlSuffix = accepted ? 'Accept' : 'Decline';

        const token = localStorage.getItem('authorizationToken');
        axios.post(`/api/Locations/${comp.state.pendingTransfers[0].locationId}/Transfer/${urlSuffix}`, {}, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
            .then(function () {
                var transfers = comp.state.pendingTransfers;
                transfers.shift();

                comp.setState({ pendingTransfers: transfers });

                localStorage.setItem("pendingLocationTransfers", JSON.stringify(transfers));
            })
            .catch(function () {
            });
    }

    render() {
        const currentTransfer = this.state.pendingTransfers[0];
        if (currentTransfer) {
            return (
                <Dialog
                    open={this.state.pendingTransfers.length > 0}
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Transfer Ownership"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {currentTransfer.invitor.firstName} {currentTransfer.invitor.lastName} would like to transfer
                        ownership of the location "{currentTransfer.locationName}" and its devices to you.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.handleTransferSelection.bind(this)(false) }} color="primary">
                            Decline
                        </Button>
                        <Button onClick={() => { this.handleTransferSelection.bind(this)(true) }} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
        else {
            return (<span />);
        }
    }
};

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    showNotification
};
//export default DeviceDashboardTab;
export default connect(mapStateToProps, mapDispatchToProps)(
    OwnershipTransferModal
);