import React, { Fragment } from 'react';
import axios from 'axios';
import {
    showNotification,
    SimpleForm,
    TextInput
} from 'react-admin';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { getPermissionList } from '../../Helpers.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faUndo, faExchangeAlt } from '@fortawesome/pro-solid-svg-icons';

class UserCardMenu extends React.Component {

    constructor(props) {
        super(props);

        // Initial state, we don't have any building types at the start.
        this.state = {
            confirmationDiagOpen: false,
            transferDiagOpen: false
        };
    }

    handleConfirmDiagClose() {
        this.setState({ confirmationDiagOpen: false });
    }

    rescindUserAccess() {
        var userId = this.props.recordId;
        var locationId = this.props.parentRecordId;

        var comp = this;

        const token = localStorage.getItem('authorizationToken');
        axios.delete(`/api/Locations/${locationId}/Rescind/${userId}`, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
            .then(function () {
                comp.handleConfirmDiagClose();
                comp.props.showNotification("elogic.toastNotifications.rescindUser");
            })
            .catch(function () {
                comp.handleConfirmDiagClose();
                comp.props.showNotification("elogic.toastNotifications.rescindUserError", "error");
            });
    }

    transferOwnership() {
        var locationId = this.props.parentRecordId;
        var comp = this;
        var data = {
            emailAddressToShareWith: document.getElementsByName("transferUserEmail")[0].value,
            groupRole: "1",
        }

        const token = localStorage.getItem('authorizationToken');
        axios.post(`/api/Locations/${locationId}/Transfer`, data, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
            .then(function (response) {
                comp.handleTransferDiagClose();
                comp.props.showNotification("elogic.toastNotifications.transferOwnership");
            })
            .catch(function (error) {
                comp.handleTransferDiagClose();
                comp.props.showNotification("elogic.toastNotifications.transferOwnershipError", "error");
            });
    }

    openConfirmDiag() {
        this.setState({ confirmationDiagOpen: true });
    }

    handleTransferDiagClose() {
        this.setState({ transferDiagOpen: false });
    }

    openTransferDiag() {
        this.setState({ transferDiagOpen: true });
    }

    render() {
        const Icon = {
            "fontWeight": "normal",
            "fontStretch": "normal",
            "fontStyle": "normal",
            "lineHeight": "1",
            "letterSpacing": "normal",
            "color": "#005DB9"
        }

        const Menu = {
            position: 'relative',
            top: '5px',
            right: '-7px'
        }

        const permissions = getPermissionList(this.props.parentRecord.permissions);

        var menuOptions = [];

        var currentUserId = localStorage.getItem("userId");

        if (permissions.Locations.Share && currentUserId != this.props.recordId && !this.props.isOwner)
            menuOptions.push(
                <FontAwesomeIcon icon={faMinusCircle} style={Icon} onClick={this.openConfirmDiag.bind(this)} className="monitorCardIcon" />
            );

        if (permissions.Locations.Transfer && this.props.isOwner)
            menuOptions.push(
                <FontAwesomeIcon icon={faExchangeAlt} style={Icon} onClick={this.openTransferDiag.bind(this)} className="monitorCardIcon" />
            );

        return (
            <Fragment>
                {menuOptions.length > 0 &&
                    <div style={Menu}>
                        {menuOptions}
                    </div>
                }
                <Dialog
                    open={this.state.confirmationDiagOpen}
                    onClose={this.handleConfirmDiagClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Rescinding access will remove selected user's access to the location and all associated devices.  They may only regain access by being re-invited.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmDiagClose.bind(this)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.rescindUserAccess.bind(this)} color="primary" autoFocus>
                            Rescind
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    open={this.state.transferDiagOpen}
                    onClose={this.handleTransferDiagClose.bind(this)}
                    aria-label="Transfer Ownership"
                >
                    <DialogTitle className="ModalTitle">Transfer Ownership</DialogTitle>
                    <span className="ModalText">Enter the email address of the user that you would like to transfer ownership to:</span>

                    <DialogContent>
                        <SimpleForm toolbar={null} submitOnEnter={false}>
                            <TextInput type="email" label="Email" source="transferUserEmail" />
                        </SimpleForm>
                    </DialogContent>

                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleTransferDiagClose.bind(this)}>Cancel</Button>
                        <Button className="ModalButton" label="ra.action.next" onClick={this.transferOwnership.bind(this)}>Begin Transfer</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
};

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    showNotification
};
//export default DeviceDashboardTab;
export default connect(mapStateToProps, mapDispatchToProps)(
    UserCardMenu
);
