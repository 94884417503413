import React from 'react';
import { Show, SimpleShowLayout, useAuthState } from 'react-admin';

import WattsLoading from '../WattsLoading';
import LocationViewLayout from './LocationViewLayout';

import { withStyles } from '@material-ui/core/styles';


class ViewLocationComponent extends React.Component {

    componentDidMount() {
    }

    render() {

        const layoutStyles = {
            'root': {
                'padding': 0,
                '&:first-child, &last-child': {
                    'padding': 0
                }
            }
        };

        const showStyles = {
            'main': {
                'margin': 0
            },
            'card': {
                'background': 'inherit',
                'border-radius': '0px',
                'boxShadow': 'none',
                'overflow': 'visible'
            }
        };

        const StyledShow = withStyles(showStyles)(Show);
        const StyledLayout = withStyles(layoutStyles)(SimpleShowLayout);

        const { classes, ...other } = this.props;

        return (
            <div>
                <StyledShow actions={null} {...other}>
                    <StyledLayout className={classes.locationBody}>
                        <LocationViewLayout record={this.props.record} />
                    </StyledLayout>
                </StyledShow>
            </div>
        );
    }
}

const ViewLocation = (props) => {

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return <ViewLocationComponent {...props} />;
};

const bodyStyles = {
    locationBody: {
        padding: '0 13%',
        "&:first-child": {
            paddingTop: 0
        },
        "&:last-child": {
            paddingBottom: 0
        }
    },

    tabContainer: {
        "& .MuiTabs-root": {
            background: 'white',
            padding: '0 13%'
        }
    },

    tabContent: {
        padding: '0 13px'
    }
};

export default withStyles(bodyStyles)(ViewLocation);