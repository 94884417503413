import React, { useState, useEffect }  from 'react';
import { Show, TabbedShowLayout, Tab, SimpleShowLayout, useAuthState } from 'react-admin';
import { Link } from 'react-router-dom';

import axios from 'axios';

import ScrollToTopOnMount from '../ScrollToTop'

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import WattsLoading from '../WattsLoading';

import DeviceDashboardTab from './DeviceDashboardTab';
import DeviceMonitorTab from './DeviceMonitorTab';
import DeviceScheduleTab from './DeviceScheduleTab';

import NoAccessPage from '../NoAccessPage';
import DeviceInfoModal from './DeviceInfoModal';
import DeviceBanners from './DeviceBanners';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';

import { getPermissionList, getDeviceImageOrDefault, getHelperStyles, getRecordOutletTemp } from '../../Helpers.js'

import '../css/Breadcrumbs.css';

const headerStyles = makeStyles({
    header: {
        padding: '30px 15.3%',
        background: '#fff',
        position: 'relative'
    },

    headerTextContent: {
        paddingTop: '34px'
    },

    headerImage: {
        height: '200px',
        float: 'right'
    },

    tempReadout: {
        margin: '0',
        fontSize: '64px',
        letterSpacing: '-5px'
    },

    mainTitle: {
        fontWeight: '900',
        fontSize: '44px',
        margin: '5px 0px',
        width: '100%'
    },

    subTitle: {
        'color': '#666666',
        'font-family': 'Roboto',
        'font-size': '16px',
        'font-weight': 'normal',
        'font-stretch': 'normal',
        'font-style': 'normal',
        'line-height': '1.5',
        'letter-spacing': '-0.16px'
    },

    topLeftInfo: {
        color: '#666',
        fontSize: '10px',
        marginBottom: '15px'
    },

    counterContainer: {
        display: 'inline-block',
        color: '#665C5C',
        marginTop: '10px'
    },

    counter: {
        display: 'inline-block',
        height: '30px',
        width: '50px',
        marginRight: '10px'
    },

    counterIcon: {
        display: 'inline-block',
        height: '100%'
    },

    counterNumber: {
        float: 'right',
        margin: '0 10px 0 0',
        lineHeight: '30px',
        verticalAlign: 'middle'
    },

    iconContainer: {
        position: 'absolute',
        top: '34px',
        right: '15.3%'
    },

    headerActionIcon: {
        color: '#005DB9',
        cursor: 'pointer',
        display: 'inline-block',
        margin: '0 14px',
        fontSize: '20px'
    },

    overline: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: '-0.12px',
        color: 'rgba(0, 0, 0, 0.87)'
    },

    brandContainer: {
        textAlign: 'right'
    },

    tempBrandContainer: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        marginBottom: '35px',
        paddingBottom: '25px'
    },

    brandImage: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    }
});

const DeviceHeader = (props) => {
    const classes = headerStyles();

    const permissions = getPermissionList(props.record.location.permissions);

    var locationName = '';
    if (props.record.location) {

        if (props.record.location.parentId) {
            locationName = `${props.record.parentLocationName}, ${props.record.location.name}`;
        }
        else {
            locationName = props.record.location.name;
        }
    }

    var deviceOutletTemp = getRecordOutletTemp(props.record, "- - -", true);

    const classTemplate = getHelperStyles()();

    const tempReadoutClass = classes.tempReadout + ' ' + classTemplate.heading2;
    const mainTitleClass = classes.mainTitle + ' ' + classTemplate.heading3;

    // For sublocations, they'll have a parentId for the parent location.
    var parentLocationId = props.record.location.parentId;
    if (!parentLocationId) {
        // But for parent locations, they won't have a parent id, so we use the location id itself.
        parentLocationId = props.record.location.locationId;
    }

    return (
        <div className={classes.header}>

            <ScrollToTopOnMount />
            <Breadcrumbs classes={{ root: `breadcrumbContainer`, ol: `breadcrumbBase` }} aria-label="breadcrumb">
                <Link className={`breadcrumbLink`} to={`/locations`}>
                    Locations
                </Link>
                <Link className={`breadcrumbLink`} to={`/locations/${parentLocationId}/show`}>
                    {props.record.parentLocationName}
                </Link>
                <p className={`breadcrumbCurrent`} color="primary">
                    {props.record.name}
                </p>
            </Breadcrumbs>
            <div className={classes.iconContainer}>
                <DeviceInfoModal className={classes.headerActionIcon} deviceRecord={props.record} />
                {permissions && permissions.Devices && permissions.Devices.Update && <Link to={`${props.basePath}/${props.id}`}><FontAwesomeIcon icon={faPencil} className={classes.headerActionIcon} /></Link>}
            </div>
            <Grid className={classes.headerTextContent} container>
                <Grid item xs={9}>
                    <Grid className={classes.tempBrandContainer} container>
                        <Grid item xs={6}>
                            <div className={classes.overline}>Mixed Outlet Temp</div>
                            <p className={tempReadoutClass}>{deviceOutletTemp}</p>
                        </Grid>
                        <Grid className={classes.brandContainer} item xs={6}>
                            <img src="/img/powers-logo.svg" alt="POWERS" className={classes.brandImage} />
                        </Grid>
                    </Grid>
                    <span className={classes.topLeftInfo} >{props.record.deviceType}</span>
                    <Typography
                        variant="h1"
                        component="h1"
                        display="block"
                        className={mainTitleClass} >{props.record.name}</Typography>
                    <p className={classes.subTitle} >{locationName}</p>
                </Grid>
                <Grid item xs={3}>
                    <img className={classes.headerImage} src={getDeviceImageOrDefault(props.record)} />
                </Grid>
            </Grid>
        </div>
    );
}

const bodyStyles = {
    locationBody: {
        padding: '0 15.3%',
        "&:first-child": {
            paddingTop: 0
        },
        "&:last-child": {
            paddingBottom: 0
        }
    },

    tabContainer: {
        "& .MuiTabs-root": {
            background: 'white',
            padding: '0 15.3%'
        }
    },

    tabContent: {
        padding: '0 15.3px'
    }
}

const DeviceViewLayout = (props) => {

    const [helpObject, setHelpObject] = useState();
    const [helpError, setHelpError] = useState();
    const [pendingHelp, setPendingHelp] = useState(false);


    useEffect(() => {
        if (!pendingHelp && !helpObject && !helpError) {

            setPendingHelp(true);

            const token = localStorage.getItem('authorizationToken');
            axios({
                method: 'get',
                url: `/api/AppSettings/helpcontent`,
                headers: {
                    "Cache-control": "no-cache, no-store",
                    "Pragma": "no-cache",
                    "Expires": 0,
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {

                var helpObject = {};

                response.data.forEach((result) => {
                    helpObject[result.key] = result;
                });


                setHelpObject(helpObject);
            }).catch(function (error) {
                setHelpError(error);
            });
        }
    });


    const tabbedShowLayoutStyles = {
        'content': {
            'padding': '0 13%'
        }
    };

    const StyledTabbedShowLayout = withStyles(tabbedShowLayoutStyles)(TabbedShowLayout);

    const { classes, ...other } = props;
    const bodyClasses = props.classes;

    if (props.record && props.record.errorNumber) {
        return <NoAccessPage>{props.record.errorMessage}</NoAccessPage>
    }
    else if (!helpObject && !helpError) {
        return <WattsLoading />;
    }
    else {
        return (
            <>
                <DeviceBanners {...other} />
                <DeviceHeader state={props.state} {...other} />
                <StyledTabbedShowLayout className={bodyClasses.tabContainer} {...other}>
                    <Tab label="Dashboard" className={bodyClasses.tabContainer}>
                        <DeviceDashboardTab helpObject={helpObject} helpError={helpError} />
                    </Tab >
                    <Tab label="Schedule" className={bodyClasses.tabContainer}>
                        <DeviceScheduleTab helpObject={helpObject} helpError={helpError} />
                    </Tab>
                    <Tab label="Monitor" className={bodyClasses.tabContainer}>
                        <DeviceMonitorTab helpObject={helpObject} helpError={helpError} />
                    </Tab>
                </StyledTabbedShowLayout>
            </>
        );
    }
}

class DeviceViewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            infoBanners: []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state === nextState) {
            return false;
        }
        else {
            return true;
        }
    }

    render() {

        const layoutStyles = {
            'root': {
                'padding': 0,
                '&:first-child, &last-child': {
                    'padding': 0
                }
            }
        };

        const showStyles = {
            'main': {
                'margin': 0
            },
            'card': {
                'background': 'inherit',
                'boxShadow': 'none',
                'overflow': 'visible'
            }
        };

        const StyledShow = withStyles(showStyles)(Show);
        const StyledLayout = withStyles(layoutStyles)(SimpleShowLayout);

        const { classes, ...other } = this.props;
        const bodyClasses = this.props.classes;

        return (
            <div>
                <StyledShow className={bodyClasses.body} actions={null} {...other}>
                    <StyledLayout className={bodyClasses.locationBody}>
                        <DeviceViewLayout state={this.state} {...this.props} />
                    </StyledLayout>
                </StyledShow>
            </div>
        );
    }
}

const DeviceView = (props) => {
    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return (
        <DeviceViewComponent {...props} />
    );
};

export default (
    withStyles(bodyStyles)(DeviceView)
);