import React from 'react';
import { TextField } from "react-admin";
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faGavel, faHospital, faHotel, faCity, faUserNurse, faChairOffice, faRunning, faSchool, faAmbulance } from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerAlt, faClock, faExclamationTriangle, faWifiSlash, faCheckCircle, faTimesOctagon } from '@fortawesome/pro-regular-svg-icons'

const styles = {
    card: {
        border: '1px solid #E0E0E0',
        boxShadow: 'none',
        display: 'inline-block',
        margin: '12px',
        verticalAlign: 'top',
        width: 328
    },

    locationType: {
        color: '#666',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: 0,
    },

    locationTypeHeader: {
        marginBottom: '56px',
        padding: '12px 0 0 16px'
    },

    locationIcon: {
        color: '#757575',
        fontSize: '32px',
        display: 'inline-block',
    },

    locationCardContent: {
        padding: '0 0 0 22px',
        marginBottom: 28
    },

    locationInfo: {
        display: 'inline-block',
        marginLeft: '20px'
    },

    locationName: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 'bold'
    },

    locationAddress: {
        color: '#767676',
        fontSize: '12px',
        margin: 0
    },

    footerOffline: {
        backgroundColor: '#F2F2F2',
        padding: '10px 24px',
        height: '25px'
    },

    footerOnline: {
        backgroundColor: '#CCF3CE',
        padding: '10px 24px',
        height: '25px'
    },

    footerWarning: {
        backgroundColor: '#FFE7D0',
        padding: '10px 24px',
        height: '25px'
    },

    footerError: {
        backgroundColor: '#FFCCCC',
        padding: '10px 24px',
        height: '25px'
    },

    statusIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'inline-block',
        fontSize: '20px',
        height: '100%',
        marginRight: '10px'
    },

    statusMessage: {
        display: 'inline-block',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '1.33',
        letterSpacing: '-0.12px',
        margin: 0,
        height: '100%',
        verticalAlign: 'middle',
        color: 'rgba(0, 0, 0, 0.54)'
    },

    footerCardContent: {
        "&:last-child": {
            paddingBottom: '5px'
        }
    }
};

const LocationField = withStyles(styles)(({ classes, record = {} }) => <p className={classes.locationAddress}>{record.address.city}, {record.address.state_province}</p>);
LocationField.defaultProps = { label: 'Name' };

const LocationIcon = withStyles(styles)(({ classes, locationType = 99 }) => {
    var map = {
        1: faUniversity,
        2: faSchool,
        3: faHospital,
        4: faUserNurse,
        5: faAmbulance,
        6: faHotel,
        7: faChairOffice,
        8: faRunning,
        9: faCity,
        10: faGavel
    }
    if (Object.keys(map).indexOf("" + locationType) === -1) {
        return <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.locationIcon} />;
    }
    else {
        return <FontAwesomeIcon icon={map[locationType]} className={classes.locationIcon} />;
    }
});

const StatusBar = withStyles(styles)(({ classes, status }) => {

    var footerClass = null;
    var footerIcon = null;

    if (status) {
        switch (status.id) {
            case 1: // Device errors
                footerClass = classes.footerError;
                footerIcon = faTimesOctagon;
                break;

            case 2: // Alerts
            case 5: // Savings mode
                footerClass = classes.footerWarning;
                footerIcon = faExclamationTriangle;
                break;

            case 4: // Sanitizing
                footerClass = classes.footerError;
                footerIcon = faClock;
                break;

            case 3: // Device offline
                footerClass = classes.footerWarning;
                footerIcon = faWifiSlash;
                break;

            case 6: // No devices added
                footerClass = classes.footerOffline;
                footerIcon = null;
                break;
            default:
                footerClass = classes.footerOnline;
                footerIcon = faCheckCircle;
        }
    }

    return (
        <CardContent className={`${footerClass} ${classes.footerCardContent}`}>
            {status && status.id != 6 &&
                <FontAwesomeIcon icon={footerIcon} className={classes.statusIcon} />
            }

            {status &&
                <p className={classes.statusMessage} style={status && status.id != 6 ? {} : { float: `none` }}>{status.name}</p>
            }
        </CardContent>
    )
});

export const LocationCard = withStyles(styles)(({ classes, dataRecord = {}, basePath }) => {

    // TODO: Localize
    var buildingType = {
        'name': 'Unknown'
    };

    if (dataRecord.buildingType) {
        buildingType = dataRecord.buildingType;
    }

    return (
        <Link to={`${basePath}/${dataRecord.id}/show`}>
            <Card key={dataRecord.id} className={classes.card}>
                <CardHeader className={classes.locationTypeHeader} disableTypography={true}
                    title={<div><p className={classes.locationType}>{buildingType.name}</p></div>}
                />
                <CardContent className={classes.locationCardContent}>
                    <LocationIcon locationType={buildingType.buildingTypeId} />
                    <div className={classes.locationInfo}>
                        <TextField record={dataRecord} className={classes.locationName} source="name" />
                        <LocationField record={dataRecord} />
                    </div>
                </CardContent>
                <StatusBar status={dataRecord.status} />
            </Card>
        </Link>
    )
});