import { makeStyles } from "@material-ui/core";
import { downXl, downLg, downMd, primary, downXs } from '../../styles.valiable'

export const useStyles = makeStyles({
    paper: {
        padding: 16,
        display: 'flex',
        position: "relative",
    },
    logo: {
        height:40,
        position: "absolute",
        bottom: 16,
        right: 16,
        [downXl]: {
            height: 20
        },
    },
    logoFirstTile: {
        [downXl]: {
            height: 18,
        },
    },
    arrowIcon: {
        position: 'absolute',
        right: 10,
        top: '38%',
        [downXl]: {
            height: 30
        },
        [downMd]: {
            height: 20
        },
    },
    subtitle : {
        fontSize: 22,
        marginBottom: 0,
        color: primary,
        [downXl]: {
            fontSize: 15,
        },
        [downLg]: {
            fontSize: 22,
        },
        [downMd]: {
            fontSize: 16,
        },
    },
    titleSection: {
        marginTop: 7,
        [downMd]: {
            marginTop: 10,
            marginBottom: 10
        },
    },
    title: {
        fontSize: 20,
        marginTop: 5,
        marginBottom: 12,
        fontWeight: "bold",
        color: primary,
        [downXl]: {
            fontSize: 17,
        },
        [downXs]: {
            fontSize: 15,
        },
    }
});