import { makeStyles } from "@material-ui/core/styles";
import { downXl, downLg, downMd, downXs } from "../styles.valiable";

export const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        "& .MuiAppBar-root": {
            display: "none",
        },
    },
    link: {
        textDecoration: "none",
    },
    body: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition:'center',
        height: '100vh',
        minHeight: 580,
        minWidth: 320
    },
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoContainer: {
        [downMd]: {
            display: 'flex',
            justifyContent: 'center'
        },
    },
    logo: {
        height: 94,
        textAlign: "center",
        [downXl]: {
            height: 65,
        },
        [downMd]: {
            width: '80%',
        },
    },
    title: {
        color: '#fff',
        fontSize: 30,
        fontFamily: "Helvetica Neue",
        textAlign: "center",
        [downXl]: {
            fontSize: 21,
        },
        [downMd]: {
            fontSize: 16,
        },
    },
    tiles: {
        marginTop: 60,
        display: "flex",
        [downXl]: {
            marginTop: 0,
        },
        [downLg]: {
            flexDirection: 'column'
        },
        [downMd]: {
            width: '92%'
        },
    },
    tile: {
        margin: "40px 24px",
        width: 580,
        [downXl] : {
            width: 382,
        },
        [downLg]: {
            margin: '12px 0',
            width: 580,
        },
        [downMd]: {
            width: '100%',
        },
        '& > a' : {
            textDecoration: 'none'
        }
    },
    image: {
        height: 160,
        paddingRight: 12,
        [downMd]: {
            height: 125
        },
        [downXs]: {
            height: 102
        },
    }}
);
