import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeviceInfoBanner = (props) => {

    const ribbonStyles = makeStyles({
        ribbonBase: {
            backgroundColor: props.color,
            color: "#211102",
            cursor: "pointer",
            left: '0',
            minHeight: "20px",
            padding: '14px 15.3%',
            position: 'sticky',
            top: '0',
            zIndex: '1200'
        },

        userMessage: {
            display: "inline",
            marginLeft: "14px",
            fontSize: "14px",
            lineHeight: "1.43",
            letterSpacing: "-0.14px",
            verticalAlign: "bottom"
        },

        actionText: {
            display: "inline",
            margin: "0",
            fontWeight: "900",
            fontSize: "14px",
            lineHeight: "1.44",
            letterSpacing: "-0.14px",
            float: "right"
        }
    });

    const useStyles = ribbonStyles();

    return (
        <div className={useStyles.ribbonBase} onClick={props.callback}>
            <FontAwesomeIcon icon={props.icon} />
            <p className={useStyles.userMessage}>{props.userMessage}</p>
            <h3 className={useStyles.actionText}>{props.actionText}</h3>
        </div>
    );
};

export default DeviceInfoBanner;