import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WattsLoading from './WattsLoading';

const Cards = (props) => {
    if (props && props.data && (props.data.fetchedAt || (props.data.fetchedAt && Object.keys(props.data.fetchedAt).length) || props.loaded)) {
        var noEntryMessage = undefined;
        if (props.noResultsMessage != undefined) {
            noEntryMessage = props.noResultsMessage();
        }

        if (noEntryMessage == undefined) {
            noEntryMessage = (
                <div style={{ display: 'none' }} />
            );
        }

        if (props.ids.length == 0) {
            return noEntryMessage;
        }
        else {
            return (
                <div>
                    {props.ids.sort((first, second) => {

                        return props.sortFunction(props.data[first], props.data[second]);
                    }).map(id =>
                        <props.cardComponent
                            dataRecord={props.data[id]}
                            key={`cardComp${id}`}
                            callbacks={props.callbacks}
                            parentRecordId={props.parentRecordId}
                            {...props}
                        />
                    )}
                </div>
            );
        }
    }
    else {
        return (
            <WattsLoading />
        );
    }
}

Cards.defaultProps = {
    data: {},
    ids: [],
};

export const CardGrid = (props) => {

    return (
        <Cards {...props} />
    );
};