import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const requirementStyles = makeStyles({
    requiredText: {
        float: 'right',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '-0.12px',
        color: '#666'
    }
});

const RequiredFieldNote = (props) => {

    const classes = requirementStyles();

    return (
        <p className={classes.requiredText}>* Required</p>
    );
};

export default RequiredFieldNote;