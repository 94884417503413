import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import axios from 'axios';

import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import '../css/HelpModal.css';
import '../css/DeviceInfoModal.css';
import { getDeviceImageOrDefault, getLocationAddress } from '../../Helpers.js';

const headerActionIcon = {
    color: '#005db9',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 14px',
    fontSize: '20px'
}

class DeviceInfoModal extends Component {

    state = {
        error: false,
        showDialog: false,
        deviceData: {
            imageUrl: "",
            modelName: "",
            modelNumber: "",
            firmware: "",
            deviceType: "",
            name: "",
            location: "",
            subLocation: "",
            address: "",
        }
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    setStateData = (deviceData) => {
        this.setState({ deviceData: deviceData });
    }

    componentDidMount() {

        var jsonHeaders = {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0
            }
        };
        var comp = this;
        var deviceRecord = this.props.deviceRecord;

        // Get/Set Device Info
        // TODO - need a way to get Firmware version
        //      - Need to implement sublocations
        //      - How do we can model name vs model number from API?
        // TEMP Device Data

        // TODO: Remove this from the state
        this.state.deviceData.imageUrl = getDeviceImageOrDefault(deviceRecord);
        this.state.deviceData.modelName = deviceRecord.modelNumber;
        this.state.deviceData.modelNumber = deviceRecord.modelNumber;
        this.state.deviceData.deviceType = deviceRecord.deviceType;
        this.state.deviceData.name = deviceRecord.name;
        this.state.deviceData.location = deviceRecord.parentLocationName;
        this.state.deviceData.installationImage = deviceRecord.installationImageUrl;

        var firmware = 'N/A';
        if (deviceRecord.data && deviceRecord.data.sw && deviceRecord.data.sw.Release) {
            firmware = deviceRecord.data.sw.Release;
        }

        this.state.deviceData.firmware = firmware;

        // Build Address
        var address = "";
        if (deviceRecord.location) {
            if (deviceRecord.location.address && !deviceRecord.location.parentId) {
                this.state.deviceData.address = getLocationAddress(deviceRecord.location.address);
            }
            else if (deviceRecord.location.parentId) {
                const token = localStorage.getItem('authorizationToken');
                axios({
                    method: 'get',
                    url: `/api/locations/${deviceRecord.location.parentId}`,
                    headers: {
                        "Cache-control": "no-cache, no-store",
                        "Pragma": "no-cache",
                        "Expires": 0,
                        "Authorization": `Bearer ${token}`
                    }
                }).then((results) => {

                    // If it's a sublocation, we need to pull the location address data from the parent location.
                    var deviceResults = this.state.deviceData;
                    deviceResults.address = getLocationAddress(results.data.address);
                    this.setState({
                        deviceData: {
                            ...deviceResults
                        }
                    })
                });
            }

            //Sublocation
            if (deviceRecord.location.parentId) {
                this.state.deviceData.subLocation = deviceRecord.location.name;
            }
            this.setState({ deviceData: this.state.deviceData });
        }
    }

    getDeviceImageRow() {
        if (this.props.deviceRecord.installationImageUrl) {
            return (
                <div className={"deviceInfoModal-content-row"}>
                    <div className={"deviceInfoModal-content-row-text"}>
                        <span className={"deviceInfoModal-content-title"}>Installation Image</span><br />
                        <span className={"deviceInfoModal-content-text"}><img src={this.props.deviceRecord.installationImageUrl} className={this.props.classes.installPhoto} /></span>
                    </div>
                </div>
            );
        }
        else {
            return (<span />);
        }
    }

    render() {
        const { showDialog } = this.state;

        return (
            <Fragment>
                <div onClick={this.handleClick} style={headerActionIcon}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>

                <Dialog className="helpModal deviceInfoModal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Help"
                >
                    <DialogTitle className="helpModalTitle">Device Info</DialogTitle>

                    <DialogContent>

                        <div className="deviceInfoModal-content">

                            <div className={"deviceInfoModal-header"}>
                                <img src={this.state.deviceData.imageUrl} alt="device" width="200" /><br />
                                <span className={"deviceInfoModal-header-title"}>Powers</span><br />
                                <span className={"deviceInfoModal-header-modelName"}>{this.state.deviceData.modelName}</span><br />
                                <span className={"deviceInfoModal-content-title"}>{this.state.deviceData.deviceType}</span>
                            </div>

                            <div className={"deviceInfoModal-content-row deviceInfoModal-gray"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Model</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{this.state.deviceData.modelNumber}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Firmware</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{this.state.deviceData.firmware}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row deviceInfoModal-gray"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Device Name</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{this.state.deviceData.name}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row deviceInfoModal-address-row"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Location</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{this.state.deviceData.location}</span><br />
                                    <span className={"deviceInfoModal-content-address"}>{this.state.deviceData.address}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row deviceInfoModal-gray"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Sublocation</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{this.state.deviceData.subLocation}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Location Description</span><br />
                                    <span className={"deviceInfoModal-content-address"}>{this.props.deviceRecord.locationDescription}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row deviceInfoModal-gray"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Commission Date</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{moment(this.props.deviceRecord.commissionDate).toDate().toLocaleDateString()}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Installation Date</span><br />
                                    <span className={"deviceInfoModal-content-text"}>{moment(this.props.deviceRecord.installationDate).toDate().toLocaleDateString()}</span>
                                </div>
                            </div>

                            <div className={"deviceInfoModal-content-row deviceInfoModal-gray"}>
                                <div className={"deviceInfoModal-content-row-text"}>
                                    <span className={"deviceInfoModal-content-title"}>Additional Notes</span><br />
                                    <span className={"deviceInfoModal-content-address"}>{this.props.deviceRecord.additionalNotes}</span>
                                </div>
                            </div>

                            {this.getDeviceImageRow()}

                        </div>

                    </DialogContent>

                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleCloseClick}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

const infoModalStyles = {
    installPhoto: {
        maxWidth: '400px'
    }
}

export default withStyles(infoModalStyles)(connect(mapStateToProps, mapDispatchToProps)(
    DeviceInfoModal
));