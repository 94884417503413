import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { faSyncAlt, faClock } from '@fortawesome/pro-regular-svg-icons';

import DeviceInfoBanner from './DeviceInfoBanner';
import DeviceErrorHelpDialog from './DeviceErrorHelpDialog';
import { getUserTempScale } from '../../Helpers.js';

const bannerStyles = makeStyles({
    container: {
        left: '0',
        position: 'sticky',
        top: '0',
        zIndex: '1200'
    },
    spacedBanner: {
        marginRight: '20px'
    },
    tempUnit: {
        letterSpacing: '0'
    }
});

const DeviceBanners = (props) => {
    const [banners, setBanners] = useState([]);
    const [sanitizeActive, setSanitizeActive] = useState(false);
    const [showSanitizationModal, setShowSanitizationModal] = useState(false);
    const [checkHash, setCheckHash] = useState("");
    const [refreshTimer, setRefreshTimer] = useState();

    const useStyles = bannerStyles();

    useEffect(() => {
        return () => {
            if (refreshTimer) {
                clearInterval(refreshTimer);
            }
        };
    }, [refreshTimer]);

    const checkForUpdatedData = function (refreshCallback, refreshTimer) {
        const token = localStorage.getItem('authorizationToken');
        axios.get(`/api/Devices/${props.record.id}`, {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        })
            .then(function (results) {
                if (results.data.CheckHash != props.record.CheckHash) {
                    clearInterval(refreshTimer);
                    var ribbons = banners;
                    ribbons.push(
                        (<DeviceInfoBanner
                            color={"#ff8613"}
                            icon={faSyncAlt}
                            userMessage={"New data is available. Reload the page to see the latest data."}
                            actionText={"Update Device Data"}
                            callback={refreshCallback}
                        />)
                    );

                    setBanners([...ribbons]);
                }
            });
    }

    const addSanitizationBanner = function(record) {
        var ribbons = banners;

        const onclick = function () {
            setShowSanitizationModal(true);
        }

        let sanitizeSetpoint = props.record.data.SanitizeEvent.MixOutletSetpoint;

        ribbons.push(
            <DeviceInfoBanner
                color={"#F7C4C4"}
                icon={faClock}
                userMessage={
                    <>
                        <span className={useStyles.spacedBanner}>The device is sanitizing.</span>
                        <span className={useStyles.spacedBanner}>Sanitization Setpoint: {sanitizeSetpoint}<span className={useStyles.tempUnit}>°{getUserTempScale()}</span>.</span>
                        <span className={useStyles.spacedBanner}>Time Remaining: {record.sanitizeEndTime}.</span>
                    </>
                }
                actionText={"Show Event Details"}
                callback={onclick}
            />
        );

        setBanners(ribbons);
    }

    if (checkHash != props.record.CheckHash) {
        setCheckHash(props.record.CheckHash);

        var refreshPage = () => { window.location.reload(); };

        clearInterval(refreshTimer);
        var timer = window.setInterval(() => {
            checkForUpdatedData(refreshPage, timer);
        }, 30000);

        setRefreshTimer(timer);
    }

    if (!sanitizeActive && props.record.sanitizeEndTime != null) {
        setSanitizeActive(true);
        addSanitizationBanner(props.record);
    }

    const closeSanitizationModal = function () {
        setShowSanitizationModal(false);
    }

    return (
        <div className={useStyles.container}>
            {banners}
            <DeviceErrorHelpDialog
                status={props.record.status}
                open={showSanitizationModal}
                onClose={closeSanitizationModal}
            />
        </div>
    );
}

export default DeviceBanners;