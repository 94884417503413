import React from 'react';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-solid-svg-icons';


const noAccessStyles = makeStyles({
    container: {
        left: '50vw',
        marginTop: '64px',
        position: 'absolute',
        textAlign: 'center',
        top: '40vh',
        transform: 'translate(-50%, -50%)',
        width: '300px'
    },
    banIcon: {
        color: 'rgba(0, 0, 0, 0.12)',
        fontWeight: 'normal',
        fontSize: '80px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1',
        letterSpacing: 'normal',
        textAlign: 'center'
    },
    message: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: '-0.16px',
        lineHeight: 'normal',
        margin: '30px 0',
        textAlign: 'center'
    }
});

const NoAccessPage = (props) => {
    let history = useHistory();

    const useStyles = noAccessStyles();

    return (
        <div className={useStyles.container}>
            <FontAwesomeIcon icon={faBan} className={useStyles.banIcon} />
            <Typography className={useStyles.message}>
                {props.children}
            </Typography>
            <Button variant="contained" color="primary" onClick={() => history.push('/locations')}>
                Ok
            </Button>
        </div>
    );
};

export default NoAccessPage;