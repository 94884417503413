import React from "react";
import Paper from "@material-ui/core/Paper";

import { useStyles } from './style'


export const ApplicationTitle = ({ logo, applicationImage, devices, classImage, isFirstTile }) => {
    const classes = useStyles();
    const additionalClass = isFirstTile ? classes.logoFirstTile : "";

  return (
    <Paper className={classes.paper} variant="outlined">
      <img
        className={`${classes.image} ${classImage}`}
        alt="Watts application logo"
        src={applicationImage}
      />
      <div className={classes.titleSection}>
        {devices && devices.map(device => <p key={device} className={classes.title}>{device}</p>)}
      </div>
          <img
            className={`${classes.logo} ${additionalClass}`}
            src={logo}
            alt="Watts company logo"
      />
    </Paper>
  );
};
