import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';

import { renderTemperature } from '../../Helpers.js';
import '../css/TelemetryCard.css';

// Monitor Cards
const TelemetryCard = (props) => {

    // Set Date
    var dateContent = null;
    if (props.date) {

        // Verify the date passed in is valid.
        var parsedDate = Date.parse(props.date);

        // Only display the date content when the date value is a valid date.
        if (!isNaN(parsedDate)) {
            dateContent = (
                <div className="monitorCardDate">
                    <FontAwesomeIcon icon={faCalendarDay} className="errorLogCardIcon monitorCardDateIcon" />
                    <span>{props.date}</span>
                </div>
            );
        }
    }

    var className = props.className;

    if (!props.className) {
        className = "monitorCard"
    }

    var telemetryValue = renderTemperature(props.value);

    if (!props.value) {
        telemetryValue = '- - -';
    }

    return (
        <div className={className}>
            <div className="monitorCardTitle">
                {props.iconType}
                <p className="monitorCardMessage">{props.message}</p>
            </div>

            <div className="monitorCardValue">{telemetryValue}</div>

            {dateContent}
        </div>
    );
}

export default TelemetryCard;