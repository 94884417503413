import { 
    faWifiSlash, 
    faTimesOctagon, 
    faClock, 
    faExclamationCircle,
    faThermometerFull,
    faThermometerQuarter,
    faCheckCircle,
    faWrench,
    faLeaf,
    faBolt
} from '@fortawesome/pro-regular-svg-icons';

// red      '#FFCCCC'
// orange   '#FFE7D0'
// green    '#CCF3CD'

const statuses = {
    0 : { // No Status
        "icon": faTimesOctagon,
        "color" : "red"
    },
    1 : { // NVM Error
        "icon": faTimesOctagon,
        "color" : "red"
    },
    2 : { // Sensor Error
        "icon": faTimesOctagon,
        "color" : "red"
    },
    3 : { // Manual Mixing Mode
        "icon": faWrench,
        "color" : "green"
    },
    4 : { // High Temperature Alert
        "icon": faThermometerFull,
        "color" : "orange"
    },
    5 : { // Low Temperature Alert
        "icon": faThermometerQuarter,
        "color" : "orange"
    },
    6 : { // Device Offline
        "icon": faWifiSlash,
        "color" : "orange"
    },
    7 : { // Sanitizing
        "icon": faClock,
        "color" : "red"
    },
    8 : { // Cooling Down
        "icon": faExclamationCircle,
        "color" : "red"
    },
    9 : { // Maintaining Setpoint
        "icon": faCheckCircle,
        "color" : "green"
    },
    10 : { // Valve Selection is not Set
        "icon": faExclamationCircle,
        "color" : "orange"
    },
    11 : { // Startup
        "icon": faBolt,
        "color" : "green"
    },
    12 : { // Within Limits
        "icon": faCheckCircle,
        "color" : "green"
    },
    13 : { // Saving Mode
        "icon": faLeaf,
        "color" : "green"
    },
};

export default statuses;

export const SANITIZATION_STATUS = 7;