import React, { useState} from 'react';

import { TabbedShowLayout, Tab } from 'react-admin';
import { Link } from 'react-router-dom';

import ScrollToTopOnMount from '../ScrollToTop'
import { DeviceCard } from '../Devices/DeviceCard';

import AddDeviceOrUserButton from '../Buttons/AddDeviceOrUserButton';
import DeleteModal from "../DeleteModal";
import { UserCard } from './UserCard';
import { CardGrid } from '../CardGrid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faWifi, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faPencil, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import WattsLoading from '../WattsLoading';
import LeaveLocationDialog from './LeaveLocationDialog';
import LocationBanner from './LocationBanner';
import NoAccessPage from '../NoAccessPage';
import { getPermissionList, getHelperStyles, getLocationAddress, prioritySort} from '../../Helpers.js';

import '../css/Breadcrumbs.css';

const headerStyles = makeStyles({
    header: {
        padding: '30px 15.3%',
        background: '#fff'
    },

    mainTitle: {
        fontWeight: 'bold',
        fontSize: '44px',
        margin: '25px 0px'
    },

    subTitle: {
        color: '#666666'
    },

    topLeftInfo: {
        color: '#999',
        fontSize: '12px'
    },

    counterContainer: {
        display: 'inline-block',
        color: '#838489',
        marginTop: '10px'
    },

    counter: {
        display: 'inline-block',
        height: '30px',
        minWidth: '50px',
        marginRight: '10px'
    },

    counterIcon: {
        display: 'inline-block',
        height: '100%',
        fontSize: '20px'
    },

    counterNumber: {
        float: 'right',
        margin: '0 10px 0 5px',
        lineHeight: '30px',
        verticalAlign: 'middle'
    },

    headerActionItem: {
        float: 'right',
        color: '#005DB9',
        cursor: 'pointer',
        marginLeft: '20px',
        fontSize: '20px'
    }
});


const LocationHeader = (props) => {

    var state = props.state;

    var permissions = getPermissionList(props.record.permissions);

    // TODO: Localize this
    var buildingType = {
        'name': 'Unknown'
    };

    if (props.record.buildingType && props.record.buildingType.name) {
        buildingType = props.record.buildingType;
    }

    // set location and device counters here - setCounterValues function
    var deviceCounter = 0;
    var sublocationsCounter = 0;

    // Get Parent Devices
    if (props.record.devices && props.record.devices.length > 0) {
        props.record.devices.forEach(function (device) {
            deviceCounter++;
        });
    }

    // Get Sublocation names and devices
    if (props.record.sublocations && props.record.sublocations.length > 0) {
        props.record.sublocations.forEach(function (sublocation) {
            sublocationsCounter++;
            if (sublocation.devices && sublocation.devices.length > 0) {
                sublocation.devices.forEach(function (subDevice) {
                    deviceCounter++;
                });
            }
        });
    }

    // set device and location counter numbers
    props.setCounterValues(sublocationsCounter, deviceCounter);
    const classes = headerStyles();
    const classTemplate = getHelperStyles()();

    const userId = localStorage.getItem('userId');
    const mainTitleClass = classes.mainTitle + ' ' + classTemplate.heading3;

    return (
        <div className={classes.header}>
            <ScrollToTopOnMount />
            <Breadcrumbs classes={{ root: `breadcrumbContainer`, ol: `breadcrumbBase` }} aria-label="breadcrumb">
                <Link className={`breadcrumbLink`} to={`/locations`}>
                    Locations
                </Link>
                <p className={`breadcrumbCurrent`} color="primary">
                    {props.record.name}
                </p>
            </Breadcrumbs>
            <span className={classes.topLeftInfo} >{buildingType.name}</span>
            {permissions && permissions.Locations && permissions.Locations.Delete && <span className={classes.headerActionItem}><DeleteModal thisRecord={props.record} deviceCounter={deviceCounter} type="location" /></span>}
            {props.userData.ownerId && props.userData.ownerId != userId && <span className={classes.headerActionItem}><LeaveLocationDialog thisRecord={props.record} /></span>}
            {permissions && permissions.Locations && permissions.Locations.Update && <Link to={`${props.basePath}/${props.record.id}`} className={classes.headerActionItem}><FontAwesomeIcon icon={faPencil} /></Link>}
            <h1 className={mainTitleClass} >{props.record.name}</h1>
            <p className={classes.subTitle}>{getLocationAddress(props.record.address)}</p>
            <div className={classes.counterContainer} >
                <div className={classes.counter}>
                    <FontAwesomeIcon icon={faBuilding} className={classes.counterIcon} />
                    <p className={classes.counterNumber} >{state.numberSubLocations}</p>
                </div>
                <div className={classes.counter}>
                    <FontAwesomeIcon icon={faWifi} className={classes.counterIcon} />
                    <p className={classes.counterNumber} >{state.numberDevices}</p>
                </div>
                <div className={classes.counter}>
                    <FontAwesomeIcon icon={faUsers} className={classes.counterIcon} />
                    <p className={classes.counterNumber} >{props.userData.users.length}</p>
                </div>
            </div>
        </div>
    );
}

const noLocationStyles = makeStyles({
    container: {
        textAlign: 'center',
        paddingTop: '180px'
    },

    largeIcon: {
        fontSize: '100px',
        color: '#D7D7D7'
    },

    message: {
        color: '#626262',
        fontSize: '16px',
        margin: '30px 0',
    },

    addIcon: {
        fontSize: '16px',
        verticalAlign: 'middle'
    }
});
const NoLocationMessage = () => {
    const classes = noLocationStyles();
    return (
        <div id="noLocationsMessage" className={classes.container}>
            <FontAwesomeIcon icon={faWifi} className={classes.largeIcon} />
            <p className={classes.message}>Tap <FontAwesomeIcon icon={faPlusCircle} className={classes.addIcon} /> to add your first device.</p>
        </div>
    );
}

const sublocationMenuStyles = makeStyles({
    sublocationFilter: {
        'width': '330px',
        'marginLeft': '12px'
    }
});

const Devices = (props) => {
    const [locationFilter, setLocation] = useState("All")
    const handleChange = (event) => {
        setLocation(event.target.value);
    };

    var idArray = [];
    var data = {};

    var returnContent = <NoLocationMessage />;

    var devices = [];
    var sublocations = [];

    var deviceCounter = 0;

    const sublocationMenuUseStyles = sublocationMenuStyles();

    var menuItems = [];
    menuItems.push(<MenuItem value={"All"} key="SubLocationFilter-All">All Sublocations</MenuItem>);

    // default status (used if device status is missing (NULL))
    const missingStatus = {
        "id": 0,
        "name": "No Status",
        "priority": 14
    };

    // Get Parent Devices
    if (props.location.devices && props.location.devices.length > 0) {
        props.location.devices.forEach(function (device) {
            deviceCounter++;
            if (!device.status) {
                device.status = missingStatus;
            }
            // check filter - show if all or parent location is set
            if (locationFilter === "All" || locationFilter === `${props.location.name}`) {
                devices.push(device);
            }
        });
    }

    // Get Sublocation names and devices
    if (props.location.sublocations && props.location.sublocations.length > 0) {
        props.location.sublocations.forEach(function (sublocation, i) {
            // get sublocation name
            sublocations.push(sublocation.name);
            menuItems.push(<MenuItem value={`${sublocation.name}`} key={`SubLocationFilter-${i}-${sublocation.name}`}>{sublocation.name}</MenuItem>);

            // get sublocation devices
            if (sublocation.devices && sublocation.devices.length > 0) {
                sublocation.devices.forEach(function (subDevice) {
                    deviceCounter++;
                    if (!subDevice.status) {
                        subDevice.status = missingStatus;
                    }
                    // check filter - show if all or sublocation location is set
                    if (locationFilter === "All" || locationFilter === `${sublocation.name}`) {
                        devices.push(subDevice);
                    }
                });
            }
        });
    }

    // default card grid = no location message
    var cards = <NoLocationMessage />;

    // if devices are found after filter - set device card grid
    if (devices.length > 0) {
        // sort by priority
        devices.sort(function (a, b) {
            return a.status.priority - b.status.priority;
        });

        // map device data for device cards
        devices.forEach(function (device) {
            idArray.push(device.deviceId);
            data[device.deviceId] = device;
        });

        cards = (
            <CardGrid loaded={true} sortFunction={prioritySort} ids={idArray} data={data} cardComponent={DeviceCard} basePath={`/devices`} location={`/devices`} noResultsMessage={NoLocationMessage} />
        );
    }

    // show form as long as this location has at least one device total (including sublocations - regardless of filter)
    // AND if this location has at least one sublocation
    if (deviceCounter > 0 && sublocations.length > 0) {
        // set menu items
        var dropdown = (
            <FormControl className={sublocationMenuUseStyles.sublocationFilter}>
                <InputLabel id="select-sublocation-select-label">Select Sublocation</InputLabel>
                <Select
                    labelId="select-sublocation-select-label"
                    id="select-sublocation-select"
                    value={locationFilter}
                    onChange={handleChange}
                >
                    {menuItems}
                </Select>
            </FormControl>
        );
        returnContent = (
            <div>
                {dropdown}
                {cards}
            </div>
        );
    }
    // else just show cards if there are no sublocations for this location
    else if (deviceCounter > 0) {
        returnContent = (
            <div>
                {cards}
            </div>
        );
    }
    else if (props.location && props.location.devices == undefined) {
        returnContent = (<WattsLoading />);
    }

    return returnContent;
}

const bodyStyles = {
    locationBody: {
        padding: '0 13%',
        "&:first-child": {
            paddingTop: 0
        },
        "&:last-child": {
            paddingBottom: 0
        }
    },

    tabContainer: {
        "& .MuiTabs-root": {
            background: 'white',
            padding: '0 13%'
        }
    },

    tabContent: {
        padding: '0 13px'
    }
};

class LocationViewLayout extends React.Component {

    constructor(props) {
        super(props);
        this.setCounterValues = this.setCounterValues.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.state = {
            userCardList: [],
            numberSubLocations: "0",
            numberDevices: "0",
            ownerId: null
        };
    }

    getRole = (obj) => {
        var role = "";

        if (obj.permissions.length) {
            role = obj.permissions[0].roles[0];
        }

        return role;
    }


    getUserData() {
        var idArray = [];
        var data = {};
        var ownerId = null;

        // Process the user records from the API.
        this.props.record.users.forEach(function (user) {

            // Create a list of user ids.
            idArray.push(user.userId);

            // And a mapping of user ids to users for lookup purposes.
            data[user.userId] = user;

            // Determine the owner of the location.
            user.permissions.forEach((value) => {
                if (value.roles.indexOf("Owner") > -1) {
                    ownerId = user.userId;
                }
            });
        });

        return {
            users: idArray,
            data: data,
            ownerId: ownerId
        };
    }

    setCounterValues(locCounter, deviceCounter) {
        this.state.numberSubLocations = locCounter;
        this.state.numberDevices = deviceCounter;
    }
    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(this.props.record) !== JSON.stringify(nextProps.record);
    }


    render() {

        const tabbedShowLayoutStyles = {
            'content': {
                'padding': '0 13%',
                'marginTop': '12px'
            }
        };

        const StyledTabbedShowLayout = withStyles(tabbedShowLayoutStyles)(TabbedShowLayout);
        const { classes, ...other } = this.props;
        const comp = this;

        if (this.props.record && this.props.record.errorNumber) {
            return <NoAccessPage>{this.props.record.errorMessage}</NoAccessPage>
        }
        else if (!this.props.record || (!this.props.record.users)) {
            // Don't render the page until the record contains the "users" property. When clicking a location card,
            // react-admin attempts to render the location page with the information it has, then lazy load the rest
            // of the data. Since this will cause stuttering in the UI, render a loading spinner until the UI has all of
            // the information it needs.
            return <WattsLoading />;
        }
        else {
            const userData = this.getUserData();

            const permissions = getPermissionList(this.props.record.permissions);

            return (
                <div>
                    <LocationBanner {...other} />
                    <LocationHeader state={this.state} {...other} userData={userData} setCounterValues={this.setCounterValues} />
                    {(permissions && permissions.Devices && permissions.Devices.Create || permissions && permissions.Locations && permissions.Locations.Share) &&
                        <AddDeviceOrUserButton locationid={this.props.record.locationId} permissions={permissions} />
                    }
                    <StyledTabbedShowLayout className={classes.tabContainer}>
                        <Tab label="Devices" className={classes.tabContainer}>
                            <Devices location={this.props.record} />
                        </Tab >
                        <Tab label="Users" className={classes.tabContainer}>
                            <CardGrid
                                ids={userData.users}
                                data={userData.data}
                                cardComponent={UserCard}
                                basePath={`/users`}
                                location={`/users`}
                                parentRecordId={this.props.record.id}
                                parentRecord={this.props.record}
                                loaded={true}
                                callbacks={
                                    {
                                        onUserRescindSuccess: {
                                            component: comp
                                        }
                                    }
                                }
                                sortFunction={(firstObj, secondObj) => {

                                    const sortOrder = ["Admin", "Owner"];

                                    const firstRole = comp.getRole(firstObj);
                                    const secondRole = comp.getRole(secondObj)

                                    return sortOrder.indexOf(secondRole) - sortOrder.indexOf(firstRole);
                                }}
                            />
                        </Tab>
                    </StyledTabbedShowLayout>
                </div>
            );
        }
    }
}

export default withStyles(bodyStyles)(LocationViewLayout);
