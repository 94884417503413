import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route, BrowserRouter } from 'react-router-dom';
import { Redirect } from "react-router";
import { useAuthState } from "react-admin";

// Localization
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import { authProvider } from './AuthProvider';
import WattsTheme from './Colors/WattsTheme';
import WattsLayout from './Components/WattsLayout';
import WattsLoginPage from './Components/WattsLoginPage';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// Locations
import CreateLocation from './Components/Locations/CreateLocation';
import EditLocation from './Components/Locations/EditLocation';
import ViewLocation from './Components/Locations/LocationView';

// Dashboard
import { Dashboard } from './Components/Dashboard';
import { routes } from './Components/Dashboard/routes';

// Devices
import CreateDevice from './Components/Devices/CreateDevice';
import EditDevice from './Components/Devices/EditDevice';
import DeviceView from './Components/Devices/DeviceView';

// User
import EditSettings from './Components/Settings/EditSettings';
import EditAccount from './Components/Account/EditAccount';

import dataProvider from './dataProvider';
import { CardList } from './Components/CardList';
import { LocationCard } from './Components/Locations/LocationCard';
import { prioritySort } from './Helpers.js';

englishMessages.elogic = {
  loading: 'Loading...',
  toastNotifications: {
    setMixedOutletSetpointValue: 'Updated Mixed Outlet Setpoint',
    setMixedOutletSetpointValueError: 'Error Updating Mixed Outlet Setpoint',
    setHighTempDiffValue: 'Updated High Temperature Alert Differential',
    setHighTempDiffValueError:
      'Error Updating High Temperature Alert Differential',
    setLowTempDiffValue: 'Updated Low Temperature Alert Differential',
    setLowTempDiffValueError:
      'Error Updating Low Temperature Alert Differential',
    setSetbackOffset: 'Updated Setback Offset',
    setSetbackOffsetError: 'Error Updating Setback Offset',
    inviteUser: 'User invited.',
    inviteUserError: 'Failed to invite User',
    rescindUser: 'Access rescinded.',
    rescindUserError: 'Failed to Rescind User',
    transferOwnership: 'Ownership Transfer Requested',
    transferOwnershipError: 'Failed to Create Transfer Ownership Request',
    setClearMixedOutletHigh: 'The mixed outlet high value has been cleared.',
    setClearMixedOutletHighError:
      'There was an error clearing the mixed outlet high value.',
    setClearMixedOutletLow: 'The mixed outlet low value has been cleared.',
    setClearMixedOutletLowError:
      'There was an error clearing the mixed outlet low value.',
    updateSchedule: 'The schedule has been updated.',
    updateScheduleError: 'There was an error updating the schedule.',
    leaveMessage: 'Location left.',
    leaveMessageError: 'There was an error trying to leave this location.',
    deviceCreated: 'Device created.',
    deviceUpdated: 'Device updated.',
    deviceDeleted: 'Device deleted.',
    deviceDeleteError: 'Error removing device.',
    locationCreated: 'Location created.',
    locationUpdated: 'Location updated.',
    locationDeleted: 'Location deleted.',
    locationDeleteError: 'Error removing location.',
    cannotDeleteLocationWithDevices:
      'Cannot delete location as it has active devices. User must delete devices or move devices to another location.',
  },
};

const i18nProvider = polyglotI18nProvider((locale) => englishMessages);

const RedirectTarget = () => {
    const { authenticated } = useAuthState();

    if (authenticated && window.location.href.includes('/state=')) {

        return <Redirect to={routes.dashboard}></Redirect>;
    }
    return <></>
}

const App = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
  <BrowserRouter>
    <Admin disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={WattsTheme}
      i18nProvider={i18nProvider}
      loginPage={WattsLoginPage}
      layout={WattsLayout}
      customRoutes={[
        <Route
          exact
          path='/dashboard'
          component={(props) => <Dashboard {...props} />}
          noLayout // Skip the layout
          />,
      ]}
    >
      <Resource name='dashboard'  />
      <Resource
        name='locations'
        options={{ cardComponent: LocationCard, sortFunction: prioritySort }}
        list={CardList}
        create={CreateLocation}
        edit={EditLocation}
        show={ViewLocation}
      />

      <Resource
        name='devices'
        create={CreateDevice}
        edit={EditDevice}
        show={DeviceView}
      />
      <Resource name='user' edit={EditSettings} />
      <Resource name='account' edit={EditAccount} />
      <Route
            path='/'
            component={(props) => <RedirectTarget />}
            noLayout // Skip the layout
      />
            </Admin>
        </BrowserRouter>
  </MuiPickersUtilsProvider>
);

export default App;
