import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching
} from 'react-admin';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { faWifi } from '@fortawesome/pro-solid-svg-icons';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import ContextMenuOption from '../ContextMenuOption';

import { Link } from 'react-router-dom';

import '../css/DeviceModal.css';

class CreateDeviceModal extends Component {
    _isMounted = false;
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        if (this._isMounted) {
            this.setState({ showDialog: true });
        }
    };

    handleCloseClick = () => {
        if (this._isMounted) {
            this.setState({ showDialog: false });
        }
    };

    handleSaveClick = () => {
    };

    setCode = () => {
        if (this._isMounted) {
            this.setState({
                code: document.getElementById('code').value
            });
        }
    };

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { showDialog } = this.state;

        return (
            <Fragment>
                <div onClick={this.handleClick}>
                    <ContextMenuOption
                        icon={faWifi}
                        optionText="Add a Device"
                    />
                </div>

                <Dialog className="Modal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Create post"
                >
                    <DialogTitle className="ModalTitle">Add a Device</DialogTitle>
                    <span className="ModalText">Enter the 8-digit registration code shown on the device.</span>

                    <DialogContent>
                        <TextField inputProps={{
                                maxLength: 8,
                            }}
                            label="Registration Code" id="code" onChange={this.setCode} fullWidth />
                    </DialogContent>

                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleCloseClick}>Cancel</Button>
                        <Button component={Link} to={`/devices/create?code=${this.state.code}&locationId=${this.props.locationid}`} className="ModalButton" label="ra.action.next" onClick={this.handleSaveClick}>Next</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    CreateDeviceModal
);