import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching,

} from 'react-admin';
import { Redirect } from "react-router";

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import dataProvider from '../dataProvider';

import './css/LocationModal.css';

class DeleteModal extends React.Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleRemoveClick = () => {
        const { submit } = this.props;


        if (this.props.deviceCounter && this.props.deviceCounter > 0) {
            this.props.showNotification(`elogic.toastNotifications.cannotDeleteLocationWithDevices`);
            this.handleCloseClick();
        }
        else {
            dataProvider.delete(this.props.type + 's', { id: this.props.thisRecord[this.props.type + 'Id'] })
                .then(({ data }) => {
                    this.props.showNotification(`elogic.toastNotifications.${this.props.type}Deleted`);
                    const url = '/#/' + this.props.type + 's'
                    window.location.replace(window.location.origin + url)
                })
                .catch(error => {
                    this.props.showNotification(`elogic.toastNotifications.${this.props.type}DeleteError`, 'error');
                    const url = '/#/' + this.props.type + 's'
                    window.location.replace(window.location.origin + url)
                });
        }
    };

    render() {
        const { showDialog } = this.state;

        const capitalType = this.props.type[0].toUpperCase() + this.props.type.slice(1);

        return (
            <Fragment>
                <FontAwesomeIcon icon={faTrash} onClick={this.handleClick} label="ra.action.remove" />

                <Dialog className="Modal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Are you sure?"
                >
                    <DialogTitle className="ModalTitle">Are you sure?</DialogTitle>
                    <span className="ModalText">This will permanently delete the {this.props.type} for you and all shared users.  This action cannot be undone.</span>

                    <DialogActions>
                        <Button className="ModalButton" label="ra.action.cancel" onClick={this.handleCloseClick}>Cancel</Button>
                        <Button className="ModalButton" label="ra.action.remove" onClick={this.handleRemoveClick}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    DeleteModal
);