import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import CreateDeviceModal from '../Devices/CreateDeviceModal';
import InviteUserModal from '../Locations/InviteUserModal.js';
import { ADD_BUTTON_STYLES } from '../../Helpers.js';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const FloatingActionButton = withStyles(ADD_BUTTON_STYLES)(Button);

export default function AddDeviceOrUserButton(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    
    const options = []
    if (props.permissions && props.permissions.Devices && props.permissions.Devices.Create) {
        options.push(<CreateDeviceModal {...props} />);
    }

    if (props.permissions && props.permissions.Locations && props.permissions.Locations.Share) {
        options.push(<InviteUserModal  {...props} />);

    }

    const handleClickListItem = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <FloatingActionButton onClick={handleClickListItem} label="ra.action.create" style={{ transform: 'translateY(36%)' }}>
                <FontAwesomeIcon icon={faPlus} />
            </FloatingActionButton>
            <Menu
                id="add-device-user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={`AddDeviceOrUserButton-${index}`}
                        onClick={event => handleMenuItemClick(event, index)}
                        locationid={props.locationId}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}