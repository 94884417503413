import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, SimpleForm, useAuthState } from 'react-admin';
import ScrollToTopOnMount from '../ScrollToTop'
import WattsToolbar from '../WattsToolbar';
import SettingsFormLayout from './SettingsFormLayout';
import WattsLoading from '../WattsLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

// CSS
import '../css/LandingPage.css';

const editStyles = makeStyles({
    'main': {
        'margin': '0'
    }
});

const EditSettings = ({ hasCreate, hasEdit, hasList, hasShow, ...props }) => {

    const editUseStyles = editStyles();

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return (
        <div>
            <ScrollToTopOnMount />
            <Edit classes={{ main: editUseStyles.main }} actions={null} undoable={false} successMessage={<><FontAwesomeIcon icon={faCheckCircle} /><p style={{display:`inline`, marginLeft:`15px`}}>Settings saved.</p></>} {...props}>
                <SimpleForm toolbar={<WattsToolbar savebuttonlabel="Save" />}>
                    <SettingsFormLayout title="Settings" />
                </SimpleForm>
            </Edit>
        </div>
    );
};

export default EditSettings;