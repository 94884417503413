import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    crudGetMatching,
} from 'react-admin';

import { faPencil } from '@fortawesome/pro-solid-svg-icons';

import ContextMenuOption from '../ContextMenuOption';
import TemperatureDialog from '../TemperatureDialog';

import '../css/DeviceModal.css';
import '../css/TelemetryCard.css';


class TelemetryCardHelpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            showDialog: false,
            testTemp: this.props.superProps.initialSetting,
            open : false,
        };
    }

    onTempDiagOpen = () => {
        this.setState({open: true});
    }

    onTempDiagClose = () => {
        this.setState({open: false});
    }

    onTempDiagSave = (newVal, tempErrorCallback) => {
        this.setState({open: false});
        this.setState({testTemp: newVal});
        this.props.superProps.addTodoItem(newVal, tempErrorCallback);
    }

    render() {
        return (
            <Fragment>
                <div onClick={this.onTempDiagOpen}>
                    <ContextMenuOption icon={faPencil} optionText="Edit Settings" />
                </div>

                <TemperatureDialog 
                    startingValue={this.state.testTemp} 
                    device={this.props.superProps.thisDevice}
                    type={this.props.superProps.uniqueKey}
                    open={this.state.open} 
                    callback={this.onTempDiagClose} 
                    saveCallback={this.onTempDiagSave}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    TelemetryCardHelpModal
);