import React from 'react';
import moment from "moment";

import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextField } from "react-admin";

import { getDeviceImageOrDefault, getUserTempScale, getDurationString, getRecordOutletTemp, getMixedOutletTemp, BLANK_TEMP_SCALE_VALUE, getUserSettingsTempScale } from '../../Helpers.js';
import statuses from './DeviceStatusLookup';

const MAINTAINING_SETPOINT = 9;

const styles = {
    card: {
        width: 330,
        margin: '12px',
        display: 'inline-block',
        verticalAlign: 'top',
        position: 'relative'
    },

    deviceType: {
        color: '#C1C1C1',
        fontSize: '10px',
        margin: 0,
    },

    deviceInfo: {
        padding: '10px 0'
    },

    deviceName: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 'bold'
    },

    temperature: {
        color: '#212121',
        margin: 0,
        fontSize: '32px'
    },

    tempUnit: {
        fontSize: '14px',
        verticalAlign: 'top'
    },

    deviceFooter: {
        padding: '10px 16px',
        height: '20px',
        color: '#5d5d5d'
    },

    redStatus: {
        backgroundColor: '#FFCCCC',
    },
    orangeStatus: {
        backgroundColor: '#FFE7D0',
    },
    greenStatus: {
        backgroundColor: '#CCF3CD',
    },

    statusIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        height: '20px',
        float: 'left',
        fontSize: '20px'
    },

    statusMessage: {
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'block',
        float: 'left',
        fontWeight: 'bold',
        lineHeight: '1.9',
        letterSpacing: '-0.12px',
        margin: 0,
        marginLeft: '10px'
    },
    statusValue: {
        display: 'block',
        float: 'right',
        fontWeight: 'normal'
    },
    footerCardContent: {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '20px',
        verticalAlign: 'middle',
        "&:last-child": {
            paddingBottom: '10px'
        }
    },

    deviceImage: {
        width: '80px',
        position: 'absolute',
        top: '16px',
        right: '16px'
    }
};


const DeviceStatusBar = withStyles(styles)(({ classes, record = {} }) => {
    // Get status name
    var statusName = "";
    var colorClass = "";
    var statusIcon = "";
    var durationString = "";
    var sanitizeInterval;
    var RightContent = (
        <span />
    );

    const getTelemetryDataValue = (fieldName) => {

        if (record.data[fieldName]) {
            return record.data[fieldName].Val;
        }

        return null;
    };

    const handleSanitizeCountdown = (endTime) => {
        var now = moment(new Date()).utc();

        var duration = endTime.diff(now);
        if (duration && duration > 0) {
            return getDurationString(duration);
        }
        else {
            return null;
        }
    }

    if (record.status) {
        statusName = record.status.name;
        // get color class
        if (statuses[record.status.id].color === "red") {
            colorClass = classes.redStatus;
        }
        else if (statuses[record.status.id].color === "orange") {
            colorClass = classes.orangeStatus;
        }
        else if (statuses[record.status.id].color === "green") {
            colorClass = classes.greenStatus;
        }
        // get icon
        statusIcon = statuses[record.status.id].icon;

        if (record.status.id == 7 && record.sanitizeEndTime) {
            RightContent = (
                <div className={classes.statusValue} id="sanitzationCountdown">{record.sanitizeEndTime}</div>
            );
        }
    }

    return (
        <CardContent className={`${colorClass} ${classes.deviceFooter} ${classes.footerCardContent}`}>
            <FontAwesomeIcon icon={statusIcon} className={classes.statusIcon} />
            <div className={classes.statusMessage}>{statusName}</div>
            {RightContent}
        </CardContent>
    );
});

const TempField = withStyles(styles)(({ classes, record = {} }) => {

    const tempText = getMixedOutletTemp(record, '- - -');
    const displayValue = getUserSettingsTempScale(record);

    return (<p className={classes.temperature}>{tempText}<span className={classes.tempUnit}>&deg;{displayValue}</span></ p>);
});

TempField.defaultProps = { label: 'Name' };

export const DeviceCard = withStyles(styles)(({ classes, dataRecord = {}, location }) => {

    return (
        <Link to={`${location}/${dataRecord.deviceId}/show`}>
            <Card key={dataRecord.id} className={classes.card}>
                <img className={classes.deviceImage} src={getDeviceImageOrDefault(dataRecord)} />
                <CardHeader disableTypography={true}
                    title={<div><p className={classes.deviceType}>{dataRecord.deviceType}</p></div>}
                />
                <CardContent>
                    <div className={classes.deviceInfo}>
                        <TempField record={dataRecord} />
                        <TextField record={dataRecord} className={classes.deviceName} source="name" />
                    </div>
                </CardContent>
                <DeviceStatusBar record={dataRecord} />
            </Card>
        </Link>
    )
});