import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Popover from '@material-ui/core/Popover';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCog, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';

import { withRouter } from "react-router";

import { showNotification, hideNotification } from 'react-admin';

import { connect } from 'react-redux';

import statuses from './Devices/DeviceStatusLookup';
import { getHelperStyles } from '../Helpers.js'

const SomeDevicesHaveErrors = (props) => {

    return (
        <div>
            <FontAwesomeIcon icon={faBellSolid}
                style={
                    {
                        'marginRight': '12px', 'fontSize': '20px'
                    }} />
            Some devices have errors.
            <span style={
                {
                    'color': '#5daef1',
                    'cursor': 'pointer',
                    'fontSize': '14px',
                    'fontWeight': 'bold',
                    'marginLeft': '20px'
                }}
                onClick={props.onClick}> Show Notifications </span>

            <FontAwesomeIcon icon={faTimes} onClick={props.close} style={{ 'cursor': 'pointer', 'marginLeft': '20px', 'fontSize': '20px', }} />
        </div>
    );
}

const noNotificationStyles = {
    container: {
        fontWeight: 'normal',
        textAlign: 'center',
        padding: '70px'
    },

    icon: {
        fontSize: '80px',
        color: '#E0E0E0'
    },

    message: {
        fontSize: '16px',
        color: '#6D6D6D'
    }
}
const NoNotificationMessage = withStyles(noNotificationStyles)(({ classes }) => {
    return (
        <div className={classes.container}>
            <FontAwesomeIcon icon={faBellSolid} className={classes.icon} />
            <p className={classes.message}>No New Notifications</p>
        </div>
    )
});

const styles = {
    notificationContainer: {
        position: 'relative',
        right: '55px',
        cursor: 'pointer'
    },

    notificationIcon: {
        fontSize: '20px',
        color: 'white'
    },

    notificationCount: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#5daef1',
        margin: 0,
        display: 'inline',
        position: 'absolute',
        top: '-5px'
    },

    popoverPaper: {
        width: '330px'
    },

    trayHeader: {
        backgroundColor: '#005db9',
        padding: '16px',
        position: 'relative',
        color: 'white',
        fontSize: '20px'
    },

    headerText: {
        margin: 0,
        padding: 0,
        display: 'inline',
        fontWeight: '900'
    },

    optionsIcon: {
        float: 'right',
        cursor: 'pointer',
        color: 'white'
    },

    notificationControls: {
        padding: '16px',
        textAlign: 'center',

        '& button': {
            width: '150px',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: '900',
            color: '#666',
            height: '36px'
        }
    },

    sortButtonSelected: {
        borderColor: '#036ecb !important',
        backgroundColor: '#E0EDF8 !important',
        color: '#005db9 !important'
    },

    notificationList: {
        maxHeight: '350px',
        overflow: 'auto',

        '& a': {
            color: 'inherit',
            textDecoration: 'inherit'
        }
    },

    alert: {
        borderTop: '1px solid #E0E0E0',
        padding: '16px',
        paddingTop: '10px',
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: '#e2f1ff'
        }
    },

    alertIcon: {
        backgroundColor: '#fcc',
        borderRadius: '100%',
        width: '20px !important',
        height: '20px',
        padding: '10px',
        fontSize: '20px',
        color: '#666',
        display: 'inline-block',
        marginRight: '16px',
        verticalAlign: '26px'
    },

    alertTime: {
        fontSize: '10px',
        fontWeight: '300',
        margin: 0,
        marginBottom: '4px',
        textAlign: 'right'
    },

    alertInfoContainer: {
        display: 'inline-block'
    },

    deviceName: {
        fontSize: '16px',
        fontWeight: '900',
        margin: '4px 0'
    },

    locationName: {
        fontSize: '14px',
        fontWeight: '400',
        margin: '4px 0'
    },

    alertMessage: {
        fontSize: '12px',
        margin: '4px 0',
        color: '#666'
    },

    alertIconWarning: {
        backgroundColor: '#FFE7D0'
    },

    alertIconError: {
        backgroundColor: '#fcc'
    }
}

const NotificationHeader = ({ classes }) => {
    const useStyles = getHelperStyles()();
    const className = useStyles.heading6 + ' ' + classes.headerText;

    return (
        <h2 className={className}>Notifications</h2>
    );
}

class NotificationTray extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
        this.state = {
            trayOpen: false,
            anchorEl: null,
            notificationCount: 0,
            sortby: 'priority',
            notificationList: [],
            vertical: 'top',
            horizontal: 'right',
            handleSnackbar: true
        };
    }
    // for alert popover
    handleClick(event) {
        this.setState({
            trayOpen: true,
            anchorEl: event.currentTarget,
            vertical: 'bottom'
        });
    }

    handleClose() {
        this.setState({
            trayOpen: false,
        });
    }

    // for toast notifiction
    closeNotification = () => {
        this.props.hideNotification();
    };

    handleOpen(event) {
        this.setState({
            trayOpen: true,
            anchorEl: document.getElementById("NotificationTray"),
            vertical: 'bottom'
        });
    }



    sortNotificationList(list, sortType) {
        switch (sortType) {
            case "time":
                list.sort(function (a, b) {
                    var aDate = moment(a.dateCreated).valueOf();
                    var bDate = moment(b.dateCreated).valueOf();

                    if (aDate == bDate) return 0;
                    return aDate < bDate ? 1 : -1;
                });
                break;
            case "priority":
                list.sort(function (a, b) {
                    if (a.deviceStatus == b.deviceStatus) return 0;
                    return a.deviceStatus > b.deviceStatus ? 1 : -1;
                });
                break;
        }
        return list;
    }

    handleSorting(event, value) {
        if (value != null && value != undefined) {

            var tempAlertList = this.sortNotificationList(this.state.notificationList, value);

            this.setState({
                sortby: value,
                notificationList: tempAlertList
            });
        }
    }

    componentDidMount() {
        this.props.hideNotification();
        this._isMounted = true;

        const token = localStorage.getItem('authorizationToken');

        var jsonHeaders = {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }

        };

        var comp = this;

        axios.get(`/api/Notifications`, jsonHeaders)
            .then(function (response) {

                if (comp._isMounted) {

                    let previousSnackbar = comp.state.handleSnackbar;

                    comp.setState({
                        notificationList: comp.sortNotificationList(response.data, comp.state.sortby),
                        notificationCount: response.data.length,
                        handleSnackbar: false
                    });

                    // Toast Notifications - based on route path location
                    if (comp.props.location.pathname === "/locations") {
                        if (response.data.length > 0 && previousSnackbar) {

                            comp.props.showNotification(
                                <SomeDevicesHaveErrors
                                    thisState={comp.state}
                                    onClick={comp.handleOpen.bind(comp)}
                                    close={comp.closeNotification.bind(comp)}
                                />,
                                'info',
                                { autoHideDuration: 10000 }
                            );
                        }
                    }
                    else if (/^\/locations\/.+\/show.*$/.test(comp.props.location.pathname)) { // match location show page
                        var thisLocationHasAnError = false;

                        // look and see if this route location ID has a device with an alert - match GUID
                        var locationId = comp.props.location.pathname.match(/(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g)[0];
                        response.data.forEach(element => {
                            if (element.parentLocationId === locationId || element.locationId === locationId) {
                                thisLocationHasAnError = true;
                            }
                        });

                        if (thisLocationHasAnError && previousSnackbar) {

                            comp.props.showNotification(
                                <SomeDevicesHaveErrors
                                    thisState={comp.state}
                                    onClick={comp.handleOpen.bind(comp)}
                                    close={comp.closeNotification.bind(comp)}
                                />,
                                'info',
                                { autoHideDuration: 10000 }
                            );
                        }
                    }
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.hideNotification();
    }

    getTimestamp(otherTime) {

        var now = moment(new Date());
        var oTime = moment.utc(otherTime);
        var duration = moment.duration(now.diff(oTime));

        if (duration.asDays() > 1) {
            return duration.days() + 'd';
        }
        else if (duration.asHours() > 1) {
            return duration.hours() + 'h';
        }
        else if (duration.asMinutes() > 1) {
            return duration.minutes() + 'm';
        }
        else {
            return 'Now';
        }
    }

    render() {


        const { classes, ...other } = this.props;

        var userId = localStorage.getItem('userId');
        var recordIds = Object.keys(this.state.notificationList);
        var notifications = recordIds.map(id => {
            var record = this.state.notificationList[id];

            return (
                <Link to={`/devices/${record.deviceId}/show/2`} key={id} onClick={this.handleClose.bind(this)}>
                    <div className={classes.alert}>
                        <p className={classes.alertTime}>{this.getTimestamp(record.dateCreated)}</p>
                        <FontAwesomeIcon className={`${classes.alertIcon} ${statuses[record.deviceStatus].color === "orange" ? this.props.classes.alertIconWarning : this.props.classes.alertIconError}`} icon={statuses[record.deviceStatus].icon} />
                        <div className={classes.alertInfoContainer}>
                            <h2 className={classes.deviceName}>{record.deviceName}</h2>
                            <h3 className={classes.locationName}>{record.locationName}</h3>
                            <p className={classes.alertMessage}>{record.errMsg}</p>
                        </div>
                    </div>
                </Link>
            );
        });

        return (
            <div>
                <div id={"NotificationTray"} className={classes.notificationContainer} onClick={this.handleClick.bind(this)}>
                    <FontAwesomeIcon icon={faBell} className={classes.notificationIcon} />
                    <p className={classes.notificationCount}>{this.state.notificationCount}</p>
                </div>
                <Popover
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: this.state.vertical,
                        horizontal: 'right'
                    }}
                    open={this.state.trayOpen}
                    onClose={this.handleClose.bind(this)}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    classes={{ paper: classes.popoverPaper }}
                >
                    <div className={classes.trayHeader}>
                        <NotificationHeader classes={classes} />
                        <Link to={`/user/${userId}`}><FontAwesomeIcon icon={faCog} className={classes.optionsIcon} /></Link>
                    </div>
                    {this.state.notificationCount > 0 &&
                        <>
                            <div className={classes.notificationControls}>
                                <ToggleButtonGroup
                                    value={this.state.sortby}
                                    exclusive
                                    onChange={this.handleSorting.bind(this)}
                                >
                                    <ToggleButton value="priority" aria-label="Priority" classes={{ selected: classes.sortButtonSelected }}>
                                        Priority
                                    </ToggleButton>
                                    <ToggleButton value="time" aria-label="Time" classes={{ selected: classes.sortButtonSelected }}>
                                        Time
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div className={classes.notificationList}>
                                {notifications}
                            </div>
                        </>
                    }
                    {this.state.notificationCount == 0 &&
                        <NoNotificationMessage />
                    }
                </Popover>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    showNotification,
    hideNotification
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationTray)));