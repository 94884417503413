import React, { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Slider from '@material-ui/core/Slider';
import Dialog from '@material-ui/core/Dialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import { getUserTempScale } from '../Helpers.js';

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

class ConfirmationModal extends React.Component {
    state = {
        error: false,
        showDialog: false,
        showError: false,
        errorText: "An error has occured"
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleConfirmClick = () => {
        var x = this.props;
        this.handleCloseClick();
        this.props.close();
    };

    render() {
        const { showDialog } = this.state;

        return (
            <Fragment>
                <Button autoFocus className={this.props.className} onClick={this.handleClick} color="primary">
                    Save
                </Button>
                <Dialog className="Modal"
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Temperature Confirmation"
                >
                    <DialogTitle className="ModalTitle">Are you sure?</DialogTitle>
                    <span className="ModalText">Changing this setting will update the device.</span>

                    <DialogActions>
                        <Button className="ModalButton" onClick={this.handleCloseClick}>Cancel</Button>
                        <Button className="ModalButton" onClick={this.handleConfirmClick}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const dialogStyles = makeStyles({
    dialogBody: {
        width: '328px',
        height: '464px'
    },

    dialogHeader: {
        'font-family': 'Roboto',
        'font-size': '20px',
        'font-weight': '900',
        'font-stretch': 'normal',
        'font-style': 'normal',
        'line-height': '1.2',
        'letter-spacing': '-0.2px'
    },

    dialogContent: {
        width: '244px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },

    tempReadout: {
        fontSize: '64px',
        letterSpacing: '-0.64px',
        margin: 0,
        marginBottom: '20px'
    },

    tempUnit: {
        fontSize: '32px',
        verticalAlign: 'super'
    },

    tempControlButton: {
        'background': '#00254a',
        'border-radius': '100%',
        'color': '#FFF',
        'height': '40px',
        'width': '40px',
        'minHeight': '40px',
        'minWidth': '40px',
        'margin': '0 12px',

        '&:hover, &:active, &:focus': {
            'background': '#005DB9'
        }
    },

    dialogAction: {
        fontSize: '14px',
        fontWeight: '900',
        textTransform: 'none',
        letterSpacing: '-0.14px'
    }
});

function TempDialog(props) {

    const { callback, saveCallback, startingValue, open, device, type } = props;

    const [value, setValue] = React.useState(startingValue);
    const [showError, setShowError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("An Error has occured");

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        callback(startingValue);
        setValue(startingValue);
    };

    const handleSave = () => {
        saveCallback(value, tempErrorCallback);
    }

    const handleErrorClose = () => {
        setErrorText("An Error has occured");
        setShowError(false);
    }

    const tempErrorCallback = (revertValue, callbackErrorText) => {
        if (callbackErrorText) {
            setErrorText(callbackErrorText);
            setShowError(true);
        }
        setValue(revertValue);
    }

    const classes = dialogStyles();

    const increaseTemp = () => {
        if (value < device.data[type].Max)
            setValue(value + device.data[type].Steps);
    }

    const decreaseTemp = () => {
        if (value > device.data[type].Min)
            setValue(value - device.data[type].Steps);
    }


    // TODO: Localize these titles...
    const GetTitle = () => {
        var title = "";
        if (type === "MixedSetpoint") {
            title = "Mixed Outlet Setpoint";
        }
        else if (type === "HighAlertDiff") {
            title = "High Temperature Alert Differential";
        }
        else if (type === "LowAlertDiff") {
            title = "Low Temperature Alert Differential";
        }
        return title;
    }

    if (device.data && device.data[type]) {
        const sliderMarks = [
            {
                value: device.data[type].Min,
                label: `${device.data[type].Min}`
            },
            {
                value: device.data[type].Max,
                label: `${device.data[type].Max}`
            }
        ];

        const displayValue = getUserTempScale(device);

        return (
            <>
                <Dialog classes={{ paper: classes.dialogBody }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                    <DialogTitle className={classes.dialogHeader} disableTypography={true} id="simple-dialog-title">{GetTitle()}</DialogTitle>
                    <DialogContent style={{ position: 'relative' }}>
                        <div className={classes.dialogContent}>
                            <p className={classes.tempReadout}>{value} <span className={classes.tempUnit}>°{displayValue}</span></p>
                            <Slider
                                defaultValue={startingValue}
                                value={value}
                                onChange={handleSliderChange}
                                min={device.data[type].Min}
                                max={device.data[type].Max}
                                marks={sliderMarks}
                                step={device.data[type].Step}
                            />
                            <div>
                                <Button autoFocus className={classes.tempControlButton} onClick={increaseTemp} color="primary">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </Button>
                                <Button autoFocus className={classes.tempControlButton} onClick={decreaseTemp} color="primary">
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus className={classes.dialogAction} onClick={handleClose} color="primary">
                            Cancel
                    </Button>
                        <ConfirmationModal className={classes.dialogAction} close={handleSave} />
                    </DialogActions>
                </Dialog>
                <Dialog className="Modal"
                    fullWidth
                    open={showError}
                    onClose={handleErrorClose}
                    aria-label="Temperature Error"
                >
                    <DialogTitle className="ModalTitle">Remote Max Exceeded</DialogTitle>
                    <span className="ModalText">{errorText}</span>
                    <DialogActions>
                        <Button className="ModalButton" onClick={handleErrorClose}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
    else {
        return (
            <Dialog classes={{ paper: classes.dialogBody }} open={open} onClose={handleClose}>
                <DialogTitle className={classes.dialogHeader} disableTypography={true} id="simple-dialog-title">{GetTitle()}</DialogTitle>
                <DialogContent style={{ position: 'relative' }}>
                    <div>Unable to retrieve temperature data from the device. Please try again later.</div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus className={classes.dialogAction} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default function TemperatureDialog(props) {
    return (
        <div>
            <TempDialog startingValue={props.startingValue} open={props.open} callback={props.callback} saveCallback={props.saveCallback} device={props.device} type={props.type} />
        </div>
    );
}
