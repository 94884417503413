import React from 'react';
import { useForm } from 'react-final-form';

import { TextInput, SelectInput, BooleanInput, required, FormDataConsumer } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faGlobe } from '@fortawesome/pro-solid-svg-icons';

import FormHeader from '../FormHeader';
import RequiredFieldNote from '../RequiredFieldNote';

const layoutStyles = makeStyles({
    'icon': {
        'color': '#005DB9',
        'font-size': '32px'
    },
    'gridBottom': {
        'marginBottom': '48px',
        'paddingTop': '40px'
    },
    'subText': {
        'color': '#333',
        'fontSize': '14px',
        'lineHeight': 1.43,
        'letterSpacing': '-0.14px'
    }
});

const languageOptions = [
    {
        id: 'en',
        name: 'English'
    }
];

const tempScaleOptions = [
    {
        id: 'F',
        name: 'Fahrenheit'
    },
    {
        id: 'C',
        name: 'Celsius'
    }
];

const measurementScaleOptions = [
    {
        id: 'I',
        name: 'Imperial'
    },
    {
        id: 'M',
        name: 'Metric'
    }
];

const SettingsForm = (props) => {

    const useStyles = layoutStyles();
    const form = useForm();

    return (
        <div>
            <FormHeader title={props.title} />
            <Grid container alignContent="center" justify="center" className="formGrid">
                <Grid item md={2}>
                </Grid>
                <Grid item xs={7}>
                    <Grid container>
                        <Grid item md={5} xs={12}>
                            <FontAwesomeIcon icon={faBell} className={useStyles.icon} />
                            <h2>Notifications</h2>
                            <p className={useStyles.subText}> Choose how you’d like to receive notifications about your locations and devices.</p>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <RequiredFieldNote />

                            <BoolInputLeftLabel label="Push" source="pushNotificationEnabled" />
                            <BoolInputLeftLabel label="Email" source="emailNotificationEnabled" />
                            <FormDataConsumer>
                                {({ formData }) => {

                                    // Recursively parses digits from a string.
                                    function recursiveDigits(string) {

                                        if (string) {
                                            let matches = string.match(/(\d+)/);

                                            if (matches && matches.length > 0) {
                                                let index = matches['index'];
                                                let restOfString = string.substring(index + matches[0].length);

                                                return matches[0] + recursiveDigits(restOfString);
                                            }
                                        }

                                        // Otherwise, return empty string to end recursion.
                                        return '';
                                    }

                                    var validation = [];
                                    validation.push(
                                        (() => {
                                            // This validation is triggered by react-admin in 2 different orders depending
                                            // on what triggers the change.
                                            // If the phone number field is changed, the sms notification toggle represents
                                            // the correct current value.
                                            // If the sms notification toggle is changed first, this function gets called
                                            // before that change takes effect in the formData value, so the old value is
                                            // passed in here, not the new one. This is a problem. So we check what field 
                                            // is active.
                                            const smsChangedField = form.getFieldState("smsNotificationEnabled");
                                            var smsChanged = false;

                                            // Determine if the sms field is what changed.
                                            if (smsChangedField) {
                                                smsChanged = smsChangedField.active;
                                            }

                                            var smsValue = formData.smsNotificationEnabled;

                                            if (smsChanged) {
                                                // If the sms field changed, invert it, because it's a boolean and the next
                                                // state will be the opposite of what it currently is.
                                                smsValue = !smsValue;
                                            }

                                            return Object.assign(
                                                (value) => {
                                                    if (!value && smsValue) {
                                                        // If it's required but not filled in, return the required message.
                                                        return 'Required'
                                                    }
                                                    else {

                                                        let phoneDigits = recursiveDigits(value);

                                                        if (value && phoneDigits.length !== 10) {
                                                            return 'Please enter a valid phone number. (e.g., 555-123-4567)';
                                                        }
                                                        else {
                                                            // react-admin specifies to return undefined when the validation is
                                                            // successful.
                                                            return undefined;
                                                        }
                                                    }
                                                },
                                                // Need to do this assign for react-admin to show the '*' in front of
                                                // the field label.
                                                { isRequired: formData.smsNotificationEnabled }
                                            );
                                        })()
                                    );

                                    return (
                                        <>
                                            <BoolInputLeftLabel label="Text" source="smsNotificationEnabled" />
                                            <TextInput
                                                source="mobilePhoneNumber"
                                                label="Phone Number"
                                                validate={validation}
                                                fullWidth />
                                        </>
                                    );
                                }}
                            </FormDataConsumer>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="formGridSeparated"></div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <FontAwesomeIcon icon={faGlobe} className={useStyles.icon} />
                            <h2>Preferences</h2>
                            <p className={useStyles.subText}>Select your preferred language and units of measurement.</p>
                        </Grid>
                        <Grid item md={7} xs={12} className={useStyles.gridBottom}>
                            <SelectInput
                                source="languagePreference"
                                label="Language"
                                fullWidth
                                choices={languageOptions}
                                defaultValue="en"
                                validate={[required()]} />
                            <SelectInput
                                source="temperatureScale"
                                label="Temperature Scale"
                                fullWidth
                                choices={tempScaleOptions}
                                defaultValue="F"
                                validate={[required()]} />
                            <SelectInput
                                source="measurementScale"
                                label="Measurement Scale"
                                fullWidth
                                choices={measurementScaleOptions}
                                defaultValue="I"
                                validate={[required()]} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2}>
                </Grid>
            </Grid>
        </div>
    );

};

const BoolInputLeftLabel = (props) => {

    const boolStyles = makeStyles({
        'boolGrid': {
            'marginBottom': '24px'
        },
        'boolLabel': {
            'padding': '16px 0'
        },
        'boolButton': {
            'display': 'inline-block',
            'float': 'right'
        }
    });

    const useStyles = boolStyles();

    return (
        <Grid container>
            <Grid item xs={6} className={useStyles.boolLabel}>
                <label>{props.label}</label>
            </Grid>
            <Grid item xs={6}>
                <BooleanInput label="" className={useStyles.boolButton} source={props.source} />
            </Grid>
        </Grid>
    );
};

export default SettingsForm;