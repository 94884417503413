import React from 'react';
import { getHelperStyles } from '../Helpers';

const FormHeader = (props) => {

    const classTemplate = getHelperStyles()();

    return (
        <div className={classTemplate.heading3}>
            <h1 className="formHeader">{props.title}</h1>
        </div>
    );
}

export default FormHeader;