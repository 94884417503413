import React from "react";
import { Link } from "react-router-dom";

import {
    SHOW_BOX_INSTALLISTATION,
} from "../../Helpers";
import { ApplicationTitle } from "./ApplicationTitle";
import { routes } from './routes'
import { intelliStationJsImageDevice, intelliStationJsImageLogo } from "./constants";

export const BoxIntelliStation = ({ classImage }) => {

    return SHOW_BOX_INSTALLISTATION == 'true'? (
        <Link to={routes.locations}>
            <ApplicationTitle
                logo={intelliStationJsImageLogo}
                applicationImage={intelliStationJsImageDevice}
                devices={["Intellistation Jr."]}
                classImage={classImage}
                isFirstTile
            />
        </Link>
    ) : (
        <ApplicationTitle
                logo={intelliStationJsImageLogo}
                applicationImage={intelliStationJsImageDevice}
                devices={["Intellistation Jr."]}
                classImage={classImage}
                isFirstTile
        />
    );
};
