const WattsBluePrimary = {
    50: '#E2F1FC',
    100: '#B8DBF9',
    200: '#8BC5F5',
    300: '#5DAEF1',
    400: '#389DEF',
    500: '#068DEC',
    600: '#0680DE',
    700: '#005DB9',
    800: '#005DB9',
    900: '#003F9A',
    main: '#005DB9'
}

export default WattsBluePrimary;