import { useEffect } from 'react';

// For more information on why this is necessary, see:
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
const ScrollToTopOnMount = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
};

export default ScrollToTopOnMount;