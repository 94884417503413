import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const DeviceLastUpdated = (props) => {

    const useStyles = makeStyles({
        lastUpdated: {
            fontSize: '12px',
            lineHeight: '1.33',
            letterSpacing: '-0.12px',
            color: '#212121',
            margin: '19px 0 13px 0',
            float: 'right'
        }
    });

    const classes = useStyles();

    var formattedTime = new Date().toLocaleString().replace(',', '');

    return (
        <p className={classes.lastUpdated}><b>Updated:</b> {formattedTime}</p>
    );
}

export default DeviceLastUpdated;