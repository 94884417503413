import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, useAuthState } from 'react-admin';
import ScrollToTopOnMount from '../ScrollToTop'
import WattsToolbar from '../WattsToolbar';
import LocationFormLayout from './LocationFormLayout';
import WattsLoading from '../WattsLoading';

// CSS
import '../css/LandingPage.css';

const createStyles = makeStyles({
    'main': {
        'margin': '0'
    }
});

const CreateLocation = (props) => {

    const createUseStyles = createStyles();

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    return (
        <div>
            <ScrollToTopOnMount />
            <Create successMessage="elogic.toastNotifications.locationCreated" classes={{ main: createUseStyles.main }} actions={null} {...props}>
                <SimpleForm toolbar={<WattsToolbar savebuttontext="Add Location" />}>
                    <LocationFormLayout title="Add a Location" />
                </SimpleForm>
            </Create>
        </div>
    );
};


export default CreateLocation;