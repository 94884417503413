import React from 'react';
import moment from "moment";
import axios from 'axios';

import { showNotification } from 'react-admin';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { TimePicker } from '@material-ui/pickers'

import { makeStyles, withStyles } from '@material-ui/core/styles';

import TelemetryCard from './TelemetryCard';
import TelemetryCardMenu from './TelemetryCardMenu';
import ContextMenuOption from '../ContextMenuOption';

import DeviceLastUpdated from './DeviceLastUpdated';

import { faQuestionCircle, faPencil } from '@fortawesome/pro-solid-svg-icons';

import { getPermissionList, getUserTempScale, isDeviceOffline } from '../../Helpers.js'

const DEVICE_SCHEDULE_EACH_DAY = "EachDay";
const DEVICE_SCHEDULE_ALL_DAYS = "AllDays";
const ALL_DAYS = "All";
const DEVICE_SCHEDULE_DAYS = {
    'All': 'All Days',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thr': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'Sun': 'Sunday'
};

const SCHEDULE_DEVICE_DAYS = {
    'All Days': 'All',
    'Monday': 'Mon',
    'Tuesday': 'Tue',
    'Wednesday': 'Wed',
    'Thursday': 'Thr',
    'Friday': 'Fri',
    'Saturday': 'Sat',
    'Sunday': 'Sun'
};

const scheduleStyles = makeStyles({
    switchLabel: {
        color: '#005db9'
    },

    scheduleRow: {
        fontSize: '14px',
        fontWeight: '900'
    },

    disabledRow: {
        color: '#9E9E9E'
    },

    evenRows: {
        backgroundColor: '#F5F5F5'
    },

    checkboxCell: {
        textAlign: 'center'
    },

    timePickerText: {
        '& input': {
            color: '#005db9',
            fontSize: '12px',
            fontWeight: 'normal',
            cursor: 'pointer'
        }
    },
    timePickerTextDisabled: {
        '& input': {
            color: '#005db9',
            fontSize: '12px',
            fontWeight: 'normal'
        }
    }
});

const ScheduleTableBody = (props) => {

    var thisTab = props.thisTab;
    var permissions = getPermissionList(thisTab.props.record.location.permissions)
    const classes = scheduleStyles();
    var scheduleDays = thisTab.state.schedulingData.scheduleDays

    var schedulingEnabled = thisTab.state.schedulingData.schedulingEnabled;
    var allDaysChecked = schedulingEnabled && thisTab.state.schedulingData.type == DEVICE_SCHEDULE_ALL_DAYS;

    var getNearestTenInterval = () => {
        const now = new Date();

        var nearestValue = (Math.ceil((now.getMinutes() + 1) / 10) * 10);
        var minuteDiff = Math.abs(nearestValue - now.getMinutes());
        return new Date(now.getTime() + minuteDiff * 60000);
    }

    return (
        <tbody>
            <tr>
                <th width={`60px`}>
                    <Switch
                        checked={thisTab.state.schedulingData.schedulingEnabled}
                        onChange={(event) => {
                            var checked = event.target.checked;
                            thisTab.setState(prevState => {
                                let schedulingData = { ...prevState.schedulingData };
                                schedulingData.schedulingEnabled = checked;
                                return { schedulingData };
                            }, function () {
                                thisTab.updateScheduleData();
                            });
                        }}
                        color="primary"
                        disabled={!permissions.Devices.Update}
                    />
                </th>
                <th className={classes.switchLabel}>{(thisTab.state.schedulingData.schedulingEnabled ? 'ON' : 'OFF')}</th>
                <th width={`100px`}>Normal Start</th>
                <th width={`100px`}>Savings Start</th>
            </tr>

            {scheduleDays.map((scheduleDay, i) =>
                <tr key={i} className={`${classes.scheduleRow} ${(i % 2 == 0 ? classes.evenRows : '')} ${(!allDaysChecked || i == 0) && schedulingEnabled && scheduleDay.enabled ? '' : classes.disabledRow}`}>
                    <td className={classes.checkboxCell}>
                        {(!allDaysChecked || i == 0) && schedulingEnabled &&
                            <Checkbox
                                checked={scheduleDay.enabled}
                                onChange={(event) => {
                                    var checked = event.target.checked;
                                    thisTab.setState(prevState => {
                                        let schedulingData = { ...prevState.schedulingData };
                                        schedulingData.scheduleDays[i].enabled = checked;

                                        if (i == 0) {
                                            schedulingData.type = checked ? DEVICE_SCHEDULE_ALL_DAYS : DEVICE_SCHEDULE_EACH_DAY;
                                        }

                                        return { schedulingData };
                                    }, function () {
                                        thisTab.updateScheduleData();
                                    });
                                }}
                                color="primary"
                                disabled={!permissions.Devices.Update}
                            />
                        }
                    </td>
                    <td>{scheduleDay.name}</td>
                    <td>
                        {(!allDaysChecked || i == 0) && schedulingEnabled && scheduleDay.enabled &&
                            <TimePicker
                                classes={{ content: classes.timePicker }}
                                value={scheduleDay.start ? moment(scheduleDay.start, "HH:mm") : null}
                                minutesStep={10}
                                onChange={(date) => {
                                    thisTab.setState(prevState => {
                                        let schedulingData = { ...prevState.schedulingData };
                                        if (date) {
                                            schedulingData.scheduleDays[i].start = date.format("HH:mm");
                                        }
                                        else {
                                            schedulingData.scheduleDays[i].start = null;
                                        }

                                        return { schedulingData };
                                    }, function () {
                                        thisTab.updateScheduleData();
                                    });
                                }}
                                clearable
                                clearLabel="Skip"
                                emptyLabel="Skip"
                                initialFocusedDate={getNearestTenInterval()}
                                disabled={!permissions.Devices.Update}
                                InputProps={{
                                    disableUnderline: true,
                                    className: permissions.Devices.Update ? classes.timePickerText : classes.timePickerTextDisabled
                                }} />
                        }
                    </td>
                    <td>
                        {(!allDaysChecked || i == 0) && schedulingEnabled && scheduleDay.enabled &&
                            <TimePicker
                                classes={{ content: classes.timePicker }}
                                value={scheduleDay.end ? moment(scheduleDay.end, "HH:mm") : null}
                                minutesStep={10}
                                onChange={(date) => {
                                    thisTab.setState(prevState => {
                                        let schedulingData = { ...prevState.schedulingData };
                                        if (date) {
                                            schedulingData.scheduleDays[i].end = date.format("HH:mm");
                                        }
                                        else {
                                            schedulingData.scheduleDays[i].end = null;
                                        }
                                        return { schedulingData };
                                    }, function () {
                                        thisTab.updateScheduleData();
                                    });
                                }}
                                clearable
                                clearLabel="Skip"
                                emptyLabel="Skip"
                                InputProps={{
                                    disableUnderline: true,
                                    className: permissions.Devices.Update ? classes.timePickerText : classes.timePickerTextDisabled
                                }}
                                disabled={!permissions.Devices.Update}
                            />
                        }
                    </td>
                </tr>
            )}
        </tbody>
    )
}

const bodyStyles = {
    paperContainer: {
        border: '1px solid #E0E0E0',
        minHeight: '184px',
        position: 'relative'
    },

    emptySchedule: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '20px 12px',
        textAlign: 'center'
    },

    tempHeader: {
        padding: '12px 16px'
    },

    tempHeaderText: {
        margin: '0',
        fontSize: '10px',
        display: 'inline-block',
        lineHeight: '20px',
        verticalAlign: 'middle',
    },

    tempOptionButton: {
        cursor: 'pointer',
        height: '20px',
        float: 'right',
        color: '#005db9'
    },

    tempDisplay: {
        fontSize: '64px',
        margin: '0',
        position: 'absolute',
        top: '50% ',
        left: '50% ',
        transform: 'translate(-50%, -50%)'
    },

    scheduleTable: {
        borderCollapse: 'collapse',
        width: '100%',

        '& th': {
            fontSize: '10px',
            fontWeight: 'bold',
            textAlign: 'left',
            borderBottom: '1px solid #E0E0E0'
        },

        '& tr': {
            padding: '0 16px',
            height: '52px'
        }
    }
}

const SettingsCard = (props) => {

    if (props.thisDevice) {
        var permissions = getPermissionList(props.thisDevice.location.permissions);

        var menuOptions = [
            <ContextMenuOption
                icon={faQuestionCircle}
                optionText={`Help`}
                optionType={`HelpModal`}
                thisDevice={props.thisDevice}
                uniqueKey={props.uniqueKey}
                isMenu={true}
                helpKey={props.helpKey}
                helpObject={props.helpObject}
                helpError={props.helpError}
            />
        ];

        if (permissions && permissions.Devices && permissions.Devices.Update) {
            menuOptions.unshift(

                // Edit settings option.
                <ContextMenuOption
                    icon={faPencil}
                    optionText={`Edit Setting`}
                    optionType={`EditModal`}
                    initialSetting={props.settingsValue}
                    thisDevice={props.thisDevice}
                    addTodoItem={props.SetValueFunction}
                    uniqueKey={props.uniqueKey}
                    isMenu={true}
                />
            );
        }
    }

    return (
        <TelemetryCard
            message={props.message}
            value={props.settingsValue}
            date={null}
            iconType={
                !isDeviceOffline(props.thisDevice) && 
                <TelemetryCardMenu
                    uniqueKey={props.parentKey}
                    menuOptions={menuOptions}
                />
            }
            key={`${props.parentKey}-${0}`}
        />
    );
}


class DeviceScheduleTab extends React.Component {
    constructor(props) {
        super(props);

        this.SetSetbackOffsetValue = this.SetSetbackOffsetValue.bind(this);

        var setbackOffset = null;

        var scheduleData = {
            schedulingEnabled: false
        };

        if (this.props.record && this.props.record.data) {
            if (this.props.record.data.SetbackOffset) {
                setbackOffset = this.props.record.data.SetbackOffset.Val;
            }

            if (this.props.record.data.Schedule) {

                var schedule = this.props.record.data.Schedule;

                scheduleData.schedulingEnabled = schedule.Active == 1;
                scheduleData.type = this.props.record.data.Schedule.Type ? this.props.record.data.Schedule.Type : DEVICE_SCHEDULE_ALL_DAYS;
                scheduleData.scheduleDays = [];

                var isAllDays = scheduleData.type == DEVICE_SCHEDULE_ALL_DAYS;

                // Convert the schedule from the API to the web format.
                for (var dayShort in DEVICE_SCHEDULE_DAYS) {
                    var dayFull = DEVICE_SCHEDULE_DAYS[dayShort];

                    if (schedule.hasOwnProperty(dayShort) || isAllDays) {
                        var dayData = {};

                        // Remove this if and go with the else case if we want to maintain schedule.
                        if (isAllDays) {
                            if (schedule.hasOwnProperty(ALL_DAYS)) {
                                dayData = schedule[ALL_DAYS];
                            }
                        }
                        else {
                            dayData = schedule[dayShort];
                        }

                        // Default to disabled state.
                        var dayScheduleData = {
                            name: dayFull,
                            enabled: false,
                            start: null,
                            end: null
                        };

                        // If it's active, or if it's "All Days". "All Days" does not return an active
                        // flag on each individual day.
                        if (isAllDays || dayData.Active == 1) {

                            if (isAllDays || dayShort != ALL_DAYS) {
                                dayScheduleData.enabled = true;
                            }

                            dayScheduleData.start = this.processScheduleEventTime(dayData.Normal);
                            dayScheduleData.end = this.processScheduleEventTime(dayData.Savings);
                        }

                        scheduleData.scheduleDays.push(dayScheduleData);
                    }
                }
            }
        }
        else {
            scheduleData.schedulingEnabled = false;
            scheduleData.type = DEVICE_SCHEDULE_ALL_DAYS;
            scheduleData.scheduleDays = [];
        }

        this.state = {
            schedulingData: scheduleData,
            SetbackOffsetValue: setbackOffset,
        }
    }

    processScheduleEventTime(scheduleObject) {
        if (!scheduleObject || scheduleObject.Active == 0) {

            // We handle "off" days with null values.
            return null;
        }
        else {
            return scheduleObject.Val;
        }
    }

    SetSetbackOffsetValue(value, tempErrorCallback) {
        var comp = this;
        // axios update device value - direct method
        const token = localStorage.getItem('authorizationToken');
        axios({
            method: 'patch',
            url: `/api/devices/${comp.props.record.id}/UpdateSetbackOffset`,
            data: {
                SetbackOffset: {
                    Val: value
                }
            },
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }).then(function (response) {
            // handle success
            comp.setState({ SetbackOffsetValue: value });
            comp.props.record.data.SetbackOffset.Val = value;

            comp.props.showNotification("elogic.toastNotifications.setSetbackOffset");
        }).catch(function (error) {
            // handle error
            tempErrorCallback(comp.state.SetbackOffsetValue);
            comp.props.showNotification("elogic.toastNotifications.setSetbackOffsetError", "error");
            console.log(error);
        });
    }

    componentDidMount() {
    }

    updateScheduleData() {
        // Need to convert the UI data structure back to the API expected value.
        var uiScheduleData = this.state.schedulingData;

        var scheduleObj = {
            Active: 0,
            Type: uiScheduleData.type
        };

        if (uiScheduleData.schedulingEnabled) {
            scheduleObj.Active = 1;

            var startIndex = 0;

            if (uiScheduleData.type == DEVICE_SCHEDULE_EACH_DAY) {
                // For each day schedule type, skip "All Days" object.
                startIndex = 1;
            }

            for (var i = startIndex; i < uiScheduleData.scheduleDays.length; i++) {
                var uiDay = uiScheduleData.scheduleDays[i];
                var currentDayShortName = SCHEDULE_DEVICE_DAYS[uiDay.name];

                var rootDayObj = {};

                var valueDay = uiDay;
                if (uiScheduleData.type == DEVICE_SCHEDULE_ALL_DAYS) {
                    valueDay = uiScheduleData.scheduleDays[0];
                    rootDayObj.Active = 1;
                }
                else {
                    rootDayObj.Active = uiDay.enabled ? 1 : 0;
                }

                // Skip the first day because it's the "all days" option.

                // For "EveryDay" we follow the format of:
                //"Day": {
                //    "Active": 1 (or 0),
                //    "Normal": {
                //        "Active": 1 (or 0),
                //        "Val": "HH:mm"
                //    },
                //    "Savings": {
                //        "Active": 1 (or 0),
                //        "Val": "HH:mm"
                //    }
                // }

                if (uiDay.enabled || uiScheduleData.type == DEVICE_SCHEDULE_ALL_DAYS) {
                    rootDayObj["Normal"] = this.getDayObjectForAPI(valueDay, "start");
                    rootDayObj["Savings"] = this.getDayObjectForAPI(valueDay, "end");
                }
                else {
                    var inactiveDay = {
                        Active: 0
                    };

                    rootDayObj["Normal"] = inactiveDay; 
                    rootDayObj["Savings"] = inactiveDay;
                }

                scheduleObj[currentDayShortName] = rootDayObj;
            }
        }

        var comp = this;
        // axios update device value - direct method
        const token = localStorage.getItem('authorizationToken');
        axios({
            method: 'patch',
            url: `/api/devices/${comp.props.record.id}/UpdateSchedule`,
            data: scheduleObj,
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }).then(function (response) {
            // handle success

            comp.props.showNotification("elogic.toastNotifications.updateSchedule");
        }).catch(function (error) {
            // handle error
            comp.props.showNotification("elogic.toastNotifications.updateScheduleError", "error");
            console.log(error);
        });
    }

    getDayObjectForAPI(uiDay, dayType) {

        var dayObj = {
            "Active": 0,
        }

        if (uiDay[dayType] != null) {
            dayObj.Active = 1;
            dayObj.Val = uiDay[dayType];
        }

        return dayObj;
    }

    render() {
        const classes = this.props.classes;

        var thisTab = this;

        return (
            <div>
                <Grid container alignContent="center" justify="center" spacing={3} style={{marginTop:'0'}}>
                    <Grid item xs={8} />
                    <Grid item xs={4} style={{padding:'0'}}>
                        <div style={{ width: '328px', marginLeft: '34px'}}>
                            <DeviceLastUpdated />
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        {!isDeviceOffline(thisTab.props.record) && <Paper className={classes.paperContainer}>
                            <table className={classes.scheduleTable}>
                                <ScheduleTableBody thisTab={thisTab} thisDevice={this.props.thisDevice} />
                            </table>
                        </Paper>}
                        {isDeviceOffline(thisTab.props.record) && <Paper className={classes.emptySchedule}>
                            The device schedule is currently unavailable.
                        </Paper>}
                    </Grid>
                    <Grid item xs={4}>
                        <SettingsCard
                            message={`Setback Offset (°${getUserTempScale(this.props.record)})`}
                            settingsValue={this.state.SetbackOffsetValue}
                            parentKey={`ScheduleTabCard-${0}`}
                            uniqueKey={`SetbackOffset`}
                            thisDevice={this.props.record}
                            SetValueFunction={this.SetSetbackOffsetValue}
                            helpKey="HELP_MixedSetbackOffset"
                            helpObject={this.props.helpObject}
                            helpError={this.props.helpError}
                        />
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default withStyles(bodyStyles)(connect(null, { showNotification })(DeviceScheduleTab));