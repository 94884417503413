import { envConfig } from './env';

const storage = 'localStorage';

export const environment = {
        authorization: {
            issuer: envConfig.auth.instance,
            clientId: envConfig.auth.clientId,
            allowedUrls: [envConfig.auth.instance],
            redirectUri: envConfig.auth.redirectUrl,
            logoutUri: envConfig.auth.logoutUri,
            scopes: envConfig.auth.scopes,
            setupAutomaticSilentRefresh: false,
            storage,
            b2cUrls: {
                edit: envConfig.auth.instance + '/B2C_1A_UnifiedProfileEdit/',
                resetPassword: envConfig.auth.instance + '/B2C_1A_UnifiedPasswordReset/',
            },
            baseUrl: envConfig.baseUrl,
        },
};
