import React, { } from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import WattsLoading from '../WattsLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faArrowToBottom, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faFileDownload, faFilePdf, faCalendarDay, faCalendarWeek, faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as d3 from "d3";
import { getUTCDate } from '../../Helpers.js';
import DeviceStatusNotification from './DeviceStatusNotification';

class DeviceHistoryChart extends React.Component {
    constructor(props) {
        super(props);

        this.showPdfReport = this.showPdfReport.bind(this);
        this.showCsvReport = this.showCsvReport.bind(this);

        this.state = {
            reportType: 'daytrend',
            legendOpen: false,
            anchorEl: null,
            loading: true,
            notified: false,
            data: null,
            error: null,
        };

        this.chartColors = {
            mixedOutlet: "#005db9",
            setpoint: "#00c509",
            highLimit: "#ff0000",
            lowLimit: "#a200d2"
        }
    }

    componentDidMount() {
        this.onChangeReportType(this.state.reportType);
    }

    onChangeReportType(value) {
        this.handleChartClose();

        d3.select('#dashboardChart').remove();

        this.setState({
            reportType: value,
            loading: true
        });

        this.getApiData(value, this.generateSetPointChart);
    }

    /**
     * Generates the set point chart.
     */
    generateSetPointChart(reportType) {

        var apiData = this.state.data;

        // Only render the chart if we successfully retrieved data from the API.
        if (!this.state.error && apiData.data.datapointList.length > 0) {

            var margin = { top: 10, right: 30, bottom: 65, left: 60 };

            var fullWidth = 1100;
            var fullHeight = 200;

            var width = fullWidth - margin.left - margin.right;
            var height = fullHeight - margin.top - margin.bottom;

            var dateFormatString = "%Y-%m-%d";

            // Set point axis details.
            var yAxisPadding = 15;

            // Get the data.
            var dataResult = {};

            switch (reportType) {
                case 'monthlytrend':
                    dataResult = this.processSetPointDataMonthly(apiData);
                    break;
                case 'weektrend':
                    dataResult = this.processSetPointDataWeekly(apiData);
                    break;
                case 'daytrend':
                    dataResult = this.processSetPointDataHourly(apiData);
                    dateFormatString = "%-I %p";
                    break;
            }

            var data = dataResult.outletResults;

            var minValue = dataResult.minValue;
            var maxValue = dataResult.maxValue;

            // Append the svg object to the body of the page.
            var svg = d3.select(".chartContent")
                .append("svg")
                .attr("id", "dashboardChart")
                .attr("width", '100%')
                .attr("height", '100%')
                .attr('viewBox', '0 0 ' + fullWidth + ' ' + fullHeight)
                .attr('preserveAspectRatio', 'xMinYMin')
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");

            // Tell d3 to use the data we processed.
            svg.data(data);

            // Set up the x-axis scale.
            var x = d3.scaleTime()
                .domain(d3.extent(data, function (d) {
                    return d.day;
                })) // Our data set for the x axis.
                .range([0, width]); // Transform it to fit within the width of the graph.

            // Format and append the x-axis.
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(
                    d3.axisBottom(x)
                        .tickFormat(d3.timeFormat(dateFormatString))
                )
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)");

            // TODO: Set up a custom step value / calculation to only show the 3 major points.

            // Set up the y-axis scale.
            var y = d3.scaleLinear()
                .domain([
                    minValue - yAxisPadding,
                    maxValue + yAxisPadding]) // Our data set for the y axis.
                .range([height, 0]); // Transform it to fit within the height of the graph.

            // Format and append the y-axis.
            svg.append("g")
                .call(
                    d3.axisLeft(y)
                        .tickFormat(function (d, i) {
                            if (i % 2 == 0) {
                                return d + decodeURIComponent('%C2%B0') + dataResult.unit;
                            }
                        })
                );

            // Draw the set point average line.
            svg.append('path')
                .datum(dataResult.setpointResults)
                .attr("fill", "none")
                .attr("stroke", this.chartColors.setpoint)
                .attr("stroke-width", 1)
                .attr("d", d3.line()
                    .x(function (d) { return x(d.day) })
                    .y(function (d) { return y(d.y) })
                );

            // Draw the high temp diff average line.
            svg.append('path')
                .datum(dataResult.highTempDiffResults)
                .attr("fill", "none")
                .attr("stroke", this.chartColors.highLimit)
                .attr("stroke-width", 1)
                .attr("d", d3.line()
                    .x(function (d) { return x(d.day) })
                    .y(function (d) { return y(d.y) })
                );


            // Draw the low temp diff average line.
            svg.append('path')
                .datum(dataResult.lowTempDiffResults)
                .attr("fill", "none")
                .attr("stroke", this.chartColors.lowLimit)
                .attr("stroke-width", 1)
                .attr("d", d3.line()
                    .x(function (d) { return x(d.day) })
                    .y(function (d) { return y(d.y) })
                );

            // Draw the scatterplot from the data.
            svg.append('path')
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", this.chartColors.mixedOutlet)
                .attr("stroke-width", 2)
                .attr("d", d3.line()
                    .x(function (d) { return x(d.day) })
                    .y(function (d) { return y(d.y) })
                );
        }
    }

    getApiData(reportType, callback) {
        var compThis = this;
        const token = localStorage.getItem('authorizationToken');
        var jsonHeaders = {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        };

        axios.get(`/api/devices/${this.props.thisDevice.id}/historicaldata/${reportType}`, jsonHeaders).then(function (response) {

            compThis.setState({
                loading: false,
                data: response.data
            });

            callback.call(compThis, reportType);
        }).catch(function (error) {
            compThis.setState({
                error: error
            });
        });
    }

    /**
     * Processes the API data into something we can use to generate the chart.
     * 
     * @param {any} apiData The data from the API.
     * 
     * @returns The processed API data.
     */
    processSetPointDataHourly(apiData) {

        var resultsObject = this.getInitResults(apiData);

        for (var i = 0; i < apiData.data.datapointList.length; i++) {
            var currentData = apiData.data.datapointList[i];

            var dataDate = new Date(currentData.date);
            dataDate.setHours(currentData.hour);
            dataDate = getUTCDate(dataDate, this.props.thisDevice)

            resultsObject = this.getResultsForDate(dataDate, i, currentData, resultsObject);
        }

        return resultsObject;
    }

    processSetPointDataWeekly(apiData) {
        var resultsObject = this.getInitResults(apiData);

        for (var i = 0; i < apiData.data.datapointList.length; i++) {
            var currentData = apiData.data.datapointList[i];

            var weeklyTrend = currentData.hourAverages;

            if (weeklyTrend) {
                for (var k = 0; k < weeklyTrend.length; k++) {

                    var currentWeek = weeklyTrend[k];

                    if (i == 0 && k == 0) {
                        // Start time.
                        var startDate = getUTCDate(currentData.date, this.props.thisDevice);
                        startDate.setUTCHours(currentWeek.startHour);

                        resultsObject = this.getResultsForDate(startDate, i, currentWeek, resultsObject);
                    }

                    // End time.
                    var endDate = getUTCDate(currentData.date, this.props.thisDevice);
                    endDate.setUTCHours(currentWeek.endHour);

                    resultsObject = this.getResultsForDate(endDate, i, currentWeek, resultsObject);
                }
            }
        }

        return resultsObject;
    }

    /**
     * Processes the API data into something we can use to generate the chart.
     * 
     * @param {any} apiData The data from the API.
     * 
     * @returns The processed API data.
     */
    processSetPointDataMonthly(apiData) {
        var resultsObject = this.getInitResults(apiData);

        for (var i = 0; i < apiData.data.datapointList.length; i++) {
            var currentData = apiData.data.datapointList[i];

            var monthTrend = currentData.dailyAverages;

            if (monthTrend) {
                for (var k = 0; k < monthTrend.length; k++) {

                    var currentMonth = monthTrend[k];
                    var dataDate = getUTCDate(currentData.date, this.props.thisDevice);

                    resultsObject = this.getResultsForDate(dataDate, i, currentMonth, resultsObject);
                }
            }
        }

        return resultsObject;
    }

    getInitResults(apiData) {

        var unit = 'F';

        if (apiData.data && apiData.data.scale) {
            switch (apiData.data.scale.toLowerCase()) {
                case 'metric':
                    unit = 'C'
                    break;
                case 'imperial':
                    unit = 'F';
                    break;
                default:
                    // Redundant, but just to make it easier if we need a default in the future.
                    unit = 'F';
                    break;
            }
        }

        return {
            unit: unit,
            outletResults: [],
            setpointResults: [],
            highTempDiffResults: [],
            lowTempDiffResults: [],
            minValue: Infinity,
            maxValue: -Infinity
        };
    }

    getResultsForDate(date, currentX, currentObject, resultsObject) {

        var highDiffTemp = currentObject.mixSetpointTempAvg + currentObject.highAlertDiffAvg;
        var lowDiffTemp = currentObject.mixSetpointTempAvg - currentObject.lowAlertDiffAvg;


        resultsObject.minValue = this.getMinMaxValue([resultsObject.minValue, currentObject.mixOutletTempAvg,
        currentObject.mixSetpointTempAvg, highDiffTemp, lowDiffTemp],
            Math.min);

        resultsObject.maxValue = this.getMinMaxValue([resultsObject.maxValue, currentObject.mixOutletTempAvg,
        currentObject.mixSetpointTempAvg, highDiffTemp, lowDiffTemp],
            Math.max);

        var unit = "F";

        // Outlet temp average.
        resultsObject.outletResults.push({
            x: currentX,
            y: currentObject.mixOutletTempAvg,
            day: date
        });

        // Set point average
        resultsObject.setpointResults.push({
            x: currentX,
            y: currentObject.mixSetpointTempAvg,
            day: date
        });

        // High temp diff
        resultsObject.highTempDiffResults.push({
            x: currentX,
            y: highDiffTemp,
            day: date
        });

        // Low temp diff
        resultsObject.lowTempDiffResults.push({
            x: currentX,
            y: lowDiffTemp,
            day: date
        });

        return resultsObject;
    }

    getMinMaxValue(testValues, testFunc) {
        return testFunc(...testValues);
    }

    // Menu helper functions.
    handleChartClickListItem(event) {
        this.setState({
            anchorChartEl: event.currentTarget
        });
    }

    handleDownloadClickListItem(event) {
        this.setState({
            anchorDownloadEl: event.currentTarget
        });
    }

    handleLegendClickListItem(event) {
        this.setState({
            legendOpen: true
        });
    }

    handleChartClose() {
        this.setState({
            anchorChartEl: null
        });
    }

    handleDownloadClose() {
        this.setState({
            anchorDownloadEl: null
        });
    }

    handleLegendClose() {
        this.setState({
            legendOpen: false
        });
    }

    getLoadingSpinner() {
        if (this.state.loading) {
            return (
                <WattsLoading />
            );
        }
        else {
            return null;
        }
    }

    getNotifications() {
        if (!this.state.loading && !this.state.notified) {

            this.setState({
                notified: true
            });

            return (
                <DeviceStatusNotification device={this.props.thisDevice} />
            );
        }
        else {
            return null;
        }
    }

    getChartMessage() {
        if (!this.state.loading) {
            if (this.state.data.data.datapointList.length == 0) {
                return (
                    <div className={this.props.classes.chartMessage}>
                        <div className={this.props.classes.noHistoricalDataMessage}>No historical data yet.</div>
                        <div>Check back later for updates.</div>
                    </div>
                );
            }
            else if (this.state.error) {
                return (
                    <div className={this.props.classes.chartMessage}>
                        <div>An error occurred while rendering this chart:</div>
                        <div>{`${this.state.error.message}`}</div>
                    </div>
                );
            }
        }

        return null;
    }

    getReportTypeName() {
        switch (this.state.reportType) {
            case 'monthlytrend':
                return '30 days';
            case 'weektrend':
                return '7 days';
            case 'daytrend':
                return '24 hours';
        }
    }

    async downloadFile(url) {
        const token = localStorage.getItem('authorizationToken');
        const options = {
            headers: {
                "Cache-control": "no-cache, no-store",
                "Pragma": "no-cache",
                "Expires": 0,
                "Authorization": `Bearer ${token}`
            }
        }

        fetch(url, options)
            .then(res => res.blob())
            .then(blob => {
                const file = window.URL.createObjectURL(blob);
                window.open(file);
            });
    };

    showCsvReport() {
        var currentDate = new Date();
        this.downloadFile(`/api/Devices/${this.props.thisDevice.id}/historicalreport/${this.state.reportType}/${currentDate.toLocaleDateString().replace(/\//g, '-')}-${this.props.thisDevice.name}-CsvExtract`);
    }

    showPdfReport() {
        var currentDate = new Date();
        this.downloadFile(`/api/HotWaterPerformanceSummary/${this.props.thisDevice.id}/${currentDate.toLocaleDateString().replace(/\//g, '-')}-${this.props.thisDevice.name}-HotWaterSummary`);
    }

    render() {
        const chartViews = [
            {
                name: '24 hours',
                key: 'daytrend',
                icon: (<FontAwesomeIcon icon={faCalendarDay} className={this.props.classes.menuIcon} />),
            },
            {
                name: '7 days',
                key: 'weektrend',
                icon: (<FontAwesomeIcon icon={faCalendarWeek} className={this.props.classes.menuIcon} />)
            },
            {
                name: '30 days',
                key: 'monthlytrend',
                icon: (<FontAwesomeIcon icon={faCalendarAlt} className={this.props.classes.menuIcon} />)
            }
        ];

        const reportOptions = [
            {
                name: 'Download CSV',
                key: 'pdf',
                icon: (<FontAwesomeIcon icon={faFileDownload} className={this.props.classes.menuIcon} />),
                callback: this.showCsvReport
            },
            {
                name: 'Download PDF',
                key: 'csv',
                icon: (<FontAwesomeIcon icon={faFilePdf} className={this.props.classes.menuIcon} />),
                callback: this.showPdfReport
            }
        ];

        const compThis = this;

        return (
            <div className="chartDiv">
                <div className="chartHeader">
                    <FontAwesomeIcon icon={faInfoCircle} onClick={this.handleLegendClickListItem.bind(this)} className="chartHeaderIcon" />
                    <Dialog
                        open={this.state.legendOpen}
                        onClose={this.handleLegendClose.bind(this)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className="legendTitle">{"Legend"}</DialogTitle>
                        <DialogContent>
                            <div id="alert-dialog-description">
                                <div className="legendContainer">
                                    <div className="thickLine legendLine" style={{ borderColor: this.chartColors.mixedOutlet, backgroundColor: this.chartColors.mixedOutlet }} />
                                    <h3 className="legendLabel">Mixed Outlet</h3>
                                </div>
                                <div className="legendContainer">
                                    <div className="thinLine legendLine" style={{ borderColor: this.chartColors.setpoint, backgroundColor: this.chartColors.setpoint }} />
                                    <h3 className="legendLabel">Setpoint</h3>
                                </div>
                                <div className="legendContainer">
                                    <div className=" thinLine legendLine" style={{ borderColor: this.chartColors.highLimit, backgroundColor: this.chartColors.highLimit }} />
                                    <h3 className="legendLabel">High Limit</h3>
                                </div>
                                <div className="legendContainer">
                                    <div className="thinLine legendLine" style={{ borderColor: this.chartColors.lowLimit, backgroundColor: this.chartColors.lowLimit }} />
                                    <h3 className="legendLabel">Low Limit</h3>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleLegendClose.bind(this)} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <FontAwesomeIcon icon={faCog} onClick={event => this.handleChartClickListItem(event)} className="chartHeaderIcon" />
                    <Menu
                        id="chartType"
                        anchorEl={this.state.anchorChartEl}
                        keepMounted
                        open={Boolean(this.state.anchorChartEl)}
                        onClose={event => compThis.handleChartClose(event)}
                        classes={{ paper: this.props.classes.paper }}
                    >
                        {chartViews.map((option, index) => (
                            <MenuItem
                                key={`ChartType-${index}-${option.key}`}
                                onClick={event => compThis.onChangeReportType(`${option.key}`)}>
                                {option.icon} <span className={this.props.classes.menuText}>{option.name}</span>
                            </MenuItem>
                        ))}
                    </Menu>
                    <FontAwesomeIcon icon={faArrowToBottom} onClick={event => this.handleDownloadClickListItem(event)} className="chartHeaderIcon" />
                    <Menu
                        id="chartType"
                        anchorEl={this.state.anchorDownloadEl}
                        keepMounted
                        open={Boolean(this.state.anchorDownloadEl)}
                        onClose={event => compThis.handleDownloadClose(event)}
                        classes={{ paper: this.props.classes.paper }}
                    >
                        {reportOptions.map((option, index) => (
                            <MenuItem
                                key={`DownloadOption-${index}-${option.key}`}
                                onClick={option.callback}>
                                {option.icon} <span className={this.props.classes.menuText}>{option.name}</span>
                            </MenuItem>
                        ))}
                    </Menu>
                    <span className="chartHeaderTitle"><b>History</b> ({compThis.getReportTypeName()})</span>
                </div>
                <div className="chartContent">
                    {compThis.getLoadingSpinner()}
                    {compThis.getChartMessage()}
                    {compThis.getNotifications()}
                </div>
            </div>
        );
    }
}

const chartStyles = {
    chartMessage: {
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '12px',
        textAlign: 'center'
    },
    noHistoricalDataMessage: {
        marginBottom: '5px'
    },
    paper: {
        marginTop: '48px'
    },
    menuIcon: {
        color: '#757575',
        fontSize: '20px',
        margin: '14px 24px'
    },
    menuText: {
        color: '#666',
        fontSize: '16px',
        fontWeight: '900',
        marginRight: '24px'
    }
};

export default withStyles(chartStyles)(DeviceHistoryChart);