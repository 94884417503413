import React from 'react';
import { TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { authProvider } from '../../AuthProvider';

import FormHeader from '../FormHeader';

const layoutStyles = makeStyles({
    'icon': {
        'color': '#005DB9',
        'font-size': '32px'
    },
    'gridBottom': {
        'marginBottom': '48px',
        'paddingTop': '40px'
    },
    'subText': {
        'color': '#333',
        'fontSize': '14px',
        'lineHeight': 1.43,
        'letterSpacing': '-0.14px'
    },
    'actionText': {
        'color': '#005DB9',
        'fontSize': '14px',
        'lineHeight': 1.43,
        'letterSpacing': '-0.14px'
    },
    'fab': {
        'background': '#005DB9',
        'border-radius': '100%',
        'box-shadow': '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0,0,0,.12)',
        'color': '#FFF',
        'fontSize': '20px',
        'height': '56px',
        'left': '100%',
        'transform': 'translateY(-50%)',
        'minHeight': '56px',
        'minWidth': '56px',
        'position': 'absolute',
        'marginLeft': '-14.2%',
        'width': '56px',
        'z-index': '100',

        '&:hover, &:active, &:focus': {
            'background': '#005DB9'
        }
    },
    'container': {
        'background': '#FFF',
        'minHeight': '100vh'
    }
});

const AccountForm = (props) => {

    const useStyles = layoutStyles();
    const userAccount = JSON.parse(sessionStorage.getItem('userAccount'))

    return (
        <div className={useStyles.container}>
            <FormHeader title={props.title} />

            <Button classes={{ root: useStyles.fab }} onClick={() => { authProvider.editProfile() }}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            <Grid container alignContent="center" justify="center" className="formGrid">
                <Grid item md={2}>
                </Grid>
                <Grid item xs={7}>
                    <Grid container>
                        <Grid item md={5} xs={12}>
                            <FontAwesomeIcon icon={faUserAlt} className={useStyles.icon} />
                            <h2>Account Info</h2>
                            <p className={useStyles.subText}>The following information is managed outside the Watts OnSite app.</p>
                            <p className={useStyles.actionText}>Click <FontAwesomeIcon icon={faPencil} /> to make changes.</p>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <TextInput
                                value={userAccount ? userAccount.aad_givenname : ""}
                                label="First Name"
                                fullWidth
                                disabled />

                            <TextInput
                                value={userAccount ? userAccount.aad_surname : ""}
                                label="Last Name"
                                fullWidth
                                disabled />

                            <TextInput
                                value={userAccount ? userAccount.emailAddress : ""}
                                label="Email Address"
                                fullWidth
                                disabled />

                            <TextInput
                                value={userAccount ? userAccount.country : ""}
                                label="Country"
                                fullWidth
                                disabled />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2}>
                </Grid>
            </Grid>
        </div>
    );

};


export default AccountForm;