const config = {
    auth: {
        instance: '',
        clientId: '',
        redirectUrl: '',
        scopes: [],
        logoutUri: '',
    }
};


export const envConfig = {
    auth: {
        instance: process.env.REACT_APP_AUTH_INSTANCE || config.auth.instance || '',
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID || config.auth.clientId || '',
        redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL || config.auth.redirectUrl || '',
        logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI || config.auth.logoutUri || '',
        scopes: process.env.REACT_APP_AUTH_SCOPES ? process.env.REACT_APP_AUTH_SCOPES.split(' ') : config.auth.scopes || [],
    }
};