import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Sidebar } from 'react-admin';

const WattsSidebar = () => {
    return (
        <div />
    );
};

export default WattsSidebar;