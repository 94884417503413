import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const cancelButtonStyles = makeStyles({
    cancelDiv: {
        color: '#005DB9',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: '0 20px'
    }
});

const CancelButton = ({ history, ...props }) => {

    const useStyles = cancelButtonStyles();

    // Uses the react-router to go back to the previous page.
    return (
        <div className={useStyles.cancelDiv} onClick={() => history.goBack()}>
            {props.text}
        </div>
    );
};

export default withRouter(CancelButton);