import React, { useState } from 'react';
import { useAuthState, List } from "react-admin";
import { Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { faPlus, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

import FormHeader from './FormHeader';
import WattsLoading from './WattsLoading';

import { CardGrid } from './CardGrid';
import { ADD_BUTTON_STYLES } from '../Helpers.js';
import OwnershipTransferModal from './OwnershipTransferModal';
import LocationBanner from './Locations/LocationBanner';

CardGrid.defaultProps = {
    data: {},
    ids: [],
};

const noLocationStyles = makeStyles({
    container: {
        textAlign: 'center',
        paddingTop: '180px'
    },

    largeIcon: {
        fontSize: '100px',
        color: '#D7D7D7'
    },

    message: {
        color: '#626262',
        fontSize: '16px',
        margin: 0,
        marginTop: '30px'
    },

    addIcon: {
        fontSize: '16px',
        verticalAlign: 'middle'
    }
});

const NoLocationMessage = () => {
    const classes = noLocationStyles();
    return (
        <div id="noLocationsMessage" className={classes.container}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.largeIcon} />
            <p className={classes.message}>Click <FontAwesomeIcon icon={faPlusCircle} className={classes.addIcon} /> to add your first location.</p>
        </div>
    );
}

export const CardList = (props) => { 

    const [data, setData] = useState({});
    const useStyles = makeStyles({
        content: {
            margin: '0 13%',
            background: 'none',
            border: 'none',
            boxShadow: 'none'
        }
    });

    const classes = useStyles();

    const { loading, authenticated } = useAuthState();

    if (loading) {
        return <WattsLoading />;
    }

    if (!authenticated) {
        return <div />;
    }

    const FloatingActionButton = withStyles(ADD_BUTTON_STYLES)(Button);

    return (
        <>
            <div>
                <LocationBanner setData={setData} record={data}/>
                <FormHeader title="Locations" />
                <FloatingActionButton component={Link} to={`${props.basePath}/create`}>
                    <FontAwesomeIcon icon={faPlus} />
                </FloatingActionButton>
                <List classes={{ content: classes.content }} empty={<NoLocationMessage />} title="All Cards" perPage={999} pagination={null} actions={null} {...props}>
                    <CardGrid className={classes.cardList} cardComponent={props.options.cardComponent} sortFunction={props.options.sortFunction} noResultsMessage={NoLocationMessage} {...props} />
                </List>
            </div>
            <OwnershipTransferModal />
        </>
    );
};

